import { Card, Col, Modal, Select } from 'antd';
import React, { useEffect, useState } from 'react'
import { AiOutlinePlus } from 'react-icons/ai';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useContext } from 'react';
import { AppContext } from '../../components/context/AppContextProvider';
import moment from 'moment';

import { ReactTabulator } from 'react-tabulator'
import 'react-tabulator/lib/styles.css';
import "tabulator-tables/dist/css/tabulator.min.css";
const NotAnswer = () => {
  const authUser=localStorage.getItem("authUser");
  let uData=JSON.parse(authUser);
  // console.log(uData,"uData")
  const {user}=useContext(AppContext);
  const rowMoved = (row) => {
    console.log('Row moved:', row.getData());
  };
  const options = {
    initialSort: [
      { column: 'age', dir: 'asc' }, // sort by age in ascending order
    ],
    movableRows: true,movableColumns: true, // enable row dragging
    rowMoved: rowMoved, // optional, handle row move events
  };
  // console.log(user)
  let agentData=JSON.parse(localStorage.getItem("authUser"));
  const [pageloading,setpageloading]=useState(true);
  const [data,setData]=useState([]);
  const [originalData,setOriginalData]=useState([]);
  const copyContent = async (phone) => {
    try {
      await navigator.clipboard.writeText(phone);
      console.log('Content copied to clipboard');
      toast.success('Content copied to clipboard')
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  }
  const nav =useNavigate()


  const phone1lead= (cell, formatterParams, onRendered)=>{
    const value = cell.getValue();
    const row = cell.getRow();
    const rowData = row.getData();
    const div=document.createElement('div');

    const img = document.createElement("img");
    img.src = rowData?.image;
    img.alt = "Profile";
    img.style.width = "30px";
    img.style.height = "30px";
    img.style.cursor = "pointer";

    const phone=document.createElement('span')
    phone.innerText=rowData.client_phone;
    div.appendChild(phone);
    // div.appendChild(img);

    const imgcopy=document.createElement("img");

    imgcopy.src=rowData.copyimg
    imgcopy.alt = "copy";
    imgcopy.style.width = "30px";
    imgcopy.style.height = "30px";
    imgcopy.style.cursor = "pointer";

    img.addEventListener("click", () => {
      console.log(rowData);
      window.open(`whatsapp://send?text=!&phone=+2${rowData.client_phone.startsWith("0")?rowData.client_phone:`${rowData.client_phone}`}`,'_blank')
      // alert(JSON.stringify(rowData, null, 2)); // For demonstration purposes
    });
    imgcopy.addEventListener("click", () => {
      console.log(rowData,"copy");
      // window.open(`whatsapp://send?text=!&phone=+2${rowData.client_phone.startsWith("0")?rowData.client_phone:`0${rowData.client_phone}`}`,'_blank')
      // alert(JSON.stringify(rowData, null, 2)); // For demonstration purposes
    });

    const imgs_div=document.createElement('div');
    imgs_div.appendChild(imgcopy);
    imgs_div.appendChild(img);
    div.appendChild(imgs_div)
    div.style.display='flex';
    div.style.alignItems='center';
    div.style.justifyContent='space-between'
    imgcopy.addEventListener('click',function(){
      // console.log(rowData)
      copyContent(rowData.client_phone)
    })
    return div;
  }

  const phone2lead= (cell, formatterParams, onRendered)=>{
    const value = cell.getValue();
    const row = cell.getRow();
    const rowData = row.getData();
    const div=document.createElement('div');

    const img = document.createElement("img");
    img.src = rowData?.image;
    img.alt = "Profile";
    img.style.width = "30px";
    img.style.height = "30px";
    img.style.cursor = "pointer";

    const phone=document.createElement('span')
    phone.innerText=rowData.client_phone2;
    div.appendChild(phone);
    // div.appendChild(img);

    const imgcopy=document.createElement("img");

    imgcopy.src=rowData.copyimg
    imgcopy.alt = "copy";
    imgcopy.style.width = "30px";
    imgcopy.style.height = "30px";
    imgcopy.style.cursor = "pointer";

    img.addEventListener("click", () => {
      console.log(rowData);
      window.open(`whatsapp://send?text=!&phone=+2${rowData.client_phone.startsWith("0")?rowData.client_phone:`${rowData.client_phone}`}`,'_blank')
      // alert(JSON.stringify(rowData, null, 2)); // For demonstration purposes
    });
    imgcopy.addEventListener("click", () => {
      console.log(rowData,"copy");
      // window.open(`whatsapp://send?text=!&phone=+2${rowData.client_phone.startsWith("0")?rowData.client_phone:`0${rowData.client_phone}`}`,'_blank')
      // alert(JSON.stringify(rowData, null, 2)); // For demonstration purposes
    });

    const imgs_div=document.createElement('div');
    imgs_div.appendChild(imgcopy)
    imgs_div.appendChild(img)
    div.appendChild(imgs_div)
    div.style.display='flex';
    div.style.alignItems='center';
    div.style.justifyContent='space-between'
    imgcopy.addEventListener('click',function(){
      console.log(rowData)
      copyContent(rowData.client_phone)
    })
    return div;
  }
  const rowNumFormatter = (cell) => {
    return cell.getRow().getPosition(true) +0; // Get the row position and add 1 to make it 1-based
};

  const columns = [
    // { title: 'ID', field: 'client_id', sorter: 'number', headerFilter: "input" },
    { title: 'No', formatter: rowNumFormatter, width: 50, hozAlign: 'center' }, // Row number column
    { title: 'Name', field: 'client_name', sorter: 'string', headerFilter: "input" },
    { title: 'Phone', field: 'client_phone',formatter:phone1lead, sorter: 'string', headerFilter: "input", headerFilterPlaceholder: "phone"  },
    { title: 'Phone2', field: 'client_phone2',formatter:phone2lead, sorter: 'string', headerFilter: "input", headerFilterPlaceholder: "phone" },
    { title: 'Address', field: 'client_address', sorter: 'string', headerFilter: "input" },
    // { title: 'Added Date', field: 'added_at', sorter: 'date',sorterParams: { format: 'YYYY-MM-DD' }, headerFilter: "date" },
    // { title: 'Action Number', field: 'call_number', sorter: 'number', headerFilter: "input" },
  ];


  const getNotAnswer=()=>{
    setpageloading(true)
    const data_send={
      type:uData.user_type,
      agent_id:uData.agent_id,
      client_type:'all'
    }
    axios.post('https://quad-walls.com/quad_crm/admin/select_not_answer.php',JSON.stringify(data_send))
    .then((res)=>{
      console.log(res,"this is response")
      if(Array.isArray(res.message)){
        setData(res.message.map((item)=>{
          return {...item,image:require("../../assets/images/whats.png"),copyimg:require("../../assets/images/copy.png")}
        }))
        setOriginalData(res.message.map((item)=>{
          return {...item,image:require("../../assets/images/whats.png"),copyimg:require("../../assets/images/copy.png")}
        }))
      }
    })
    .catch(e=>console.log(e))
    .finally(()=>{
      setpageloading(false)
    })
  }
  const handleSearch=(txt)=>{
    // if(txt==''){
    //   setData(originalData)
    // }
    // else {
    //   const pushed=[];
    //   for(let i=0;i<originalData.length;i++){
    //     if(originalData[i].client_name.includes(txt)||originalData[i].client_phone.includes(txt))
    //     pushed.push(originalData[i]);
    //   }
    //   setData(pushed)
    // }
    if(txt==''){
      setData(originalData)
    }
    else {
      if(true){
        // console.log('rere')
        let pushed=originalData.filter((it)=>{
          // console.log(it.client_name)
          if(it.client_name!=null){
            if(it.client_name.includes(txt)||it.client_phone.includes(txt)){
              return {...it}
            }
          }
          return null
        });
        console.log(pushed)
        setData(pushed)
        // setData(originalData.filter(it=>it.client_name.includes(txt)||it.client_phone.includes(txt)));
      }
      // else {
      //   setclients(originalColds.filter(it=>it.client_name.includes(txt)||it.client_phone.includes(txt)));
      // }
    }

  }
  useEffect(()=>{
    getNotAnswer()
  },[])

  return (
    <>
      {
        pageloading?(
          [1, 2, 3].map(x => (
            <Col xs={24} md={12} lg={8} key={x}>
              <Card loading minHeight={200} />
            </Col>
          ))
        ):(
          <div style={{
            marginTop:'30px',
            overflow:'auto'
          }} className='py-5 container'>

      <div className='my-2 p-2'>
        <input placeholder='Search' className='form-control' type="text" onChange={(e)=>{
          e.preventDefault();
          handleSearch(e.target.value)
        }} />
      </div>
        <ReactTabulator
          data={data}
          options={
            options
          }
          // sortOrderReverse={true}
          columns={columns}
            layout={"fitData"}
        />

          </div>
        )
      }
    </>
  )
}

export default NotAnswer
