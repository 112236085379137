import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
// import { BASE_URL } from "..";

const initialState={
}
export const fetchSiteData= createAsyncThunk("Site/data",async()=>{
  const data= await axios.get(`https://camp-coding.tech/new_quad_wall/quad_crm/admin/get_user_colors.php`)
  console.log(data,"vreevdata")
  alert(data.message)
  return data.message;
})
export const SiteDataSlice=createSlice({
  name:'sitedata',
  initialState,
  reducers:{},
  extraReducers:(builder)=>{
    builder.addCase(fetchSiteData.fulfilled,(state,action)=>{
      return action.payload;
    })
  }
})


export const {}=SiteDataSlice.actions;

export default SiteDataSlice.reducer;
