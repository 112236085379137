import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css'
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import "./i18n";
import 'react-tabulator/lib/styles.css';
import {configureStore} from './store/store';
import {store2} from './store2'
import AppContextProvider from './components/context/AppContextProvider';
import 'react-range-slider-input/dist/style.css';
const root = ReactDOM.createRoot(document.getElementById("root"));
// console.log(store2,"storestore")
root.render(
  <Provider store={store2}>
    <Provider store={configureStore({})}>{/* configureStore({}) */}
      <React.Fragment>
        <BrowserRouter>
        <AppContextProvider>
          <App />
        </AppContextProvider>

        </BrowserRouter>
      </React.Fragment>
    </Provider>
  </Provider>
);
reportWebVitals();
