import React, { useCallback, useMemo, useRef } from "react";

import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import "react-tabulator/lib/styles.css";
import "tabulator-tables/dist/css/tabulator.min.css"; //import Tabulator stylesheet
import { ReactTabulator } from "react-tabulator";
import * as XLSX from 'xlsx';

// import Tabulator from 'tabulator-tables';
import Lottie from "lottie-react";
import groovyWalkAnimation from "../../assets/images/Animation - 1717928166617.json";
import { toast } from "react-toastify";
import { Button, Select, Spin, Modal } from "antd";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { useSearchParams } from "react-router-dom";
import { AgGridReact } from "ag-grid-react";

const OtherColds = () => {
  const [showHistoyModel,setShowHistoyModel]=useState(false);

  const [comment, setComment] = useState("");
  const [historyLoading,setHistoryLoading]=useState(false)
  const [ClientHistory,setClientHistory]=useState([])
  const [callBackData,setCallBackData]=useState({
    time:'',
    date:'',
  })
const [commentsData, setCommentsData] = useState([]);
const [ClientId, setClientId] = useState(null);
const [oldComment, setOldComment] = useState(null);

const [meetingData,setMeetingData]=useState({
  date:'',
  time:'',

})

const [columnHisSelsDefs, setColumnHisSelsDefs] = useState([
  {
    headerName: "#",
    field: "rowNumber",
    valueGetter: (params) => params.node.rowIndex + 1, // This will give you the row number starting from 1
    filter: 'agSetColumnFilter',
    filterParams: {
      caseSensitive: true
    },
    // aggFunc:"sum",
    checkboxSelection: true,
    rowDrag: true,
    headerCheckboxSelection: true,
    cellStyle: (params) => ({
      backgroundColor: params.value === "Den" ? "#c3c3c3" : "#b7d8d6",
    }),
    cellClass: (params) =>
      params.value === "Den" ? "class_name" : "another_class_name",
    // tooltipField:"age",
    // headerComponentFramework: CustomHeader
  },
  {
    headerName: 'status',
    field: "status",
    // aggFunc:"sum",
    // tooltipField:"age",
  },
  {
    headerName: 'status_label',
    field: "status_label",
    // aggFunc:"sum",
    // tooltipField:"age",
  },
  {
    headerName: 'feed_back',
    field: "feed_back",
    // aggFunc:"sum",
    // tooltipField:"age",
  },
  {
    headerName: 'call_back_time',
    field: "call_back_time",
    // aggFunc:"sum",
    // tooltipField:"age",
  },
  {
    headerName: 'call_back_date',
    field: "call_back_date",
    // aggFunc:"sum",
    // tooltipField:"age",
  },


]);
const [columnHisDefs, setColumnHisDefs] = useState([
  {
    headerName: "#",
    field: "rowNumber",
    valueGetter: (params) => params.node.rowIndex + 1, // This will give you the row number starting from 1
    filter: 'agSetColumnFilter',
    filterParams: {
      caseSensitive: true
    },
    // aggFunc:"sum",
    checkboxSelection: true,
    rowDrag: true,
    headerCheckboxSelection: true,
    cellStyle: (params) => ({
      backgroundColor: params.value === "Den" ? "#c3c3c3" : "#b7d8d6",
    }),
    cellClass: (params) =>
      params.value === "Den" ? "class_name" : "another_class_name",
    // tooltipField:"age",
    // headerComponentFramework: CustomHeader
  },
  {
    headerName: 'status',
    field: "status",
    // aggFunc:"sum",
    // tooltipField:"age",
  },
  {
    headerName: 'status_label',
    field: "status_label",
    // aggFunc:"sum",
    // tooltipField:"age",
  },
  {
    headerName: 'feed_back',
    field: "feed_back",
    // aggFunc:"sum",
    // tooltipField:"age",
  },
  {
    headerName: 'call_back_time',
    field: "call_back_time",
    // aggFunc:"sum",
    // tooltipField:"age",
  },
  {
    headerName: 'call_back_date',
    field: "call_back_date",
    // aggFunc:"sum",
    // tooltipField:"age",
  },
  {
    headerName: "sale Name",
    field: "email",
    sortable: true,
    cellRenderer: (params) => (
      <div>
        {
          params?.data&&<div>
            <p>{params?.data&&params?.data?.agent?.name}</p>
          </div>
        }
      </div>
    ),
  },
  {
    headerName: "sale Email",
    field: "email",
    sortable: true,
    cellRenderer: (params) => (
      <div>
        {
          params?.data&&<div>
            <p>{params?.data&&params?.data?.agent?.user_email}</p>
          </div>
        }
      </div>
    ),
  },
  {
    headerName: "sale phone",
    field: "email",
    sortable: true,
    cellRenderer: (params) => (
      <div>
        {
          params?.data&&<div>
            <p>{params?.data&&params?.data?.agent?.phone}</p>
          </div>
        }
      </div>
    ),
  },


]);


const [oldData, setOldData] = useState(null);
const [deleteComment, setDeleteComment] = useState(null);

const [openComment, setOpenNewCommect] = useState(false);
  const [disConf,setDisConf]=useState(true)

  var filterParams = {
    comparator: (filterLocalDateAtMidnight, cellValue) => {
      var dateAsString = cellValue;
      if (dateAsString == null) return -1;
      var dateParts = dateAsString.split("/");
      var cellDate = new Date(
        Number(dateParts[2]),
        Number(dateParts[1]) ,
        Number(dateParts[0]),
      );
      if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
        return 0;
      }
      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      }
      if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      }
      return 0;
    },
    minValidYear: 2024,
    maxValidYear: 3000,
    inRangeFloatingFilterDateFormat: "Do MMM YYYY",
  };
  const [filteredRowCount,setFilteredRowCount]=useState([])
  const gridStyle = useMemo(() => ({ height: "100vh" }), []);
  const gridRef = useRef();
  const [selectedRows,setSelectedRows]=useState([]);

  const authUser = JSON.parse(localStorage.getItem("authUser"));
  const [selectedemployee, setselectedemployee] = useState("");
  const [employeessdata, setemployeessdata] = useState([]);
  const [calls, setCalls] = useState([]);
  const[selectAll,setSelectAll]=useState(false)

  const [showAddModal, setShowAddModal] = useState(false);
  const [rowData, setRowData] = useState({});
  const [assignloading, setassignloading] = useState(false);
  const [page, setPage] = useState();
  const [newCall, setNewCall] = useState({
    feed_back: "",
    duration: "",
  });
  const imageFormatter = (cell, formatterParams, onRendered) => {
    // console.log(cell.getValue())
    // console.log(row.getRow())
    const value = cell.getValue();
    const row = cell.getRow();
    const rowData = row.getData();
    // Create the image element
    const img = document.createElement("img");
    img.src = rowData?.image;
    img.alt = "Profile";
    img.style.width = "30px";
    img.style.height = "30px";
    img.style.cursor = "pointer";

    // Add click event listener
    img.addEventListener("click", () => {
      console.log(rowData);
      window.open(rowData?.sheet_link, "_blank");
    });

    return img;
  };

  const handleExport = () => {
    const pp = [];
    data.map((item, index) => {
      if (item.check === true) {
        console.log('dsd');
        let newobj = {
          'Cleint name': item.name || '',
          'Cleint phone': item.phone || '',
          'Cleint phone2': item.phone2 || '',
          'Cleint project_intrested_in': item.project_intrested_in || '',
          'Cleint time_to_call': item.time_to_call || '',
          'Cleint unitcode': item.unitcode || '',
          'Cleint client_type': item.client_type || '',
          'Cleint job': item.job || '',
          'Cleint address': item.address || '',
          'agent name': item.agent_name || '',
          'agent Email': item.agent_email || '',
          'agent Phone': item.agent_phone || '',
        };
        pp.push(newobj);
      }
    });
    if (pp.length == 0) {
      toast.warn('إختر عناصر أولا');
      return;
    }
    const ids = pp.join('&&');
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(pp);
    XLSX.utils.book_append_sheet(wb, ws, 'MySheet1');
    XLSX.writeFile(wb, 'MyExcel.XLSX');
    setSelectAll(false);
    let allData = [...data];
    setData(
      allData.map(item => {
        return { ...item, check: false };
      }),
    );
    // XLSX.utils.book_append_sheet(wb, ws, 'MySheet1');
    // XLSX.writeFile(wb, 'MyExcel.XLSX');
  };

  const handleGetHistoy=(id)=>{
    setHistoryLoading(true)
    const data_send={
      client_id:id,
    }
    axios.post("https://camp-coding.tech/new_quad_wall/quad_crm/admin/get_client_history.php",JSON.stringify(data_send))
    .then((res)=>{
      if(res.status=='success'){
        if(Array.isArray(res.message)){
          setClientHistory(res.message)
        }
      }
    console.log(res)

    })
    .catch(e=>console.log(e))
    .finally(()=>{
      setHistoryLoading(false)
    })
  }
  var headerMenu = function () {
    var menu = [];
    var columns = this.getColumns();

    for (let column of columns) {
      //create checkbox element using font awesome icons
      let icon = document.createElement("i");
      icon.classList.add("fas");
      icon.classList.add(column.isVisible() ? "fa-check-square" : "fa-square");

      //build label
      let label = document.createElement("span");
      let title = document.createElement("span");

      title.textContent = " " + column.getDefinition().title;

      label.appendChild(icon);
      label.appendChild(title);

      //create menu item
      menu.push({
        label: label,
        action: function (e) {
          //prevent menu closing
          e.stopPropagation();

          //toggle current column visibility
          column.toggle();

          //change menu item icon
          if (column.isVisible()) {
            icon.classList.remove("fa-square");
            icon.classList.add("fa-check-square");
          } else {
            icon.classList.remove("fa-check-square");
            icon.classList.add("fa-square");
          }
        },
      });
    }

    return menu;
  };

  //add header menu in column definition
  // var table = new Tabulator("#example-table", {
  //     columns:[
  //         {title:"Name", field:"name", headerMenu:headerMenu}, //add menu to this column header
  //     ]
  // });

  const geteployees = () => {
    axios
      .get(
        "https://camp-coding.tech/new_quad_wall/quad_crm/admin/select_selles.php"
      )
      .then((res) => {
        //console.log(res)
        //console.log(res);
        setemployeessdata(res.message);
        setselectedemployee(res?.message[0]?.agent_id);
        //console.log(res?.message[0]?.name);
      });
  };

  const copyContent = async (phone) => {
    try {
      await navigator.clipboard.writeText(phone);
      console.log("Content copied to clipboard");
      toast.success("Content copied to clipboard");
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  const phone1lead = (cell, formatterParams, onRendered) => {
    const value = cell.getValue();
    const row = cell.getRow();
    const rowData = row.getData();
    const div = document.createElement("div");

    const img = document.createElement("img");
    img.src = rowData?.image;
    img.alt = "Profile";
    img.style.width = "30px";
    img.style.height = "30px";
    img.style.cursor = "pointer";

    const phone = document.createElement("span");
    phone.innerText = rowData.phone;
    div.appendChild(phone);
    // div.appendChild(img);

    const imgcopy = document.createElement("img");

    imgcopy.src = rowData.copyimg;
    imgcopy.alt = "copy";
    imgcopy.style.width = "30px";
    imgcopy.style.height = "30px";
    imgcopy.style.cursor = "pointer";

    img.addEventListener("click", () => {
      console.log(rowData);
      window.open(
        `whatsapp://send?text=!&phone=+2${
          rowData.phone.startsWith("0") ? rowData.phone : `0${rowData.phone}`
        }`,
        "_blank"
      );
      // alert(JSON.stringify(rowData, null, 2)); // For demonstration purposes
    });
    imgcopy.addEventListener("click", () => {
      console.log(rowData, "copy");
      // window.open(`whatsapp://send?text=!&phone=+2${rowData.phone.startsWith("0")?rowData.phone:`0${rowData.phone}`}`,'_blank')
      // alert(JSON.stringify(rowData, null, 2)); // For demonstration purposes
    });

    const imgs_div = document.createElement("div");
    imgs_div.appendChild(imgcopy);
    imgs_div.appendChild(img);
    // div.appendChild(imgs_div)

    if (rowData?.phone != null && rowData?.phone != "") {
      div.appendChild(imgs_div);
      div.appendChild(phone);
    }

    div.style.display = "flex";
    div.style.alignItems = "center";
    div.style.justifyContent = "space-between";
    imgcopy.addEventListener("click", function () {
      // console.log(rowData)
      copyContent(rowData.phone);
    });
    return div;
  };

  const phone2lead = (cell, formatterParams, onRendered) => {
    const value = cell.getValue();
    const row = cell.getRow();
    const rowData = row.getData();
    const div = document.createElement("div");

    const img = document.createElement("img");
    img.src = rowData?.image;
    img.alt = "Profile";
    img.style.width = "30px";
    img.style.height = "30px";
    img.style.cursor = "pointer";

    const phone = document.createElement("span");
    phone.innerText = rowData.phone2;

    // div.appendChild(img);

    const imgcopy = document.createElement("img");

    imgcopy.src = rowData.copyimg;
    imgcopy.alt = "copy";
    imgcopy.style.width = "30px";
    imgcopy.style.height = "30px";
    imgcopy.style.cursor = "pointer";

    img.addEventListener("click", () => {
      console.log(rowData);
      window.open(
        `whatsapp://send?text=!&phone=+2${
          rowData.phone2.startsWith("0") ? rowData.phone2 : `0${rowData.phone2}`
        }`,
        "_blank"
      );
      // alert(JSON.stringify(rowData, null, 2)); // For demonstration purposes
    });
    imgcopy.addEventListener("click", () => {
      console.log(rowData, "copy");
      // window.open(`whatsapp://send?text=!&phone=+2${rowData.phone.startsWith("0")?rowData.phone:`0${rowData.phone}`}`,'_blank')
      // alert(JSON.stringify(rowData, null, 2)); // For demonstration purposes
    });

    const imgs_div = document.createElement("div");
    imgs_div.appendChild(imgcopy);
    imgs_div.appendChild(img);
    // console.log(rowData?.phone2)
    if (rowData?.phone2 != null && rowData?.phone2 != "") {
      div.appendChild(imgs_div);
      div.appendChild(phone);
    }
    div.style.display = "flex";
    div.style.alignItems = "center";
    div.style.justifyContent = "space-between";
    imgcopy.addEventListener("click", function () {
      console.log(rowData);
      copyContent(rowData.phone);
    });
    return div;
  };
  const rowNumFormatter = (cell) => {
    return cell.getRow().getPosition(true) + 0; // Get the row position and add 1 to make it 1-based
  };

  const selectForm = (cell, formatterParams, onRendered) => {
    const value = cell.getValue();
    const row = cell.getRow();
    const rowData = row.getData();
    const check_box = document.createElement("input");
    check_box.type = "checkbox";
    check_box.checked = rowData?.check;
    check_box.style.width = "20px";
    check_box.style.height = "20px";
    check_box.style.cursor = "pointer";
    check_box.addEventListener("click", function () {
      setData(
        data.map((item) => {
          return {
            ...item,
            check:
              item.client_id == rowData.client_id ? !item.check : item.check,
          };
        })
      );
    });

    return check_box;
    // check_box.
  };

  // const columns = ;

  const [coulmns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [showCalls, setShowCalls] = useState(false);
  const [callLoading, setCallLoading] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const handleAss50 = (len) => {
    let allClis = [...data];
    console.log(data);
    for (let i = 0; i < data.length; i++) {
      allClis[i]["check"] = false;
    }
    for (let i = 0; i < len; i++) {
      allClis[i]["check"] = !allClis[i]["check"];
    }
    setData(allClis);
    console.log(allClis);
  };

  const assignfunction = () => {
    let selected = [...data];
    let checddata = selectedRows;
    let selectedtxt = "";
    if (selectedemployee == "") {
      toast.warn("select at least one employee");
    }
    for (let i = 0; i < checddata.length; i++) {
      if (i == 0) {
        selectedtxt += checddata[i].client_id;
      } else {
        selectedtxt += "**UAD**" + checddata[i].client_id;
      }
    }
    const data_send = {
      client_id_data: selectedtxt,
      agent_id: selectedemployee,
    };
    console.log(data_send);
    setassignloading(true);
    axios
      .post(
        "https://camp-coding.tech/new_quad_wall/quad_crm/admin/assign_agent_to_client.php",
        JSON.stringify(data_send)
      )
      .then((res) => {
        console.log(res);
        if (res.status === "success") {
          let alldata = [...data];
          alldata.map((item) => {
            return { ...item, check: false };
          });
          //retnotchecked();
          setData(alldata);

          toast.success(res.message);
          getSheets();
        } else if (res.status == "error") {
          toast.error(res.message);
        } else {
          toast.error("something is error try again");
        }
      })
      .finally(() => {
        setassignloading(false);
      });
  };
  const handleGetCalls=async(id)=>{
    setCallLoading(true)
    const data_send={
      client_id:id
    }
    await axios.post("https://camp-coding.tech/new_quad_wall/quad_crm/admin/new_client_calls.php",JSON.stringify(data_send))
    .then((res)=>{
      console.log(res.message)
      if(res.status=='success'){
        if(Array.isArray(res.message)){
          setCalls(res.message);
        }
      }
    })
    .catch(e=>console.log(e))
    .finally(()=>{
      setCallLoading(false)
    })
  }
  const callField = (cell, formatterParams, onRendered) => {
    const value = cell.getValue();
    const row = cell.getRow();
    const rowData = row.getData();
    const div = document.createElement("div");
    const btn = document.createElement("button");
    btn.innerText = "Calls";
    btn.style.color = "white";
    btn.style.backgroundColor = "#0b5ed7";
    btn.style.border = "none";
    btn.style.padding = "10px";
    btn.style.borderRadius = "4px";
    btn.addEventListener("click", function () {
      console.log(rowData);
      handleGetCalls(rowData?.client_id);
      setRowData(rowData?.client_id);
      setShowCalls(true);
    });
    div.appendChild(btn);

    return div;
  };

  const updateColumnTitles = (dataLength) => {
    const updatedColumns = [
      {
        title: "No",
        formatter: rowNumFormatter,
        width: 50,
        hozAlign: "center",
        headerMenu,
      }, // Row number column
      {
        title: "Name",
        field: "name",
        sorter: "string",
        headerFilter: "input",
        headerMenu,
      },
      {
        title: "email",
        field: "email",
        sorter: "string",
        headerFilter: "input",
        headerMenu,
      },
      {
        title: "Phone",
        field: "phone",
        formatter: phone1lead,
        sorter: "string",
        headerFilter: "input",
        headerFilterPlaceholder: "phone",
        headerMenu,
      },
      {
        title: "Phone2",
        field: "phone2",
        formatter: phone2lead,
        sorter: "string",
        headerFilter: "input",
        headerFilterPlaceholder: "phone",
        headerMenu,
      },
      { title: 'project_intrested_in', field: 'project_intrested_in', sorter: 'string', headerFilter: "input" ,headerMenu},

      {
        title: "Address",
        field: "address",
        sorter: "string",
        headerFilter: "input",
        headerMenu,
      },
      {
        title: "Added Date",
        field: "added_at",
        sorter: "date",
        sorterParams: { format: "YYYY-MM-DD" },
        headerFilter: "date",
        headerMenu,
      },

      {
        title: "Agent Name",
        field: "agent_name",
        sorter: "string",
        headerFilter: "input",
        headerMenu,
      },

      {
        title: "Agent Email",
        field: "agent_email",
        sorter: "string",
        headerFilter: "input",
        headerMenu,
      },

      {
        title: "Agent Phone",
        field: "agent_phone",
        sorter: "string",
        headerFilter: "input",
        headerMenu,
      },
      // { title: 'Action Number', field: 'call_number', sorter: 'number', headerFilter: "input" },
      {
        title: "Calls",
        field: "phone",
        formatter: callField,
        headerFilterPlaceholder: "phone",
        headerMenu,
      },

      { title: "select", field: "client_id", formatter: selectForm, width: 30 },
    ];
    setColumns(updatedColumns);
  };

  const agentField = (cell, formatterParams, onRendered) => {
    const value = cell.getValue();
    const row = cell.getRow();
    const rowData = row.getData();
    const div = document.createElement("div");
    div.innerText = rowData?.agent?.name;
    return div;
  };

  const agentEmailField = (cell, formatterParams, onRendered) => {
    const value = cell.getValue();
    const row = cell.getRow();
    const rowData = row.getData();
    const div = document.createElement("div");
    div.innerText = rowData?.agent?.user_email;
    return div;
  };

  const agentEmailPhone = (cell, formatterParams, onRendered) => {
    const value = cell.getValue();
    const row = cell.getRow();
    const rowData = row.getData();
    const div = document.createElement("div");
    div.innerText = rowData?.agent?.phone;
    return div;
  };

  const historyColumns = [
    {
      title: "No",
      formatter: rowNumFormatter,
      width: 50,
      hozAlign: "center",
      headerMenu,
    }, // Row number column
    {
      title: "feed_back",
      field: "feed_back",
      sorter: "string",
      headerFilter: "input",
      headerMenu,
    },
    {
      title: "duration",
      field: "duration",
      sorter: "string",
      headerFilter: "input",
      headerMenu,
    },
    // { title: 'feed_back', field: 'feed_back', sorter: 'string', headerFilter: "input",headerMenu },
    {
      title: "Last Action Date",
      field: "date",
      sorter: "date",
      sorterParams: { format: "YYYY-MM-DD" },
      headerFilter: "date",
      headerMenu,
    },
    {
      title: "Agent Name",
      field: "agent",
      formatter: agentField,
      sorter: "string",
      headerMenu,
    },
    {
      title: "Agent Email",
      field: "agent",
      formatter: agentEmailField,
      sorter: "string",
      headerMenu,
    },
    {
      title: "Agent Phone",
      field: "agent",
      formatter: agentEmailPhone,
      sorter: "string",
      headerMenu,
    },
  ];

  const callColumns = [
    {
      title: "No",
      formatter: rowNumFormatter,
      width: 50,
      hozAlign: "center",
      headerMenu,
    }, // Row number column
    {
      title: "feed_back",
      field: "feed_back",
      sorter: "string",
      headerFilter: "input",
      headerMenu,
    },
    {
      title: "duration",
      field: "duration",
      sorter: "string",
      headerFilter: "input",
      headerMenu,
    },
    // { title: 'feed_back', field: 'feed_back', sorter: 'string', headerFilter: "input",headerMenu },
    {
      title: "Last Action Date",
      field: "date",
      sorter: "date",
      sorterParams: { format: "YYYY-MM-DD" },
      headerFilter: "date",
      headerMenu,
    },
    {
      title: "Agent Name",
      field: "agent",
      formatter: agentField,
      sorter: "string",
      headerMenu,
    },
    {
      title: "Agent Email",
      field: "agent",
      formatter: agentEmailField,
      sorter: "string",
      headerMenu,
    },
    {
      title: "Agent Phone",
      field: "agent",
      formatter: agentEmailPhone,
      sorter: "string",
      headerMenu,
    },
  ];

  useEffect(() => {
    updateColumnTitles(data.length);
  }, [data]);

  const rowMoved = (row) => {
    console.log("Row moved:", row.getData());
  };
  const options = {
    initialSort: [
      { column: "age", dir: "asc" }, // sort by age in ascending order
    ],
    movableRows: true,
    movableColumns: true, // enable row dragging
    rowMoved: rowMoved, // optional, handle row move events
    // pagination:true, //enable pagination
    // paginationSize:10, //optional parameter to request a certain number of rows per page
    // paginationInitialPage:1,
    // paginationSizeSelector:[5,10,20, 25,30,40, 50],

  };

  const [pageLoading, setPageLoading] = useState(false);
  const [params, setParams] = useSearchParams();
  const getSheets = (page) => {
    setPageLoading(true);
    const data_send = {
      client_type: "cold",
      page: page,
    };
    setParams((prevParams) => {
      const newParams = new URLSearchParams(prevParams);

      newParams.set("page", page);
      return newParams;
    });
    axios
      .post(
        "https://camp-coding.tech/new_quad_wall/quad_crm/admin/select_unassign_clients.php",
        data_send
      )
      .then((res) => {
        console.log(res);
        if (Array.isArray(res.message)) {
          // setData(res.message)
          setParams((prevParams) => {
            const newParams = new URLSearchParams(prevParams);
            newParams.set(
              "tb",

              res.total_pages
            );

            newParams.set(
              "page",

              page
            );
            return newParams;
          });
          setData(
            res.message.map((item) => {
              return {
                ...item,
                image: require("../../assets/images/whats.png"),
                copyimg: require("../../assets/images/copy.png"),
                check: false,
              };
            })
          );
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setPageLoading(false);
      });
  };
  const handleAddCall = () => {
    // setAddLoading(true)
    const data_send = {
      ...newCall,
      client_id: rowData?.client_id,
      agent_id: authUser?.agent_id,
    };
    console.log(data_send);
    // return ;
    axios
      .post(
        "https://camp-coding.tech/new_quad_wall/quad_crm/admin/add_new_call.php",
        JSON.stringify(data_send)
      )
      .then((res) => {
        console.log(res);
        if (res.status == "success") {
          toast.success(res.message);
          setNewCall({ duration: "", feed_back: "" });
          handleGetCalls(rowData);
          setShowAddModal(false);
        } else if (res.status == "error") {
          toast.error(res.message);
        } else {
          toast.error("Something Went Error");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setAddLoading(false);
      });
  };
  useEffect(() => {
    getSheets(params.get("page") ? params.get("page") : 1);
    geteployees();
  }, []);

  const handlePageChange = (increment) => {
    let newPage = null;
    setParams((prevParams) => {
      const newParams = new URLSearchParams(prevParams);
      const currentPage = parseInt(params.get("page")) || 1;
      const totalPages = parseInt(params.get("tb")) || 1;
      newPage = currentPage;

      if (increment) {
        newPage = currentPage < totalPages ? currentPage + 1 : currentPage;
      } else {
        newPage = currentPage > 1 ? currentPage - 1 : currentPage;
      }

      newParams.set("page", newPage);
      return newParams;
    });

    getSheets(newPage);
  };
  const onFilterChanged = (params) => {
    const rowCount = params.api.getDisplayedRowCount();
    setFilteredRowCount(rowCount);
  };
  const handleGetComments = async (id) => {
    setCallLoading(true);
    const data_send = {
      client_id: id,
    };
    await axios
      .post(
        "https://camp-coding.tech/new_quad_wall/quad_crm/admin/get_client_comments.php",
        JSON.stringify(data_send)
      )
      .then((res) => {
        console.log(res.message);
        if (res.status == "success") {
          if (Array.isArray(res.message)) {
            setCommentsData(res.message);
          }
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setCallLoading(false);
      });
  };
  const handleAddComment = () => {
    // setAddLoading(true)
    console.log(ClientId)
    const data_send = {
      comment: comment,
      client_id: ClientId?.client_id,
      agent_id: authUser?.agent_id,
    };
    console.log(data_send);
    // return ;
    axios
      .post(
        "https://camp-coding.tech/new_quad_wall/quad_crm/admin/insert_comment.php",
        JSON.stringify(data_send)
      )
      .then((res) => {
        console.log(res);
        if (res.status == "success") {
          toast.success(res.message);
          setNewCall({ duration: "", feed_back: "" });
          handleGetComments(ClientId?.client_id);
          setOpenNewCommect(false);
          setComment('')
          setClientId(null)
          setShowAddModal(false);
        } else if (res.status == "error") {
          toast.error(res.message);
        } else {
          toast.error("Something Went Error");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setAddLoading(false);
      });
  };
  const defaultColDef = useMemo(() => {
    return {
      sortable: true,
      editable: true,
      filter: true,
      floatingFilter: true,
      onCellValueChanged: (e) => console.log(e),
      // flex: 10,
      width:'fit-content'
    };
  }, []);

  const onGridReady = useCallback((params) => {
    fetch("https://www.ag-grid.com/example-assets/small-olympic-winners.json")
      .then((resp) => resp.json())
      .then((data) => {
        // setRowData(data);
      });
  }, []);
  const onSelectionChanged = (event) => {
    setSelectedRows(event.api.getSelectedRows())
    if(event.api.getSelectedRows().lengt>0){
      setDisConf(true);
    }
    else {
      setDisConf(false);
    }
  };
  const isRowSelectable = (node) => {
    return true; // or node.data.<column>
  };
  const onExportClick = () => {
    // gridApi.api.exportDataAsCsv();
    const selectedNodes = gridRef.current.api.getSelectedNodes();
    const selectedData = selectedNodes.map(node => node.data);
    console.log(selectedData)
    const params = {
      fileName: 'selected_rows.csv',
      columnSeparator: ',',
      skipHeader: false,
      columnKeys: ['make', 'model', 'price']
    };
    const pp = [];
    selectedData.map((item, index) => {
      if (true) {
        console.log('dsd');
        let newobj = {
          'Client name': item.name || '',
          'Client phone': item.phone || '',
          'Client phone2': item.phone2 || '',
          'Client project_intrested_in': item.project_intrested_in || '',
          'Client time_to_call': item.time_to_call || '',
          'Client unitcode': item.unitcode || '',
          'Client client_type': item.client_type || '',
          'Client status': item.status || '',
          'Client status label': item.status_label || '',
          'Client job': item.job || '',
          'Client address': item.address || '',
          // 'agent name': item?.sales[0].name || '',
          // 'agent Email': item?.sales[0].user_email || '',
          // 'agent Phone': item?.sales[0].phone || '',
        };
        pp.push(newobj);
      }
    });
    if (selectedData.length == 0) {
      toast.warn('إختر عناصر أولا');
      return;
    }
    const ids = pp.join('&&');
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(pp);
    XLSX.utils.book_append_sheet(wb, ws, 'MySheet1');
    XLSX.writeFile(wb, 'MyExcel.XLSX');
    // setSelectAll(false);
    let allData = [...data];
    setData(
      allData.map(item => {
        return { ...item, check: false };
      }),
    );
    // gridRef.current.api.exportDataAsCsv(params, {
    //   rowData: selectedData
    // });
  };
  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "#",
      field: "rowNumber",
      valueGetter: (params) => params.node.rowIndex + 1, // This will give you the row number starting from 1
      filter: 'agSetColumnFilter',
      filterParams: {
        caseSensitive: true
      },
      aggFunc:"sum",
      checkboxSelection: true,
      rowDrag: true,
      headerCheckboxSelection: true,
      cellStyle: (params) => ({
        backgroundColor: params.value === "Den" ? "#c3c3c3" : "#b7d8d6",
      }),
      cellClass: (params) =>
        params.value === "Den" ? "class_name" : "another_class_name",
      // tooltipField:"age",
      // headerComponentFramework: CustomHeader
    },
    {
      headerName: 'Name',
      field: "name",
      aggFunc:"sum",
      // tooltipField:"age",
    },

    {
      headerName: "",
      aggFunc:"sum",
      // hide: true,
      cellRenderer: (params) => (
        <div>
        {
          params?.data&&params?.data?.phone!=null&&params?.data.phone!=""&&
          <div>
                <img style={{width:'30px'}} onClick={()=>{
                    window.open(`whatsapp://send?text=!&phone=+2${params.data.phone.startsWith("0")?params.data.phone:`${params.data.phone}`}`,'_blank')
            }} src={require("../../assets/images/whats.png")} alt="" />
            <img style={{width:'30px'}} onClick={()=>{
                    copyContent(params?.data&&params.data.phone)
            }} src={require("../../assets/images/copy.png")} alt="" />
          </div>
        }
      </div>
      ),
    },
    {
      headerName: "phone",
      field: "phone",
      aggFunc:"sum",

      sortable: true,
      // hide: true,
      cellRenderer: (params) => (
        <div>
        {
          params.data&&params.data.phone!=null&&params.data.phone!=""&&
          <div>
            <p>{params.data.phone}</p>
          </div>
        }
      </div>
      ),
    },
    {
      headerName: "",
      field: "phone2",
      aggFunc:"sum",
      // hide: true,
      cellRenderer: (params) => (
        <div>
          {console.log(params.data)}
        {
          params.data&&params.data.phone2!=null&&params.data.phone2!=""&&
          <div>
                          <img style={{width:'30px'}} onClick={()=>{
                    window.open(`whatsapp://send?text=!&phone=+2${params.data.phone.startsWith("0")?params.data.phone:`${params.data.phone}`}`,'_blank')
            }} src={require("../../assets/images/whats.png")} alt="" />
            <img style={{width:'30px'}} onClick={()=>{
                    copyContent(params.data.phone2)
            }} src={require("../../assets/images/copy.png")} alt="" />
          </div>
        }
      </div>
      ),
    },
    {
      headerName: "phone2",
      field: "phone2",
      aggFunc:"sum",
      sortable: true,
      // hide: true,
      cellRenderer: (params) => (
        <div>
          {/* {console.log(params.data)} */}
        {
          params.data&&params.data.phone2!=null&&params.data.phone2!=""&&
          <div>
            <p>{params.data.phone2}</p>
          </div>
        }
      </div>
      ),
    },
    {
      headerName: "project intrested in",
      aggFunc:"sum",
      field: "project_intrested_in",
      cellRenderer: (params) => (
        <div>
          {/* {console.log(params.data)} */}
        {

            <p>{params.data&&params.data.project_intrested_in}</p>

        }
      </div>
      ),
      // tooltipField:"age",
      sortable: true,
    },
    {
      headerName: "added at",
      field: "added_at",
      filter: 'agDateColumnFilter',
      filterParams:filterParams,
      cellEditorParams: {
        minDate: new Date('2000-01-01'), // Set the minimum date
        maxDate: new Date('2030-12-31')  // Set the maximum date
      },
      valueFormatter: params => {
        const date = new Date(params.value);
        return date.toLocaleDateString();
      },
      sortable: true,
    },
    // {
    //   headerName: "last_action_date",
    //   field: "last_action_date",
    //   filter: 'agDateColumnFilter',
    //   filterParams:filterParams,
    //   cellEditorParams: {
    //     minDate: new Date('2000-01-01'), // Set the minimum date
    //     maxDate: new Date('2030-12-31')  // Set the maximum date
    //   },
    //   valueFormatter: params => {
    //     const date = new Date(params.value);
    //     return date.toLocaleDateString();
    //   },
    //   sortable: true,
    // },
    {
      headerName: "Comments",
      aggFunc:"sum",
      field: "acttion",
      cellRenderer: (params) => (
        <div style={{ flexDirection:'column' }} className='d-flex flex-column gap-3'>
            <button className="btn btn-primary"
              onClick={()=>{
                handleGetComments(params?.data?.client_id);
                setRowData(params.data);
                console.log(params?.data)
                setClientId(params?.data);
              }}
            >Comments</button>

        </div>
      ),
      sortable: false,
    },
    {
      headerName: "Calls",
      aggFunc:"sum",
      field: "acttion",
      cellRenderer: (params) => (
        <div style={{ flexDirection:'column' }} className='d-flex flex-column gap-3'>
            <button className="btn btn-primary"
              onClick={()=>{
                handleGetCalls(params.data?.client_id)
                setRowData(params.data)
                setShowCalls(true)
              }}
            >Calls</button>

        </div>
      ),
      sortable: false,
    },
    {
      headerName: "Profile",
      aggFunc:"sum",
      field: "acttion",
      cellRenderer: (params) => (
        <button className="btn btn-primary"
              onClick={()=>{
                window.location.href = "/UserProfile/" + params?.data?.client_id;

              }}
            >Profile</button>
      ),
      sortable: false,
    },
    {
      headerName: "History",
      aggFunc:"sum",
      field: "acttion",
      cellRenderer: (params) => (
        <button className="btn btn-primary"
              onClick={()=>{
                setShowHistoyModel(true)
                console.log(params.data,"paramsparams")
                handleGetHistoy(params?.data?.client_id)
              }}
            >History</button>
      ),
      sortable: false,
    },
    {
      headerName: "Email",
      field: "email",
      sortable: true,
    },
    {
      headerName: "sale name",
      field: "email",
      sortable: true,
      cellRenderer: (params) => (
        <div>
          {
            params?.data&&<div>
              <p>{params?.data?.sales&&params?.data?.sales[0]&&params?.data?.sales[0].name}</p>
            </div>
          }
        </div>
      ),
    },
    {
      headerName: "sale email",
      field: "email",
      sortable: true,
      cellRenderer: (params) => (
        <div>
          {
            params?.data&&<div>
              <p>{params?.data?.sales&&params?.data?.sales[0]&&params?.data?.sales[0].user_email}</p>
            </div>
          }
        </div>
      ),
    },
    {
      headerName: "sale phone",
      field: "email",
      sortable: true,
      cellRenderer: (params) => (
        <div>
          {
            params?.data&&<div>
              <p>{params?.data?.sales&&params?.data?.sales[0]&&params?.data?.sales[0].phone}</p>
            </div>
          }
        </div>
      ),
    },
    {
      headerName: "address",
      field: "address",
      sortable: true,
    },

    {
      headerName: "last action date",
      field: "added_at",
      sortable: true,
      hide: true,
    },

  ]);
  const handleUpdateComment = () => {
    // setAddLoading(true)
    const data_send = {
      ...oldData,
    };
    console.log(rowData,"wkwek",ClientId)
    // return ;
    axios
      .post(
        "https://camp-coding.tech/new_quad_wall/quad_crm/admin/update_comment.php",
        JSON.stringify(data_send)
      )
      .then((res) => {
        console.log(res);
        if (res.status == "success") {
          toast.success(res.message);
          setOldComment(null);
          handleGetComments(ClientId?.client_id);
        } else if (res.status == "error") {
          toast.error(res.message);
        } else {
          toast.error("Something Went Error");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setAddLoading(false);
      });
  };
  const handleDeleteComment = (comment_id,client_id) => {
    // setAddLoading(true)
    const data_send = {
      comment_id,
    };
    console.log(data_send,"data_send",rowData)
    // return ;
    axios
      .post(
        "https://camp-coding.tech/new_quad_wall/quad_crm/admin/delete_comment.php",
        JSON.stringify(data_send)
      )
      .then((res) => {
        console.log(res);
        if (res.status == "success") {
          toast.success(res.message);
          setDeleteComment(null);
          handleGetComments(client_id);
        } else if (res.status == "error") {
          toast.error(res.message);
        } else {
          toast.error("Something Went Error");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setAddLoading(false);
      });
  };
  const [columnComsDefs, setColumnComsDefs] = useState([
    {
      headerName: "#",
      field: "rowNumber",
      valueGetter: (params) => params.node.rowIndex + 1, // This will give you the row number starting from 1
      filter: 'agSetColumnFilter',
      filterParams: {
        caseSensitive: true
      },
      // aggFunc:"sum",
      checkboxSelection: true,
      rowDrag: true,
      headerCheckboxSelection: true,
      cellStyle: (params) => ({
        backgroundColor: params.value === "Den" ? "#c3c3c3" : "#b7d8d6",
      }),
      cellClass: (params) =>
        params.value === "Den" ? "class_name" : "another_class_name",
      // tooltipField:"age",
      // headerComponentFramework: CustomHeader
    },
    {
      headerName: 'comment',
      field: "comment",
      // aggFunc:"sum",
      // tooltipField:"age",
    },
    {
      headerName: "update",
      // aggFunc:"sum",
      field: "acttion",
      cellRenderer: (params) =>{
        const rowIndex = params.node.rowIndex;
        const displayedRowCount = params.api.getDisplayedRowCount();
        const isLastRow = rowIndex === displayedRowCount - 1;
        return (
          <div style={{ flexDirection:'column' }} className='d-flex flex-column gap-3'>
          {/* {console.log(params,"ekllerk")} */}

            {
              isLastRow&&
              <button className="btn btn-success"
              onClick={()=>{
                setOldComment(params?.data);
                setOldData(params?.data);
              }}
              >Update</button>
            }

              {console.log(rowData,"ewklewkllkwe")}
        </div>
        )
      },
      sortable: false,
    },
    {
      headerName: "delete",
      // aggFunc:"sum",
      field: "acttion",
      cellRenderer: (params) => {
        const rowIndex = params.node.rowIndex;
        const displayedRowCount = params.api.getDisplayedRowCount();
        const isLastRow = rowIndex === displayedRowCount - 1;

        return (
          <div style={{ flexDirection: 'column' }} className='d-flex flex-column gap-3'>
            {console.log(params, "ekllerk")}
            {isLastRow && (
              <button onClick={() => {
                let data = params?.data;
                console.log("Current Row Number:", rowIndex);
                setDeleteComment((prev) => ({ ...prev, data, rowIndex })); // Optionally store rowIndex
                handleDeleteComment(params?.data?.comment_id,params?.data?.client_id);
                setRowData(params?.data);
              }} className='btn btn-danger'>
                Delete
              </button>
            )}
          </div>
        );
      },
      sortable: false,
    },
  ]);


  const [columnCallsDefs, setColumnCallsDefs] = useState([
    {
      headerName: "#",
      field: "rowNumber",
      valueGetter: (params) => params.node.rowIndex + 1, // This will give you the row number starting from 1
      filter: 'agSetColumnFilter',
      filterParams: {
        caseSensitive: true
      },
      // aggFunc:"sum",
      checkboxSelection: true,
      rowDrag: true,
      headerCheckboxSelection: true,
      cellStyle: (params) => ({
        backgroundColor: params.value === "Den" ? "#c3c3c3" : "#b7d8d6",
      }),
      cellClass: (params) =>
        params.value === "Den" ? "class_name" : "another_class_name",
      // tooltipField:"age",
      // headerComponentFramework: CustomHeader
    },

    {
      headerName: 'Feed Back',
      field: "feed_back",
      // aggFunc:"sum",
      // tooltipField:"age",
    },
    {
      headerName: 'Call Date',
      field: "date",
      filter: 'agDateColumnFilter',
      filterParams:filterParams,
      cellEditorParams: {
        minDate: new Date('2000-01-01'), // Set the minimum date
        maxDate: new Date('2030-12-31')  // Set the maximum date
      },
      valueFormatter: params => {
        const date = new Date(params.value);
        return date.toLocaleDateString();
      },
      // aggFunc:"sum",
      // tooltipField:"age",
    },
    {
      headerName: "sale Name",
      field: "email",
      sortable: true,
      cellRenderer: (params) => (
        <div>
          {
            params?.data&&<div>
              <p>{params?.data&&params?.data?.agent?.name}</p>
            </div>
          }
        </div>
      ),
    },
    {
      headerName: "sale Email",
      field: "email",
      sortable: true,
      cellRenderer: (params) => (
        <div>
          {
            params?.data&&<div>
              <p>{params?.data&&params?.data?.agent?.user_email}</p>
            </div>
          }
        </div>
      ),
    },
    {
      headerName: "sale phone",
      field: "email",
      sortable: true,
      cellRenderer: (params) => (
        <div>
          {
            params?.data&&<div>
              <p>{params?.data&&params?.data?.agent?.phone}</p>
            </div>
          }
        </div>
      ),
    },


  ]);
  const [columnsCallDefsSales,setColumnCallsDefsSales]=useState([
    {
      headerName: "#",
      field: "rowNumber",
      valueGetter: (params) => params.node.rowIndex + 1, // This will give you the row number starting from 1
      filter: 'agSetColumnFilter',
      filterParams: {
        caseSensitive: true
      },
      // aggFunc:"sum",
      checkboxSelection: true,
      rowDrag: true,
      headerCheckboxSelection: true,
      cellStyle: (params) => ({
        backgroundColor: params.value === "Den" ? "#c3c3c3" : "#b7d8d6",
      }),
      cellClass: (params) =>
        params.value === "Den" ? "class_name" : "another_class_name",
      // tooltipField:"age",
      // headerComponentFramework: CustomHeader
    },

    {
      headerName: 'Feed Back',
      field: "feed_back",
      // aggFunc:"sum",
      // tooltipField:"age",
    },
    {
      headerName: 'Call Date',
      field: "date",
      filter: 'agDateColumnFilter',
      filterParams:filterParams,
      cellEditorParams: {
        minDate: new Date('2000-01-01'), // Set the minimum date
        maxDate: new Date('2030-12-31')  // Set the maximum date
      },
      valueFormatter: params => {
        const date = new Date(params.value);
        return date.toLocaleDateString();
      },
      // aggFunc:"sum",
      // tooltipField:"age",
    },

  ])

  useEffect(()=>{
    setData(data.map((item)=>{
      return{...item,check:selectAll?true:false}
    }))
    updateColumnTitles()
  },[selectAll])
  return (
    <>
      {pageLoading ? (
        <div
          style={{
            minHeight: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Lottie
            style={{ width: "40%" }}
            animationData={groovyWalkAnimation}
            loop={true}
          />
          {/* <img src={require("../../assets/images/Animation - 1717926811158(1).gif")} alt="" /> */}
        </div>
      ) : (
        <div
          className="container py-6"
          style={{
            marginTop: "78px",
            overflow: "auto",
            minHeight: "100vh",
            minWidth: "100%",
          }}
        >
          {/* <div className='my-5 d-flex align-items-center px-2 justify-content-between'>
          <h5>Bulk assign</h5>
          <div className='d-flex align-items-center gap-2'>
            {
              data.filter(it=>it.client_type=='cold').length>80?
              (
                <>
                  <p onClick={()=>{
                    handleAss50(50)
                  }} className='btn btn-primary'>
                    50
                  </p>
                </>
              )
              :
              null
            }
              {
            data.filter(it=>it.client_type=='cold').length>100&&(
              <>
              <p onClick={()=>{
                handleAss50(100)
              }} className='btn btn-primary'>
                100
              </p>
            </>
            )
          }
          <p onClick={()=>{
            handleAss50(5)
          }} className='btn btn-primary'>
            5
          </p>
          <p onClick={()=>{
            handleAss50(10)
          }} className='btn btn-primary'>
            10
          </p>
          {
            data.length>=200&&
            <p onClick={()=>{
              handleAss50(200)
            }} className='btn btn-primary'>
              200
            </p>
          }
          {
            data.length>=150&&
            <p onClick={()=>{
              handleAss50(150)
            }} className='btn btn-primary'>
              150
            </p>
          }
          </div>
        </div> */}
          <div
            className="d-flex row-gap-2 flex-wrap flex-direction-column"
            style={{ margin: "40px 0px" }}
          >
            <Select
              style={{ width: "100%" }}
              // style={{

              // }}
              value={selectedemployee}
              onChange={(e) => {
                //console.log(e)
                setselectedemployee(e);
              }}
              options={employeessdata.map((item, index) => {
                return { label: `${item.name}`, value: `${item.agent_id}` };
              })}
            />
            <Button
              style={{ display: "block", margin: "10px auto" }}
              onClick={() => {
                assignfunction();
              }}
              className="btn btn-primary"
            >
              {assignloading ? <Spin /> : "assign"}
            </Button>
          </div>
          <div className="pagination my-4">
            <button
              className="btn btn-danger mx-2"
              onClick={() => handlePageChange(false)}
            >
              Prev
            </button>
            <button
              className="btn btn-success"
              onClick={() => handlePageChange(true)}
            >
              Next
            </button>
          </div>
          <div className='d-flex align-items-center justify-content-between'>
              {/* <div className='d-flex align-items-center gap-2 my-2'>
                <h4>Select All</h4>
                <input onChange={(e)=>{
                  setSelectAll(!selectAll)
                }} checked={selectAll} style={{
                  width:'15px',
                  height:'15px',

                }} type="checkbox" />
              </div> */}
              <div className="my-3">
                <button
                  onClick={() => {
                    onExportClick()
                    // handleExport();
                  }}
                  className="btn btn-primary text-light"
                >
                  Export
                </button>
              </div>
            </div>
          {/* <ReactTabulator
            data={data.filter((ite) => ite.client_type == "cold")}
            options={options}
            // sortOrderReverse={true}
            columns={coulmns}
            layout="fitData"
          /> */}

<div   style={gridStyle}
              className={
                "ag-theme-quartz-dark"
              }>
            <AgGridReact
            // autoGroupColumnDef ={}
            onFilterChanged={()=>{
               onFilterChanged()
            }}
            enableAdvancedFilter={true}
            grandTotalRow={"top"}
                ref={gridRef}
                rowData={data}
                // sideBar={true}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                onGridReady={onGridReady}
                rowSelection="multiple"
                // columnsi
                rowDragManaged={true}
                tooltipShowDelay={{tooltipShowDelay: 2}}
                onSelectionChanged={onSelectionChanged}
                rowMultiSelectWithClick={true}
                // ref={gridRef}
                isRowSelectable={isRowSelectable}
          // pagination={true}
          // quickFilterText={quickSearchValue}
              />

          </div>

          <Modal className='client_history_model' style={{ width:'90vw' }} title="Client Calls" open={showCalls} onOk={()=>{
        setShowCalls(false)
      }} onCancel={()=>{
        setShowCalls(false)
      }} centered>
        {
          callLoading?
          <div style={{
            minHeight:'30vh',
            display:'flex',
            alignItems:'center',
            justifyContent:'center'
          }}>
            <Lottie style={{ width:'40%' }}  animationData={groovyWalkAnimation} loop={true} />
            {/* <img src={require("../../assets/images/Animation - 1717926811158(1).gif")} alt="" /> */}
          </div>
          :
          <>
            <div style={{width:'100%',cursor:'pointer',justifyContent:'end'}} className='my-2 gap-2 d-flex align-items-center '>
              <div onClick={()=>{
              setShowAddModal(true)
            }} className='btn btn-success d-flex align-items-center gap-2'>
                <AiOutlinePlusCircle className='text-light' style={{ cursor:'pointer',fontSize:'20px' }}/>
                <h4 className='text-light'>New Call</h4>
              </div>
            </div>
            {console.log(calls)}
            {/* <ReactTabulator
              data={calls}
              options={
                options
              }
              columns={callColumns}
              layout={"fitData"}
              /> */}
              <div style={gridStyle} className=  "ag-theme-quartz-dark">

              <AgGridReact
                  onFilterChanged={()=>{
                onFilterChanged()
              }}
              enableAdvancedFilter={true}
              grandTotalRow={"top"}
                  rowData={calls}
                  columnDefs={authUser?.user_type=='sells'?columnCallsDefs:columnsCallDefsSales}
                  defaultColDef={defaultColDef}
                  onGridReady={onGridReady}
                  rowSelection="multiple"
                  rowDragManaged={true}
                  tooltipShowDelay={{tooltipShowDelay: 2}}
                  onSelectionChanged={onSelectionChanged}
                  rowMultiSelectWithClick={true}
                  />
                  </div>
          </>
        }
      </Modal>

          <Modal
            className=""
            style={{ width: "90vw" }}
            title="Client Calls"
            open={showAddModal}
            onOk={() => {
              // setShowAddModal(false)
              handleAddCall();
            }}
            onCancel={() => {
              setShowAddModal(false);
            }}
            centered
          >
            <form style={{ width: "100%" }} action="">
              <div style={{ width: "100%" }}>
                <label className="w-100" htmlFor="">
                  FeedBack
                </label>
                <input
                  value={newCall?.feed_back}
                  onChange={(e) => {
                    setNewCall({ ...newCall, feed_back: e.target.value });
                  }}
                  className="w-100"
                  type="text"
                  placeholder="FeedBack"
                />
              </div>
              {/* <div style={{ width: "100%" }}>
                <label className="w-100" htmlFor="">
                  Call Duration
                </label>
                <input
                  value={newCall?.duration}
                  onChange={(e) => {
                    setNewCall({ ...newCall, duration: e.target.value });
                  }}
                  className="w-100"
                  type="text"
                  placeholder="Call Duration"
                />
              </div> */}
            </form>
          </Modal>
          <Modal
        className="client_history_model"
        style={{ width: "90vw" }}
        title="Client Comments"
        open={ClientId}
        onOk={() => {
          setClientId(false);
        }}
        onCancel={() => {
          setClientId(false);
        }}
        centered
      >
        {callLoading ? (
          <div
            style={{
              minHeight: "30vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Lottie
              style={{ width: "40%" }}
              animationData={groovyWalkAnimation}
              loop={true}
            />
            {/* <img src={require("../../assets/images/Animation - 1717926811158(1).gif")} alt="" /> */}
          </div>
        ) : (
          <>
            <div

              style={{ width: "fit-content", cursor: "pointer" }}
              className="my-2 gap-2 d-flex align-items-center w-100 d-flex align-items-center justify-content-end"
            >
              <div onClick={() => {
                setOpenNewCommect(true);
              }} className='btn btn-success d-flex align-items-center gap-2'>
                <AiOutlinePlusCircle
                  className="text-success text-light"
                  style={{ cursor: "pointer", fontSize: "20px" }}
                />
                <h4 className='text-light'>New Comment</h4>
              </div>
            </div>
            {/* <ReactTabulator
              data={commentsData}
              options={options}
              columns={commentColumns}
              layout={"fitData"}
            /> */}
            <div style={gridStyle}
              className={
                "ag-theme-quartz-dark"
              }>
                <AgGridReact
              // autoGroupColumnDef ={}
                  onFilterChanged={()=>{
                onFilterChanged()
              }}
              enableAdvancedFilter={true}
              grandTotalRow={"top"}
                  // ref={gridRef}
                  rowData={commentsData}
                  // sideBar={true}
                  columnDefs={columnComsDefs}
                  defaultColDef={defaultColDef}
                  onGridReady={onGridReady}
                  rowSelection="multiple"
                  // columnsi
                  rowDragManaged={true}
                  tooltipShowDelay={{tooltipShowDelay: 2}}
                  onSelectionChanged={onSelectionChanged}
                  rowMultiSelectWithClick={true}
                />
              </div>

          </>
        )}
      </Modal>

      <Modal
        className=""
        style={{ width: "90vw", zIndex: 1233223412 }}
        title="Client Comments"
        open={oldComment}
        onOk={() => {
          // setShowAddModal(false)
          handleUpdateComment();
        }}
        onCancel={() => {
          setOldComment(false);
        }}
        centered
      >
        <form style={{ width: "100%" }} action="">
          <div style={{ width: "100%" }}>
            <label className="w-100" htmlFor="">
              Comment
            </label>
            <textarea
              className="w-100"
              type="text"
              placeholder="Comment"
              value={oldData?.comment}
              onChange={(e) => {
                setOldData({
                  ...oldData,
                  comment: e.target.value,
                });
              }}
            ></textarea>
          </div>
        </form>
      </Modal>

          <Modal className='client_history_model' style={{ width:'90vw' }} title="Client History" open={showHistoyModel} onOk={()=>{
        setShowHistoyModel(false)
      }} onCancel={()=>{
        setShowHistoyModel(false)
      }} centered>
        {
          historyLoading?
          <div style={{
            minHeight:'30vh',
            display:'flex',
            alignItems:'center',
            justifyContent:'center'
          }}>
            <Lottie style={{ width:'40%' }}  animationData={groovyWalkAnimation} loop={true} />
            {/* <img src={require("../../assets/images/Animation - 1717926811158(1).gif")} alt="" /> */}
          </div>
          :
          <>
          {console.log(ClientHistory,"ClientHistory")}
            {/* <ReactTabulator
              data={ClientHistory}
              options={
                options
              }
              columns={historyColumns}
              layout={"fitData"}
              /> */}
              <div style={gridStyle} className="ag-theme-quartz-dark">
                <AgGridReact
                // autoGroupColumnDef ={}
                    onFilterChanged={()=>{
                  onFilterChanged()
                }}
                enableAdvancedFilter={true}
                grandTotalRow={"top"}
                    // ref={gridRef}
                    rowData={ClientHistory}
                    // sideBar={true}
                    columnDefs={authUser?.user_type=='sells'?columnHisSelsDefs:columnHisDefs}
                    defaultColDef={defaultColDef}
                    onGridReady={onGridReady}
                    rowSelection="multiple"
                    // columnsi
                    rowDragManaged={true}
                    tooltipShowDelay={{tooltipShowDelay: 2}}
                    onSelectionChanged={onSelectionChanged}
                    rowMultiSelectWithClick={true}
                  />
              </div>
              {console.log(ClientHistory,"historyColumns")}
          </>
        }
      </Modal>
      <Modal
        className=" add_com_mod"
        style={{ width: "90vw", zIndex: 123324387874387434784387875412 }}
        title="Client Comments"
        open={openComment}
        onOk={() => {
          // setShowAddModal(false)
          handleAddComment();
        }}
        onCancel={() => {
          setOpenNewCommect(false);
        }}
        centered
      >
        <form style={{ width: "100%" }} action="">
          <div style={{ width: "100%" }}>
            <label className="w-100" htmlFor="">
              Comment
            </label>
            <textarea
              className="w-100"
              type="text"
              placeholder="Comment"
              value={comment}
              onChange={(e) => {
                setComment(e.target.value);
              }}
            ></textarea>
          </div>
        </form>
      </Modal>
        </div>
      )}
    </>
  );
};

export default OtherColds;
