import React, {useMemo, useRef, useState} from "react";
import {AgGridReact} from "ag-grid-react"; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { toast } from "react-toastify";
import * as XLSX from 'xlsx';
import CustomHeader from "./CustomHeader";
import { ColumnsToolPanelModule } from "@ag-grid-enterprise/column-tool-panel";
import { FiltersToolPanelModule } from "@ag-grid-enterprise/filter-tool-panel";
import { MenuModule } from "@ag-grid-enterprise/menu";
import { RowGroupingModule } from "@ag-grid-enterprise/row-grouping";
import { SideBarModule } from "@ag-grid-enterprise/side-bar";
import {
  ColDef,
  ColGroupDef,
  GridApi,
  GridOptions,
  GridReadyEvent,
  ModuleRegistry,
  SideBarDef,
  createGrid,
} from "@ag-grid-community/core";
import { SetFilterModule } from "@ag-grid-enterprise/set-filter";

import { CsvExportModule } from "@ag-grid-community/csv-export";
import { ExcelExportModule } from "@ag-grid-enterprise/excel-export";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
ModuleRegistry.registerModules([
  ClientSideRowModelModule,
  CsvExportModule,
  ExcelExportModule,
  MenuModule,
]);
  ModuleRegistry.registerModules([
    ClientSideRowModelModule,
    ColumnsToolPanelModule,
    FiltersToolPanelModule,
    MenuModule,
    RowGroupingModule,
    SetFilterModule,
  ]);

export const AgTable = () => {
  const autoGroupColumnDef = useMemo(() => {
    return {
      headerName: "id",
      field: "id",
      rowDrag: true,
      // use font awesome for first col, with numbers for sort
      icons: {
        menu: '<i class="fa fa-shower"/>',
        menuAlt: '<i class="fa fa-shower"/>',
        filter: '<i class="fa fa-long-arrow-alt-up"/>',
        columns: '<i class="fa fa-snowflake"/>',
        sortAscending: '<i class="fa fa-sort-alpha-up"/>',
        sortDescending: '<i class="fa fa-sort-alpha-down"/>',
      },
      headerCheckboxSelection: true,
      width: 300,
    };
  }, []);
  ModuleRegistry.registerModules([
    ClientSideRowModelModule,
    ColumnsToolPanelModule,
    FiltersToolPanelModule,
    RowGroupingModule,
    SideBarModule,
    MenuModule,
  ]);
  const [gridApi, setGridApi] = useState(null);
  const [columnApi, setColumnApi] = useState(null);
  const [paginationPageSize, setPaginationPageSize] = useState(10);
  const [data, setData] = useState([
    {
      postId: 1,
      id: 1,
      name: "id labore ex et quam laborum",
      email: "Eliseo@gardner.biz",
      body: "laudantium enim quasi est quidem magnam voluptate ipsam eos\ntempora quo necessitatibus\ndolor quam autem quasi\nreiciendis et nam sapiente accusantium",
    },
    {
      postId: 1,
      id: 2,
      name: "quo vero reiciendis velit similique earum",
      email: "Jayne_Kuhic@sydney.com",
      body: "est natus enim nihil est dolore omnis voluptatem numquam\net omnis occaecati quod ullam at\nvoluptatem error expedita pariatur\nnihil sint nostrum voluptatem reiciendis et",
    },
    {
      postId: 1,
      id: 3,
      name: "odio adipisci rerum aut animi",
      email: "Nikita@garfield.biz",
      body: "quia molestiae reprehenderit quasi aspernatur\naut expedita occaecati aliquam eveniet laudantium\nomnis quibusdam delectus saepe quia accusamus maiores nam est\ncum et ducimus et vero voluptates excepturi deleniti ratione",
    },
    {
      postId: 1,
      id: 4,
      name: "alias odio sit",
      email: "Lew@alysha.tv",
      body: "non et atque\noccaecati deserunt quas accusantium unde odit nobis qui voluptatem\nquia voluptas consequuntur itaque dolor\net qui rerum deleniti ut occaecati",
    },
    {
      postId: 1,
      id: 5,
      name: "vero eaque aliquid doloribus et culpa",
      email: "Hayden@althea.biz",
      body: "harum non quasi et ratione\ntempore iure ex voluptates in ratione\nharum architecto fugit inventore cupiditate\nvoluptates magni quo et",
    },
    {
      postId: 2,
      id: 6,
      name: "et fugit eligendi deleniti quidem qui sint nihil autem",
      email: "Presley.Mueller@myrl.com",
      body: "doloribus at sed quis culpa deserunt consectetur qui praesentium\naccusamus fugiat dicta\nvoluptatem rerum ut voluptate autem\nvoluptatem repellendus aspernatur dolorem in",
    },
    {
      postId: 2,
      id: 7,
      name: "repellat consequatur praesentium vel minus molestias voluptatum",
      email: "Dallas@ole.me",
      body: "maiores sed dolores similique labore et inventore et\nquasi temporibus esse sunt id et\neos voluptatem aliquam\naliquid ratione corporis molestiae mollitia quia et magnam dolor",
    },
    {
      postId: 2,
      id: 8,
      name: "et omnis dolorem",
      email: "Mallory_Kunze@marie.org",
      body: "ut voluptatem corrupti velit\nad voluptatem maiores\net nisi velit vero accusamus maiores\nvoluptates quia aliquid ullam eaque",
    },
    {
      postId: 2,
      id: 9,
      name: "provident id voluptas",
      email: "Meghan_Littel@rene.us",
      body: "sapiente assumenda molestiae atque\nadipisci laborum distinctio aperiam et ab ut omnis\net occaecati aspernatur odit sit rem expedita\nquas enim ipsam minus",
    },
    {
      postId: 2,
      id: 10,
      name: "eaque et deleniti atque tenetur ut quo ut",
      email: "Carmen_Keeling@caroline.name",
      body: "voluptate iusto quis nobis reprehenderit ipsum amet nulla\nquia quas dolores velit et non\naut quia necessitatibus\nnostrum quaerat nulla et accusamus nisi facilis",
    },
    {
      postId: 3,
      id: 11,
      name: "fugit labore quia mollitia quas deserunt nostrum sunt",
      email: "Veronica_Goodwin@timmothy.net",
      body: "ut dolorum nostrum id quia aut est\nfuga est inventore vel eligendi explicabo quis consectetur\naut occaecati repellat id natus quo est\nut blanditiis quia ut vel ut maiores ea",
    },
    {
      postId: 3,
      id: 12,
      name: "modi ut eos dolores illum nam dolor",
      email: "Oswald.Vandervort@leanne.org",
      body: "expedita maiores dignissimos facilis\nipsum est rem est fugit velit sequi\neum odio dolores dolor totam\noccaecati ratione eius rem velit",
    },
    {
      postId: 3,
      id: 13,
      name: "aut inventore non pariatur sit vitae voluptatem sapiente",
      email: "Kariane@jadyn.tv",
      body: "fuga eos qui dolor rerum\ninventore corporis exercitationem\ncorporis cupiditate et deserunt recusandae est sed quis culpa\neum maiores corporis et",
    },
    {
      postId: 3,
      id: 14,
      name: "et officiis id praesentium hic aut ipsa dolorem repudiandae",
      email: "Nathan@solon.io",
      body: "vel quae voluptas qui exercitationem\nvoluptatibus unde sed\nminima et qui ipsam aspernatur\nexpedita magnam laudantium et et quaerat ut qui dolorum",
    },
    {
      postId: 3,
      id: 15,
      name: "debitis magnam hic odit aut ullam nostrum tenetur",
      email: "Maynard.Hodkiewicz@roberta.com",
      body: "nihil ut voluptates blanditiis autem odio dicta rerum\nquisquam saepe et est\nsunt quasi nemo laudantium deserunt\nmolestias tempora quo quia",
    },
    {
      postId: 4,
      id: 16,
      name: "perferendis temporibus delectus optio ea eum ratione dolorum",
      email: "Christine@ayana.info",
      body: "iste ut laborum aliquid velit facere itaque\nquo ut soluta dicta voluptate\nerror tempore aut et\nsequi reiciendis dignissimos expedita consequuntur libero sed fugiat facilis",
    },
    {
      postId: 4,
      id: 17,
      name: "eos est animi quis",
      email: "Preston_Hudson@blaise.tv",
      body: "consequatur necessitatibus totam sed sit dolorum\nrecusandae quae odio excepturi voluptatum harum voluptas\nquisquam sit ad eveniet delectus\ndoloribus odio qui non labore",
    },
    {
      postId: 4,
      id: 18,
      name: "aut et tenetur ducimus illum aut nulla ab",
      email: "Vincenza_Klocko@albertha.name",
      body: "veritatis voluptates necessitatibus maiores corrupti\nneque et exercitationem amet sit et\nullam velit sit magnam laborum\nmagni ut molestias",
    },
    {
      postId: 4,
      id: 19,
      name: "sed impedit rerum quia et et inventore unde officiis",
      email: "Madelynn.Gorczany@darion.biz",
      body: "doloribus est illo sed minima aperiam\nut dignissimos accusantium tempore atque et aut molestiae\nmagni ut accusamus voluptatem quos ut voluptates\nquisquam porro sed architecto ut",
    },
    {
      postId: 4,
      id: 20,
      name: "molestias expedita iste aliquid voluptates",
      email: "Mariana_Orn@preston.org",
      body: "qui harum consequatur fugiat\net eligendi perferendis at molestiae commodi ducimus\ndoloremque asperiores numquam qui\nut sit dignissimos reprehenderit tempore",
    },
  ]);
  const gridRef = useRef(null);
  const [quickSearchValue, setQuickSearchValue] = useState("");

  const [hide, setHide] = useState(false);

  const columns = [
    {
      headerName: "#",
      field: "id",
      checkboxSelection: true,
      rowDrag:true,
      headerCheckboxSelection: true,
      cellStyle: (params) => ({
        backgroundColor: params.value == "Den" ? "#c3c3c3" : "#b7d8d6",
      }),
      cellClass: (params) =>
        params.value == "Den" ? "class_name" : "another_class_name",
      // tooltipField:"age",
      headerComponentFramework: CustomHeader,
    },
    {
      headerName: "Post Id",
      field: "postId",
      // tooltipField:"age",
    },
    {
      headerName: "Name",
      field: "name",
      sortable: true,
      hide: true,
    },
    {
      headerName: "Email",
      field: "email",
      sortable: true,
    },
    {
      headerName: "Body",
      field: "body",
      sortable: true,
      hide: true,
    },
    {
      headerName: "Action",
      field: "acttion",
      cellRenderer: (params) => (
        <button onClick={() => console.log(params.data)}>Click Me</button>
      ),
      sortable: false,
    },
  ];

  const defaultColDef =useMemo(()=> {
    return{

      sortable: true,
      editable: true,
      filter: true,
      floatingFilter: true,
      onCellValueChanged: (e) => console.log(e),
      flex: 1,
    }
  },[]);

  const onGridReady = (params) => {
    setGridApi(params);
    console.log(params);
    setColumnApi(params.gridApi);
  };
  const onExportClick = () => {
    // gridApi.api.exportDataAsCsv();
    const selectedNodes = gridRef.current.api.getSelectedNodes();
    const selectedData = selectedNodes.map(node => node.data);
    console.log(selectedData)
    const params = {
      fileName: 'selected_rows.csv',
      columnSeparator: ',',
      skipHeader: false,
      columnKeys: ['make', 'model', 'price']
    };
    const pp = [];
    selectedData.map((item, index) => {
      if (true) {
        console.log('dsd');
        let newobj = {
          'Cleint name': item.name || '',
          'Cleint phone': item.email || '',
          'Cleint phone2': item.body || '',
          'Cleint project_intrested_in': item.project_intrested_in || '',
          'Cleint time_to_call': item.time_to_call || '',
          'Cleint unitcode': item.unitcode || '',
          'Cleint client_type': item.client_type || '',
          'Cleint status': item.status || '',
          'Cleint status label': item.status_label || '',
          'Cleint job': item.job || '',
          'Cleint address': item.address || '',
          'agent name': item.agent_name || '',
          'agent Email': item.agent_email || '',
          'agent Phone': item.agent_phone || '',
        };
        pp.push(newobj);
      }
    });
    if (selectedData.length == 0) {
      toast.warn('إختر عناصر أولا');
      return;
    }
    const ids = pp.join('&&');
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(pp);
    XLSX.utils.book_append_sheet(wb, ws, 'MySheet1');
    XLSX.writeFile(wb, 'MyExcel.XLSX');
    // setSelectAll(false);
    let allData = [...data];
    setData(
      allData.map(item => {
        return { ...item, check: false };
      }),
    );
    // gridRef.current.api.exportDataAsCsv(params, {
    //   rowData: selectedData
    // });
  };

  // const rowSelectionType = "single"
  const rowSelectionType = "multiple";

  const onSelectionChanged = (event) => {
    console.log(event.api.getSelectedRows());
  };

  const isRowSelectable = (node) => {
    return true; // or node.data.<column>
  };

  const onPaginationPageSizeChanged = (newPageSize) => {
    setPaginationPageSize(Number(newPageSize));
    gridApi.api.paginationSetPageSize(Number(newPageSize));
    // onGridReady()
  };

  let hideCol = true;

  const showHiddenColumn = () => {
    gridApi.api.setColumnsVisible(["body", "name"], hideCol);
    // console.log(gridColApi)
    hideCol = !hideCol;
    gridApi.api.sizeColumnsToFit();
  };

  const onFilterTextChange = (e) => {
    return e.target.value;
  };

  // gridRef.current.api.exportDataAsCsv(params, {
  //   rowData: selectedData
  // });



  return (
    <div  style={{
      marginTop:'30px',
      overflow:'auto',
      minWidth:'100%'
    }} className='py-5 container'>
      <button onClick={() => onExportClick()}>export</button>
      <button onClick={showHiddenColumn}>Show Hidden Column</button>
      <div></div>
      <select
        id='page-size'
        value={paginationPageSize}
        onChange={(e) => onPaginationPageSizeChanged(e.target.value)}
      >
        <option value='5'>5</option>
        <option value='10'>10</option>
        <option value='20'>20</option>
      </select>
      <div>
        <input
          value={quickSearchValue}
          onChange={(e) => setQuickSearchValue(e.target.value)}
          type='search'
          placeholder='search something...'
        />
      </div>
      <div
        className='ag-theme-quartz-dark' // applying the Data Grid theme
        style={{height: "400px"}} // the Data Grid will fill the size of the parent container
      >
        <AgGridReact
          rowData={data}
          sideBar={true}
          columnDefs={columns}

          tooltipShowDelay={{tooltipShowDelay: 2}}
          defaultColDef={defaultColDef}
          onGridReady={onGridReady}

          onSelectionChanged={onSelectionChanged}
          rowMultiSelectWithClick={true}
          rowSelection="multiple"
          // ref={gridRef}
          isRowSelectable={isRowSelectable}
          // pagination={true}
          autoGroupColumnDef={autoGroupColumnDef}
          rowDragManaged={true}
          // paginationPageSize={paginationPageSize}
          // paginationPageSizeSelector={false}
          quickFilterText={quickSearchValue}
          ref={gridRef}

          // paginationPageSize={4}
          // paginationAutoPageSize={true}
        />
      </div>{" "}
    </div>
  );
};
