
  import React, {
    useCallback,
    useMemo,
    useRef,
    useState,
    StrictMode,
    useEffect,
  } from "react";
  import { createRoot } from "react-dom/client";
  import { AgGridReact } from "@ag-grid-community/react";
  import "@ag-grid-community/styles/ag-grid.css";
  import "@ag-grid-community/styles/ag-theme-quartz.css";
  // import "./styles.css";
  import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
  import { ModuleRegistry } from "@ag-grid-community/core";
  import { ColumnsToolPanelModule } from "@ag-grid-enterprise/column-tool-panel";
  import { FiltersToolPanelModule } from "@ag-grid-enterprise/filter-tool-panel";
  import { MenuModule } from "@ag-grid-enterprise/menu";
  import { RowGroupingModule } from "@ag-grid-enterprise/row-grouping";
  import { SetFilterModule } from "@ag-grid-enterprise/set-filter";
import { CsvExportModule } from "@ag-grid-community/csv-export";
import { ExcelExportModule } from "@ag-grid-enterprise/excel-export";
import axios from "axios";
import { toast } from "react-toastify";
ModuleRegistry.registerModules([
  ClientSideRowModelModule,
  CsvExportModule,
  ExcelExportModule,
  MenuModule,
]);
  ModuleRegistry.registerModules([
    ClientSideRowModelModule,
    ColumnsToolPanelModule,
    FiltersToolPanelModule,
    MenuModule,
    RowGroupingModule,
    SetFilterModule,
  ]);
  ModuleRegistry.registerModules([
    ClientSideRowModelModule,
    ColumnsToolPanelModule,
    FiltersToolPanelModule,
    RowGroupingModule,
    // SideBarModule,
    MenuModule,
  ]);

  const TestTable = () => {
    const gridRef = useRef();
    const [pageloading,setpageloading]=useState(true);
    const authUser=localStorage.getItem("authUser");

    let uData=JSON.parse(authUser);

    const [gridApi, setGridApi] = useState(null);
    const [data, setData] = useState([]);
    const [quickSearchValue, setQuickSearchValue] = useState("");
    const [employeessdata,setemployeessdata]=useState([]);
    const [comment, setComment] = useState("");
    const [ClientId, setClientId] = useState(null);

    const [commentsData, setCommentsData] = useState([]);

    const containerStyle = useMemo(() => ({ width: "100%", height: "100vh" }), []);
    const gridStyle = useMemo(() => ({ height: "100vh", width: "100%" }), []);
    const [rowData, setRowData] = useState();
    const [callLoading,setCallLoading]=useState(false);

    const [selectedemployee,setselectedemployee]=useState("");

    const copyContent = async (phone) => {
      try {
        await navigator.clipboard.writeText(phone);
        console.log("Content copied to clipboard");
        toast.success("Content copied to clipboard");
      } catch (err) {
        console.error("Failed to copy: ", err);
      }
    };


    const handleGetComments = async (id) => {
      setCallLoading(true);
      const data_send = {
        client_id: id,
      };
      await axios
        .post(
          "https://camp-coding.tech/new_quad_wall/quad_crm/admin/get_client_comments.php",
          JSON.stringify(data_send)
        )
        .then((res) => {
          console.log(res.message);
          if (res.status == "success") {
            if (Array.isArray(res.message)) {
              setCommentsData(res.message);
            }
          }
        })
        .catch((e) => console.log(e))
        .finally(() => {
          setCallLoading(false);
        });
    };
    const [columnDefs, setColumnDefs] = useState([
      {
        headerName: "#",
        field: "rowNumber",
        valueGetter: (params) => params.node.rowIndex + 1, // This will give you the row number starting from 1
        filter: 'agSetColumnFilter',
        filterParams: {
          caseSensitive: true
        },
        checkboxSelection: true,
        rowDrag: true,
        headerCheckboxSelection: true,
        cellStyle: (params) => ({
          backgroundColor: params.value === "Den" ? "#c3c3c3" : "#b7d8d6",
        }),
        cellClass: (params) =>
          params.value === "Den" ? "class_name" : "another_class_name",
        // tooltipField:"age",
        // headerComponentFramework: CustomHeader
      },
      {
        headerName: "Name",
        field: "name",
        // tooltipField:"age",
      },
      {
        headerName: "phone",
        field: "phone",
        sortable: true,
        // hide: true,
        cellRenderer: (params) => (
          <div>
          {
            params.data.phone!=null&&params.data.phone!=""&&
            <div>
                <img style={{width:'30px'}} onClick={()=>{
                      window.open(`whatsapp://send?text=!&phone=+2${params.data.phone.startsWith("0")?params.data.phone:`${params.data.phone}`}`,'_blank')
              }} src={require("../assets/images/whats.png")} alt="" />
              <img style={{width:'30px'}} onClick={()=>{
                      copyContent(params.data.phone)
              }} src={require("../assets/images/copy.png")} alt="" />
              <p>{params.data.phone}</p>
            </div>
          }
        </div>
        ),
      },
      {
        headerName: "phone2",
        field: "phone2",
        sortable: true,
        // hide: true,
        cellRenderer: (params) => (
          <div>
            {console.log(params.data)}
          {
            params.data.phone2!=null&&params.data.phone2!=""&&
            <div>
                <img style={{width:'30px'}} onClick={()=>{
                      window.open(`whatsapp://send?text=!&phone=+2${params.data.phone.startsWith("0")?params.data.phone:`${params.data.phone}`}`,'_blank')
              }} src={require("../assets/images/whats.png")} alt="" />
              <img style={{width:'30px'}} onClick={()=>{
                      copyContent(params.data.phone2)
              }} src={require("../assets/images/copy.png")} alt="" />
              <p>{params.data.phone2}</p>
            </div>
          }
        </div>
        ),
      },
      {
        headerName: "project intrested in",
        field: "project_intrested_in",
        // tooltipField:"age",
        sortable: true,
      },
      {
        headerName: "Comments",
        field: "acttion",
        cellRenderer: (params) => (
          <div>
              <button className="btn btn-primary"
                onClick={()=>{
                  handleGetComments(params?.data?.client_id);
                  setRowData(params?.data?.client_id);
                  setClientId(params?.data);
                }}
              >Comments</button>
              <button className="btn btn-primary">Profile</button>
          </div>
        ),
        sortable: false,
      },
      {
        headerName: "Email",
        field: "email",
        sortable: true,
      },
      {
        headerName: "address",
        field: "address",
        sortable: true,
      },
      {
        headerName: "last action date",
        field: "added_at",
        sortable: true,
        hide: true,
      },
      {
        headerName: "Action",
        field: "acttion",
        cellRenderer: (params) => (
          <div>

          </div>
        ),
        sortable: false,
      },
    ]);
    const defaultColDef = useMemo(() => {
      return {
        sortable: true,
        editable: true,
        filter: true,
        floatingFilter: true,
        onCellValueChanged: (e) => console.log(e),
        flex: 1,
      };
    }, []);

    const onGridReady = useCallback((params) => {
      fetch("https://www.ag-grid.com/example-assets/small-olympic-winners.json")
        .then((resp) => resp.json())
        .then((data) => {
          setRowData(data);
        });
    }, []);
    const onSelectionChanged = (event) => {
      console.log(event.api.getSelectedRows());
    };

    const isRowSelectable = (node) => {
      return true; // or node.data.<column>
    };

    const getNotAnswer=()=>{
      setpageloading(true)
      const data_send={
        type:uData.user_type,
        agent_id:uData.agent_id,
            status:'no_answer',
        client_type:"leads"
      }
      console.log(data_send)
      //get_other_agent_client_status
      axios.post('https://camp-coding.tech/new_quad_wall/quad_crm/admin/new_client_by_status.php',JSON.stringify(data_send))
      .then((res)=>{
        console.log(res,"this is may response")
        if(Array.isArray(res.message)){
          setData(res.message.map((item)=>{
            return {...item,check:false,image:require("../assets/images/whats.png"),copyimg:require("../assets/images/copy.png")}
          }))
        }
      })
      .catch(e=>console.log(e))
      .finally(()=>{
        setpageloading(false)
      })
    }


    const geteployees=()=>{
      axios.get("https://camp-coding.tech/new_quad_wall/quad_crm/admin/select_ageint.php")
      .then((res)=>{
        //console.log(res)
        //console.log(res);
        setemployeessdata(res.message);
        if(res.message.length>0)
        setselectedemployee(res?.message[0]?.agent_id)
        //console.log(res?.message[0]?.name);
      })
    }


    const onBtExport = useCallback(() => {
      gridRef.current.api.exportDataAsExcel();
    }, []);

    useEffect(()=>{
      getNotAnswer()
      geteployees()
    },[])

    return (
      <div style={{
        ...containerStyle,
        marginTop:'30px',
        overflow:'auto',
        minWidth:'100%'
      }} className='py-5 container'>
        <div className="container">
          <div>
            <button
              onClick={onBtExport}
              style={{ marginBottom: "5px", fontWeight: "bold" }}
            >
              Export to Excel
            </button>
          </div>
          <div className="grid-wrapper">

            <div
              style={gridStyle}
              className={
                "ag-theme-quartz-dark"
              }
            >
              <AgGridReact
                ref={gridRef}
                rowData={data}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                onGridReady={onGridReady}
                rowSelection="multiple"
                rowDragManaged={true}
                tooltipShowDelay={{tooltipShowDelay: 2}}
                onSelectionChanged={onSelectionChanged}
                rowMultiSelectWithClick={true}
                // ref={gridRef}
                isRowSelectable={isRowSelectable}
          // pagination={true}
          quickFilterText={quickSearchValue}


              />
            </div>
          </div>
        </div>
      </div>
    );
  };
export default TestTable;
