import React, { useState, useEffect, useRef } from "react";

const CustomHeader = (props) => {
  const [showChooser, setShowChooser] = useState(false);
  const [columns, setColumns] = useState([]);
  const dropdownRef = useRef(null);

  useEffect(() => {
    setColumns(props.columnApi.getAllColumns().map((col) => ({
      colId: col.getColId(),
      headerName: col.getColDef().headerName,
      visible: col.isVisible(),
    })));
  }, [props.columnApi]);

  const toggleChooser = () => {
    setShowChooser(!showChooser);
  };

  const toggleColumn = (colId) => {
    const isVisible = props.columnApi.getColumnState().find(col => col.colId === colId).hide;
    props.columnApi.setColumnVisible(colId, !isVisible);
    setColumns(columns.map(col => col.colId === colId ? { ...col, visible: !col.visible } : col));
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowChooser(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <div className="custom-header">
      <div className="header-title">
        {props.displayName}
      </div>
      <button onClick={toggleChooser} className="column-chooser-button">
        ⋮
      </button>
      {showChooser && (
        <div className="column-chooser" ref={dropdownRef}>
          {columns.map((col) => (
            <div key={col.colId}>
              <input
                type="checkbox"
                checked={col.visible}
                onChange={() => toggleColumn(col.colId)}
              />
              <label>{col.headerName}</label>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CustomHeader;
