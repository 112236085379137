import React, { useEffect, useState } from 'react'
import { useContext } from 'react'
import { AppContext } from '../../components/context/AppContextProvider'//context/AppContextProvider
import axios from 'axios';
import { Card, Col, Modal } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-tabulator/lib/styles.css';
import "tabulator-tables/dist/css/tabulator.min.css"; //import Tabulator stylesheet
import { ReactTabulator } from 'react-tabulator'
const FollowUp = () => {
  const {user}=useContext(AppContext);
  const navigate=useNavigate()
  const [selectedType,setSelectedType]=useState('cold');


  const rowMoved = (row) => {
    console.log('Row moved:', row.getData());
  };

  const options = {
    pagination:true, //enable pagination
    paginationSize:10, //optional parameter to request a certain number of rows per page
    paginationInitialPage:1, 
    paginationSizeSelector:[5,10,20, 25,30,40, 50],
    initialSort: [
      { column: 'age', dir: 'asc' }, // sort by age in ascending order
    ],
    movableRows: true,movableColumns: true, // enable row dragging
    rowMoved: rowMoved, // optional, handle row move events
  };

  const agent_id=JSON.parse(localStorage.getItem("authUser"))?.agent_id;
  const [pageLoading,setPageLoading]=useState(false);
  const [meetings,setMettings]=useState([]);
  const [calls,setCalls]=useState([]);
  const [callData,setCallData]=useState({})
  const [showReqModal,setShowReqModal]=useState(false)
  const [calldata,setCalldata]=useState({});



  const copyContent = async (phone) => {
    try {
      await navigator.clipboard.writeText(phone);
      console.log('Content copied to clipboard');
      toast.success('Content copied to clipboard')
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  }

  const phone1lead= (cell, formatterParams, onRendered)=>{
    const value = cell.getValue();
    const row = cell.getRow();
    const rowData = row.getData();
    const div=document.createElement('div');

    const img = document.createElement("img");
    img.src = rowData?.image;
    img.alt = "Profile";
    img.style.width = "30px";
    img.style.height = "30px";
    img.style.cursor = "pointer";

    const phone=document.createElement('span')
    phone.innerText=rowData.client_phone;
    div.appendChild(phone);
    // div.appendChild(img);

    const imgcopy=document.createElement("img");

    imgcopy.src=rowData.copyimg
    imgcopy.alt = "copy";
    imgcopy.style.width = "30px";
    imgcopy.style.height = "30px";
    imgcopy.style.cursor = "pointer";

    img.addEventListener("click", () => {
      console.log(rowData);
      window.open(`whatsapp://send?text=!&phone=+2${rowData.client_phone.startsWith("0")?rowData.client_phone:`0${rowData.client_phone}`}`,'_blank')
      // alert(JSON.stringify(rowData, null, 2)); // For demonstration purposes
    });
    imgcopy.addEventListener("click", () => {
      console.log(rowData,"copy");
      // window.open(`whatsapp://send?text=!&phone=+2${rowData.client_phone.startsWith("0")?rowData.client_phone:`0${rowData.client_phone}`}`,'_blank')
      // alert(JSON.stringify(rowData, null, 2)); // For demonstration purposes
    });

    const imgs_div=document.createElement('div');
    imgs_div.appendChild(imgcopy);
    imgs_div.appendChild(img);
    div.appendChild(imgs_div)
    div.style.display='flex';
    div.style.alignItems='center';
    div.style.justifyContent='space-between'
    imgcopy.addEventListener('click',function(){
      // console.log(rowData)
      copyContent(rowData.client_phone)
    })
    return div;
  }

  const phone2lead= (cell, formatterParams, onRendered)=>{
    const value = cell.getValue();
    const row = cell.getRow();
    const rowData = row.getData();
    const div=document.createElement('div');

    const img = document.createElement("img");
    img.src = rowData?.image;
    img.alt = "Profile";
    img.style.width = "30px";
    img.style.height = "30px";
    img.style.cursor = "pointer";

    const phone=document.createElement('span')
    phone.innerText=rowData.client_phone;
    div.appendChild(phone);
    // div.appendChild(img);

    const imgcopy=document.createElement("img");

    imgcopy.src=rowData.copyimg
    imgcopy.alt = "copy";
    imgcopy.style.width = "30px";
    imgcopy.style.height = "30px";
    imgcopy.style.cursor = "pointer";

    img.addEventListener("click", () => {
      console.log(rowData);
      window.open(`whatsapp://send?text=!&phone=+2${rowData.client_phone.startsWith("0")?rowData.client_phone:`0${rowData.client_phone}`}`,'_blank')
      // alert(JSON.stringify(rowData, null, 2)); // For demonstration purposes
    });
    imgcopy.addEventListener("click", () => {
      console.log(rowData,"copy");
      // window.open(`whatsapp://send?text=!&phone=+2${rowData.client_phone.startsWith("0")?rowData.client_phone:`0${rowData.client_phone}`}`,'_blank')
      // alert(JSON.stringify(rowData, null, 2)); // For demonstration purposes
    });

    const imgs_div=document.createElement('div');
    imgs_div.appendChild(imgcopy)
    imgs_div.appendChild(img)
    div.appendChild(imgs_div)
    div.style.display='flex';
    div.style.alignItems='center';
    div.style.justifyContent='space-between'
    imgcopy.addEventListener('click',function(){
      console.log(rowData)
      copyContent(rowData.client_phone)
    })
    return div;
  }

  var headerMenu = function(){
    var menu = [];
    var columns = this.getColumns();

    for(let column of columns){

        //create checkbox element using font awesome icons
        let icon = document.createElement("i");
        icon.classList.add("fas");
        icon.classList.add(column.isVisible() ? "fa-check-square" : "fa-square");

        //build label
        let label = document.createElement("span");
        let title = document.createElement("span");

        title.textContent = " " + column.getDefinition().title;

        label.appendChild(icon);
        label.appendChild(title);

        //create menu item
        menu.push({
            label:label,
            action:function(e){
                //prevent menu closing
                e.stopPropagation();

                //toggle current column visibility
                column.toggle();

                //change menu item icon
                if(column.isVisible()){
                    icon.classList.remove("fa-square");
                    icon.classList.add("fa-check-square");
                }else{
                    icon.classList.remove("fa-check-square");
                    icon.classList.add("fa-square");
                }
            }
        });
    }

   return menu;
};

  const columns = [
    // { title: 'Number', field: 'number', sorter: 'number', headerFilter: "input" },
    { title: 'ID', field: 'client_id', sorter: 'number', headerFilter: "input",headerMenu },
    { title: 'Name', field: 'name', sorter: 'string', headerFilter: "input" ,headerMenu},
    { title: 'Phone', field: 'phone',formatter:phone1lead, sorter: 'string', headerFilter: "input", headerFilterPlaceholder: "phone" ,headerMenu },
    { title: 'Phone2', field: 'client_phone',formatter:phone2lead, sorter: 'string', headerFilter: "input", headerFilterPlaceholder: "phone",headerMenu },
    { title: 'Address', field: 'client_address', sorter: 'string', headerFilter: "input" ,headerMenu},
    { title: 'Added Date', field: 'added_at', sorter: 'date',sorterParams: { format: 'YYYY-MM-DD' }, headerFilter: "date",headerMenu },
    { title: 'Action Number', field: 'call_number', sorter: 'number', headerFilter: "input" ,headerMenu},
  ];

  const getFollowsData=()=>{
    setPageLoading(true)
    const data_send={
      agent_id:agent_id
    }
    console.log(data_send)
    axios.post('https://camp-coding.tech/new_quad_wall/quad_crm/admin/follow_up.php',data_send)
    .then((res)=>{
      console.log(res,"ewwe")
      if(res.status=='success'){
        setMettings(res?.message?.meetings);
        setCalls(res?.message?.callings);
      }
    })
    .catch((e)=>{
      console.log(e);
    })
    .finally(()=>{
      setPageLoading(false)
    })
  }
  useEffect(()=>{
    getFollowsData()
  },[])
  return (
    <>
      {
        pageLoading?
        (
          [1, 2, 3].map(x => (
            <Col  xs={24} md={24} lg={24} key={x}>
              <Card loading minHeight={200} />
            </Col>
          ))
        )
        :
        (
          <div className='py-6 p-6 container'  style={{marginTop:'78px',overflow:'auto',minWidth:'100%'}}>
              <div className='d-flex align-items-center justify-content-center gap-2 colds_leads'>
              <p
                onClick={()=>{
                  setSelectedType('cold')
                }}
                className={selectedType=='cold'?'active':''}
              >Cold</p>
              <p
                  onClick={()=>{
                    setSelectedType('leads')
                  }}
                  className={selectedType=='leads'?'active':''}
              >Leads</p>
            </div>
            {/* <div className='mb-3 d-flex align-items-center  justify-content-between'>
              <h5>Add New Call</h5>
              <AiOutlinePlus className='text-success cursor-pointer' onClick={()=>{
                setShowAddModal(true)
              }} size={40}/>
            </div> */}


              <ReactTabulator
                data={calls}
                options={
                  options
                }
                // sortOrderReverse={true}
                columns={columns}
                layout={"fitData"}
              />

          </div>
        )
      }
            <Modal title="Request Data" open={showReqModal} onOk={()=>{
        setShowReqModal(false)
      }} onCancel={()=>{
        setShowReqModal(false)
      }}>
                <Card
                  style={{
                    width: '100%',
                  }}
                >
                  <div style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                    <p>bathroom:{calldata?.bathroom}</p>
                    <p>bedrooms:{calldata?.bathroom}</p>
                  </div>
                   <div style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                  <p>compound name:{calldata?.compound_name}</p>
                  <p>delivery date:{calldata?.delivery_date}</p>
                  </div>
                  <div style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                  <p>down payment plan:{calldata?.down_payment_plan}</p>
                  <p>monthly payment plan:{calldata?.monthly_payment_plan}</p>

                  </div>
                  <div style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                  <p>finishing:{calldata?.installment_year}</p>
                  <p>finishing:{calldata?.finishing}</p>

                  </div>
                  <div style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                  <p>max square meter:{calldata?.max_square_meter}</p>
                  <p>min square meter:{calldata?.min_square_meter}</p>
                  </div>

                 <div style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                  <p>property type:{calldata?.property_type}</p>
                  <p>sale type:{calldata?.sale_type}</p>
                  </div>
                  <div style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                  <p>sup location name:{calldata?.sub_location}</p>
                  <p>location name:{calldata?.location_data?.location_name}</p>

                  </div>
                </Card>
      </Modal>
    </>
  )
}

export default FollowUp
