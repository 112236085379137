import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';

const TealSells = () => {
  const navigate=useNavigate()
  const agent_id=JSON.parse(localStorage.getItem("authUser"))?.agent_id;
  const [sells,setSells]=useState([])
  const [pageLoading,setPageLoading]=useState(false)
  const getMySells=()=>{
    setPageLoading(true)
    const data_send={
      agent_id
    }
    axios.post('https://camp-coding.tech/new_quad_wall/quad_crm/admin/my_sells.php',JSON.stringify(data_send))
    .then((res)=>{
      console.log(res)
      if(Array.isArray(res.message)){
        setSells(res.message)
      }
    })
    .catch(e=>console.log(e))
    .finally(()=>{
      setPageLoading(false)
    })
  }
  useEffect(()=>{
    getMySells()
  },[])
  return (
    <div className='conteint  px-6 mx-2' style={{marginTop:'100px'}}>
      <div>Sells</div>
      <table className="table table-nowrap table-hover mb-0">
        <thead>
          <tr>
            <th scope="col">Tracking number</th>
            <th>Today Meetings</th>
            <th scope="col">name</th>
            <th scope="col">phone</th>
            {/* <th scope="col">password</th> */}
            <th scope="col">email</th>
          </tr>
        </thead>
        <tbody>
          {
            sells.map((item,index)=>{
              return(
                <tr>
                <th onClick={()=>{
                  navigate("/SellesFollowUp",{state:{selData:item}})
                }} style={{cursor:'pointer',textDecoration:'underline',color:'blue'}} scope="row">{item.agent_id}</th>
                <th
                  onClick={()=>{
                    navigate('/TodayMeeting',{state:{selData:item}})
                  }}
                  style={{
                    cursor:'pointer'
                  }}
                ><img src={require("../../assets/images/meetings.png")} style={{width:'30px',cursor:'pointer',}} alt="" /></th>
                <td><Link to="#" className="text-dark">{item.name}</Link></td>
                <td><Link to="#" className="text-dark">{item.phone}</Link></td>
                {/* <td>
                  {item.password}
                </td> */}
                <td>
                    <span className="badge badge-soft-primary font-size-12">{item.user_email}</span>
                </td>

            </tr>
              )
            })
          }
        </tbody>
      </table>
    </div>
  )
}

export default TealSells
