import React, { useRef } from 'react'
import { useContext } from 'react'
import { AppContext } from '../../components/context/AppContextProvider'
import axios from 'axios';
import { useEffect } from 'react';
import { useState } from 'react';
import 'react-tabulator/lib/styles.css';
import "tabulator-tables/dist/css/tabulator.min.css"; //import Tabulator stylesheet
import { ReactTabulator } from 'react-tabulator'
import { Card, Col } from 'antd';
import { ThreeDots } from "react-loader-spinner";
import { AiFillDelete, AiOutlineEdit, AiOutlinePlus } from 'react-icons/ai';
import { Link, useNavigate } from 'react-router-dom';
import { Modal, Select } from 'antd'
import { toast } from 'react-toastify';
import { MdOutlineApartment } from 'react-icons/md';
import { BiBuildingHouse } from 'react-icons/bi';
import { useDispatch, useSelector } from 'react-redux';
import { BsHouseSlashFill, BsHouses } from 'react-icons/bs';
import moment from 'moment';
// import Tabulator from 'tabulator-tables';
import Lottie from 'lottie-react';
import groovyWalkAnimation from "../../assets/images/Animation - 1717928166617.json";
// import './sellesAssign.css'
const ColdSheets = () => {
  const [data,setData]=useState([]);
  const navigate=useNavigate()
  const tableRef = useRef(null);
  const [rowData,setRowData]=useState({});
  const [showDelModel,setShowDelModel]=useState(false)
  const authUser=JSON.parse(localStorage.getItem("authUser"));
  const imageFormatter = (cell, formatterParams, onRendered) => {
    // console.log(cell.getValue())
    // console.log(row.getRow())
    const value = cell.getValue();
    const row = cell.getRow();
    const rowData = row.getData();

    // Create the image element
    const img = document.createElement("img");
    img.src = value;
    img.alt = "Profile";
    img.style.width = "30px";
    img.style.height = "30px";
    img.style.cursor = "pointer";
  img.style.display='block';
    img.style.margin='auto'
    // Add click event listener
    img.addEventListener("click", () => {
      console.log(rowData);
      window.open(rowData?.sheet_link,"_blank")
      // window.open(`whatsapp://send?text=!&phone=+2${rowData.client_phone.startsWith("0")?rowData.client_phone:`${rowData.client_phone}`}`,'_blank')
      // alert(JSON.stringify(rowData, null, 2)); // For demonstration purposes
    });

    return img;
  };

  const FileBtn = (cell, formatterParams, onRendered) => {
    const row = cell.getRow();
    const rowData = row.getData();

    // Create the button element
    const button = document.createElement("button");
    button.innerText = "Show File Clients";
    button.style.cursor = "pointer";
    // button?.classList?.add('btn-success')
    button.style.color='white'
    button.style.backgroundColor='green'
    button.style.border='none'
    button.style.borderRadius='4px'
    button.style.padding='4px'

    // Add click event listener
    button.addEventListener("click", () => {
      console.log(rowData);
      navigate("/file_clients",{state:{sheet:rowData}});
      // handelDel(rowData?.client_id)
      // alert(JSON.stringify(rowData, null, 2)); // For demonstration purposes
    });

    return button;
  };

  const DeleteBtn = (cell, formatterParams, onRendered) => {
    const row = cell.getRow();
    const rowData = row.getData();

    // Create the button element
    const button = document.createElement("button");
    button.innerText = "Delete File";
    button.style.cursor = "pointer";
    // button?.classList?.add('btn-success')
    button.style.color='white'
    button.style.backgroundColor='red'
    button.style.border='none'
    button.style.borderRadius='4px'
    button.style.padding='4px'
    // Add click event listener
    button.addEventListener("click", () => {
      setShowDelModel(true)
      setRowData(rowData);
      // handelDel(rowData?.client_id)
      // alert(JSON.stringify(rowData, null, 2)); // For demonstration purposes
    });

    return button;
  };

  const rowNumFormatter = (cell) => {
    return cell.getRow().getPosition(true) + 0; // Get the row position and add 1 to make it 1-based
};

  // const columns = [
  //   // { title: 'Number', field: 'number', sorter: 'number', headerFilter: "input" },
  //   // { title: 'ID', field: 'uplouded_sheets_id', sorter: 'number', headerFilter: "input" },
  //   { title: 'No', formatter: rowNumFormatter, width: 50, hozAlign: 'center' }, // Row number column

  //   { title: 'Title', field: 'title', sorter: 'string', headerFilter: "input" },
  //   { title: 'source', field: 'data_source', sorter: 'string', headerFilter: "input" },
  //   { title: 'Sheet Row Count', field: 'raw_count', sorter: 'string', headerFilter: "input" },
  //   { title: 'Number Of Client In Sheet', field: 'done_inserted', sorter: 'number', headerFilter: "input" },

  //   // { title: 'Type', field: 'type', sorter: 'string', headerFilter: "input" },
  //   { title: 'Uploaded Date', field: 'date', sorter: 'date',sorterParams: { format: 'YYYY-MM-DD' }, headerFilter: "date" },
  //   { title: 'Uploaded By Agent Name', field: 'uplouded_by_agent_name', sorter: 'string' },
  //   { title: 'File', field: 'excelSheet', sorter: 'string' , formatter: imageFormatter},
  //   { title: 'Clients In File', field: 'client_id',formatter:FileBtn},
  //   // { title: 'Action Number', field: 'call_number', sorter: 'number', headerFilter: "input" },
  //   // { title: 'Profile Image', field: 'image', formatter: imageFormatter,cellClick:function(e, cell){

  //     // let rowData=cell.getRow().getData();
  //     //e - the event object
  //     //cell - cell component
  //     // console.log('yesclick')
  //     // window.open(`whatsapp://send?text=!&phone=+2${rowData.client_phone.startsWith("0")?rowData.client_phone:`${rowData.client_phone}`}`,'_blank')
  // // } },
  //   // { title: 'Print', formatter: deleteButtonFormatter, width: 100 }
  // ];
  const [columns, setColumns] = useState([]);

  const updateColumnTitles = (dataLength) => {
    const updatedColumns = [
      // { title: 'Number', field: 'number', sorter: 'number', headerFilter: "input" },
      // { title: 'ID', field: 'uplouded_sheets_id', sorter: 'number', headerFilter: "input" },
      { title: 'No', formatter: rowNumFormatter, width: 50, hozAlign: 'center' }, // Row number column

      { title:`Title (${dataLength})`, field: 'title', sorter: 'string', headerFilter: "input" },
      { title: 'source', field: 'data_source', sorter: 'string', headerFilter: "input" },
      { title: 'Sheet Row Count', field: 'raw_count', sorter: 'string', headerFilter: "input" },
      { title: 'Number Of Client In Sheet', field: 'done_inserted', sorter: 'number', headerFilter: "input" },

      // { title: 'Type', field: 'type', sorter: 'string', headerFilter: "input" },
      { title: 'Uploaded Date', field: 'date', sorter: 'date',sorterParams: { format: 'YYYY-MM-DD' }, headerFilter: "date" },
      { title: 'Uploaded By Agent Name', field: 'uplouded_by_agent_name', sorter: 'string' },
      { title: 'File', field: 'excelSheet', sorter: 'string' , formatter: imageFormatter},
      { title: 'Clients In File', field: 'client_id',formatter:FileBtn},
      { title: 'Delete File', field: 'client_id',formatter:DeleteBtn},

      // { title: 'Action Number', field: 'call_number', sorter: 'number', headerFilter: "input" },
      // { title: 'Profile Image', field: 'image', formatter: imageFormatter,cellClick:function(e, cell){

        // let rowData=cell.getRow().getData();
        //e - the event object
        //cell - cell component
        // console.log('yesclick')
        // window.open(`whatsapp://send?text=!&phone=+2${rowData.client_phone.startsWith("0")?rowData.client_phone:`${rowData.client_phone}`}`,'_blank')
    // } },
      // { title: 'Print', formatter: deleteButtonFormatter, width: 100 }
    ];
    setColumns(updatedColumns);
};


useEffect(() => {
  updateColumnTitles(data.length);
}, [data]);

useEffect(() => {
  const table = tableRef.current?.table;

  if (table) {
      table.on("dataFiltered", (filters, rows) => {
          updateColumnTitles(rows.length);
      });

      // Initial update of column titles with the full data length
      updateColumnTitles(data.length);
  }
}, [columns]);


  const [showAddModel,setShowAddModel]=useState(false)
  const [upLoading,setUpLoading]=useState(false)
  const [title,setTitle]=useState('');

  const rowMoved = (row) => {
    console.log('Row moved:', row.getData());
  };
  const options = {
    initialSort: [
      { column: 'age', dir: 'asc' }, // sort by age in ascending order
    ],
    movableRows: true,movableColumns: true, // enable row dragging
    rowMoved: rowMoved, // optional, handle row move events
  };

  const [file,setFile]=useState(null);
  const [sourceId,setSourceId]=useState('');
  const [sources,setSources]=useState([]);
  const [pageLoading,setPageLoading]=useState(false)
  const getSheets=()=>{
    setPageLoading(true)
    const data_send={
      type:'cold',
      agent_id:authUser?.agent_id,
      user_type:authUser?.user_type,
    }
    axios.post("https://camp-coding.tech/new_quad_wall/quad_crm/admin/select_sheets.php",JSON.stringify(data_send))
    .then((res)=>{
      console.log(res,"this is response")
      if(Array.isArray(res.message)){
        setData(res.message.map((item)=>{
          return {...item,excelSheet:require("../../assets/images/excel.png")}
        }))
      }
    })
    .catch(e=>console.log(e))
    .finally(()=>{
      setPageLoading(false)
    })
  }
  const getSources=()=>{
    axios.post("https://camp-coding.tech/new_quad_wall/quad_crm/admin/select_sources.php",{
      "type":"cold"
    })
    .then((res)=>{
      if(Array.isArray(res.message)){
        setSources(res.message)
        if(res.message.length>0){
          setSourceId(res.message[0].source_id)
        }
      }
    })
    .catch(e=>console.log(e))
  }
  const handleUpload=()=>{
    if(file==null){
      toast.warn('Select File')
      return;
    }
    setUpLoading(true)
    const formData=new FormData();
    formData.append('file_attachment',file);
    formData.append('source_id',sourceId);
    formData.append('title',title);
    formData.append('type',"cold");
    formData.append('added_by_agent_id',authUser?.agent_id);
    console.log(formData)
    // set

    // return
    axios.post('https://camp-coding.tech/new_quad_wall/quad_crm/admin/uploud_clients_excel.php',formData)
    .then((res)=>{
      console.log(res)
      toast.success(`Success To Upload ${res?.add_counter} Client From This File`)
      toast.warn(`There Are ${res?.duplication_counter} Duplicated Client Or Existed Before In This File`)
      getSheets()
      setShowAddModel(false)
      // if(res.status=='success'){
      //   toast.success(res.message)
      // }
      // else if(res.status=='error'){
      //   toast.error(res.message)
      // }
      // else {
      //   toast.error("Something Went Error");
      // }
      // setTitle('');
      // setFile(null)
    })
    .catch(e=>console.log(e))
    .finally(()=>{
      setUpLoading(false)
    })
  }

  const handleDelete=()=>{
    setUpLoading(true)
    const data_send={
      uplouded_sheets_id:rowData?.uplouded_sheets_id,
    }
    axios.post('https://camp-coding.tech/new_quad_wall/quad_crm/admin/delete_sheet.php',JSON.stringify(data_send))
    .then((res)=>{
      if(res.status=='success'){
        toast.success(res.message)
        setShowDelModel(false)
        getSheets()
      }
      else if(res.status=='error'){
        toast.error(res.message)
      }
      else {
        toast.error('Something Went Error')
      }
    })
    .catch(e=>console.log(e))
    .finally(()=>{
      setUpLoading(false)
    })
    console.log(data_send);
  }
  useEffect(()=>{
    getSheets()
    getSources()
  },[])
  return (
    <>
      {
        pageLoading?(
          <div style={{
            minHeight:'100vh',
            display:'flex',
            alignItems:'center',
            justifyContent:'center'
          }}>
            <Lottie style={{ width:'40%' }}  animationData={groovyWalkAnimation} loop={true} />
            {/* <img src={require("../../assets/images/Animation - 1717926811158(1).gif")} alt="" /> */}
          </div>
          )
        :(
          <div className='container py-6' style={{marginTop:'78px',overflow:'auto',minHeight:'100vh',minWidth:'100%'}}>
            <div style={{margin:'40px 0px'}} className='d-flex align-items-center justify-content-between '>
              <h5>Uploaded Cold Sheets</h5>
              <img style={{width:'30px',cursor:'pointer'}} onClick={()=>{
                setShowAddModel(true)
              }} src={require("../../assets/images/upload_file.png")} alt="" />
            </div>
            <ReactTabulator
              ref={tableRef}
              data={data}
              options={
                options
              }
              // sortOrderReverse={true}
              columns={columns}
              layout={"fitData"}
              />


      <Modal onOk={handleUpload} title="Add Customer" open={showAddModel} onCancel={()=>{
        setShowAddModel(false)
      }} centered>
        <form onSubmit={(e)=>{e.preventDefault()}} action="">
          <div style={{width:'100%'}} className='form_control'>
            <label htmlFor="">Title</label>
            <input value={title} onChange={(e)=>{
              setTitle(e.target.value)
            }} type="text" />
          </div>
          <div style={{width:'100%'}} className='form_control'>
            <label htmlFor="">Uploaded File</label>
            <input accept='.xlsx, .xls' onChange={(e)=>{
              setFile(e.target.files[0])
            }} type="file" />
          </div>
          <div style={{width:'100%'}} className='form_control'>
            <label htmlFor="">The Source</label>
            <select
              style={{width:'100%'}}
              onChange={(e)=>{
                setSourceId(e.target.value)
              }}
              value={sourceId}
            >
              {
                sources&&sources.map((item,index)=>{
                  return (
                    <option value={item.source_id}>{item.name}</option>
                  )
                })
              }
            </select>
          </div>
        </form>
        {
          upLoading&&<div>
            <h5>...loading</h5>
          </div>
        }
      </Modal>


      <Modal onOk={handleDelete} title="Add Customer" open={showDelModel} onCancel={()=>{
        setShowDelModel(false)
      }} centered>
        <div>
          <h5>Do You Want To Delete This File</h5>
          <p className='my-2'>You Should Know That the clients in this file will deleted too</p>

        </div>
        {
          upLoading&&<div>
            <h5>...loading</h5>
          </div>
        }
      </Modal>


          </div>
        )
      }
    </>
  )
}

export default ColdSheets
