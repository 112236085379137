import React from 'react'
import { Button, Card, Col, Modal, Spin } from 'antd';
import { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useEffect } from 'react';


function Location() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [location_name,setlocation_name]=useState("");
  const [alllocations,setalllocations]=useState([]);
  const [pageloading,setpageloading]=useState(true);
  const [addloading,setaddloading]=useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
    const data_send={
      location_name:location_name
    }
    setaddloading(true);
    axios.post("https://camp-coding.tech/new_quad_wall/quad_crm/admin/insert_location.php",JSON.stringify(data_send))
    .then((res)=>{
    console.log(res)

      if(res.status==="success"){
        toast.success(res.message);
        getlocations();
      }
      else  if(res.status==="error"){
        toast.error(res.data.message);
      }
      else {
        toast.error("something is error try again");
      }
    }).finally(()=>{
      setaddloading(false);
    })

  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const [ newLocation, setNewLocation ] = useState([]);

  const getlocations=async()=>{
    let Location_data=await axios.get(`https://camp-coding.tech/quad_crm/user/select_locations.php`).catch(err=>{
      console.log(err);
    }).catch((err)=>{
      console.log(err);
    }).finally(()=>{
      setpageloading(false);
    })
    console.log(Location_data)
    setalllocations(Location_data.message);


  }
  useEffect(()=>{
    getlocations();
  },[])
  return <>
        {
          pageloading?(
            [1, 2, 3].map(x => (
              <Col xs={24} md={12} lg={8} key={x}>
                <Card loading minHeight={200} />
              </Col>
            ))
          ):(
            <>
            <div className="container py-5">
            <div className="row py-5 justify-content-between">
              <div className='right-text col-6'>
                <h4>Add Location</h4>
              </div>
              <div className='left-text col-6 text-end'>
                {
                  addloading?(
                    <Spin/>
                  ):(
                    <button className='btn btn-outline-info' type="button"
                    onClick={showModal}>
                      Add Location
                    </button>
                  )
                }
              </div>

        <Modal centered title="Add Modal" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
            <form>
              <input type='text' className='w-100 form-control'  name='Location' value={newLocation} onChange={e=>{
                setNewLocation(e.target.value)
                setlocation_name(e.target.value);
              }}  placeholder='Add Location'/>
            </form>
        </Modal>
            </div>
            <div className='row'>
                <div className='col-md-12'>
                <table class="table table-hover">
    <thead>
      <tr>
        <th scope="col">id</th>
        <th scope="col">Location Name</th>
        {/* <th scope="col">Edit</th> */}
      </tr>
    </thead>
    <tbody>
      {
        alllocations.map((item,index)=>{
          return(
            <tr>
              <td>{item.location_id}</td>
              <td>{item.location_name}</td>
            </tr>
          )
        })
      }

    </tbody>
  </table>

                </div>

            </div>
          </div>
            </>
          )
        }
  </>
}

export default Location
