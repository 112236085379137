import React from 'react'
import 'react-tabulator/lib/styles.css';
import "tabulator-tables/dist/css/tabulator.min.css"; //import Tabulator stylesheet
import { ReactTabulator } from 'react-tabulator'
const Test = () => {
  const columns = [
    { title: 'ID', field: 'id', sorter: 'number', headerFilter: "input" },
    { title: 'Name', field: 'name', sorter: 'string', headerFilter: "input" },
    { title: 'Age', field: 'age', sorter: 'number', headerFilter: "number", headerFilterPlaceholder: "Age" },
  ];
  const data = [
    { id: 1, name: 'John Doe', age: 28 },
    { id: 2, name: 'Jane Smith', age: 34 },
    { id: 3, name: 'Sam Green', age: 22 },
  ];
  const rowMoved = (row) => {
    console.log('Row moved:', row.getData());
  };
  const options = {
    initialSort: [
      { column: 'age', dir: 'asc' }, // sort by age in ascending order
    ],
    movableRows: true,movableColumns: true, // enable row dragging
    rowMoved: rowMoved, // optional, handle row move events
  };
  return (
    <div style={{minHeight:'80vh',marginTop:'100px'}}>
      <ReactTabulator
        data={data}
        options={
          options
        }
        // sortOrderReverse={true}
        columns={columns}
        layout={"fitData"}
        />
    </div>
  )
}

export default Test
