import React, { useState } from 'react'
import './employeess.css'
import user1 from '../../assets/images/users/avatar-1.jpg'
import user2 from '../../assets/images/users/avatar-2.jpg'
import user3 from '../../assets/images/users/avatar-3.jpg'
import user4 from '../../assets/images/users/avatar-4.jpg'
import user5 from '../../assets/images/users/avatar-5.jpg'
import user6 from '../../assets/images/users/avatar-6.jpg'
import user7 from '../../assets/images/users/avatar-7.jpg'
import user8 from '../../assets/images/users/avatar-8.jpg'
import user9 from '../../assets/images/users/avatar-9.jpg'
import user10 from '../../assets/images/users/avatar-10.jpg'
import {  Card, Col, Modal, Spin } from 'antd';
import { Link } from "react-router-dom";
import {AiFillDelete, AiOutlineEdit} from 'react-icons/ai'
import {DropdownItem, DropdownMenu, DropdownToggle, Nav, NavItem, NavLink, Row, TabContent, TabPane, UncontrolledDropdown, CardHeader, CardBody } from "reactstrap";

import classnames from "classnames";
import axios from 'axios'
import { useEffect } from 'react'
import { toast } from 'react-toastify'




function Employees() {
  const [showEdit,setShowEdit]=useState(false)
  const [employeedata,setemployeedata]=useState({
    name:'sells',
    phone:'',
    password:'',
    user_email:'',
    type:'sells',
  });
  const [tealId,setTealId]=useState('');
  const [activeSales,setActiveSales]=useState(true);
  const [addloading,setaddloading]=useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [employeessdata,setemployeessdata]=useState([]);
  const [teals,setTeals]=useState([]);
  const [rowData,setRowData]=useState({});
  const [pageloading,setpageloading]=useState(true);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    if(employeedata.name==""){
      toast.warn("enter employee name");
      return;
    }
    if(employeedata.phone==""||isNaN(employeedata.phone)){
      toast.warn("enter employee phone(valied)");
      return;
    }
    if(employeedata.password==""){
      toast.warn("enter employee password");
      return;
    }
    if(employeedata.user_email==""){
      toast.warn("enter employee email");
      return;
    }
    setIsModalOpen(false);
    const data_Send={
      ...employeedata,
    }
    if(employeedata.type=='sells'){
      data_Send['teal_id']=tealId;
    }
    console.log(data_Send);
    axios.post("https://camp-coding.tech/new_quad_wall/quad_crm/admin/insert_ageint.php",data_Send)
    .then((res)=>{
      console.log(res)
      if(res.status==="success"){
        geteployees()
        toast.success(res.message);
      }
      else if(res.status==="error"){
        toast.error(res.message);
      }
      else {
        toast.error("something is error try again");
      }
    }).finally(()=>{
      setaddloading(false);
    })
  };
  const handleEdit=()=>{
    const data_send={
      ...rowData,
      type:rowData.user_type
    }
    console.log(data_send)
    axios.post("https://camp-coding.tech/new_quad_wall/quad_crm/admin/edit_employee.php",JSON.stringify(data_send))
    .then((res)=>{
      if(res.status=='error'){
        toast.error(res.message)
      }
      else if(res.status=='success'){
        setShowEdit(false)
        toast.success(res.message)
        geteployees();
      }
      else {
        toast.error('Something Went Error');
      }
    }).catch(e=>console.log(e))
  }
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const geteployees=()=>{
    axios.get("https://camp-coding.tech/new_quad_wall/quad_crm/admin/select_ageint.php")
    .then((res)=>{
      //console.log(res)
      console.log(res);
      setTeals(res.message.filter(item=>item.user_type=='teal_leader'))
      let teals=res.message.filter(item=>item.user_type=='teal_leader');
      if(teals>0){
        setTealId(teals[0].agent_id)
      }
      setemployeessdata(res.message);
      console.log(res.message)
    }).finally(()=>{
      setpageloading(false);
    })
  }
  const handleDeleteEmployee=(id)=>{
    const data_send={
      agent_id:id*1,
    }
    console.log(data_send)
    axios.post(`https://camp-coding.tech/new_quad_wall/quad_crm/admin/delete_employee.php`,JSON.stringify(data_send))
    .then((res)=>{
      console.log(res)
      if(res.status=='success'){
        toast.success(res.message)
        geteployees()
      }
      else if(res.status=='error'){
        toast.error(res.message);
      }
      else {
        toast.error("Something Went Error");
      }
    })
    .catch(e=>console.log(e))
  }
  useEffect(()=>{
    geteployees();
  },[])
  return <>
  {
    pageloading?(
      [1, 2, 3].map(x => (
        <Col xs={24} md={12} lg={8} key={x}>
          <Card loading minHeight={200} />
        </Col>
      ))
    ):(
      <div style={{ overflow: 'auto', }} className="container-fluid py-5">
      <div className="page-content py-5">
       <div className="row">
         <div className="col-lg-6">
           <h2>Employees</h2>
         </div>
         <div className="col-lg-6 text-end">

        <Modal title="Add employee" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} centered>
             <form>
               <input onChange={(e)=>{
                 setemployeedata({...employeedata,name:e.target.value});
               }} type="text" placeholder='enter employee name'/>
               <input onChange={(e)=>{
                 setemployeedata({...employeedata,phone:e.target.value});
               }} type="text" placeholder='enter employee phone'/>
               <input onChange={(e)=>{
                 setemployeedata({...employeedata,password:e.target.value});
               }} type="text" placeholder='enter employee password'/>
               <input onChange={(e)=>{
                 setemployeedata({...employeedata,user_email:e.target.value});
               }} type="text" placeholder='enter employee user name'/>
               <label htmlFor="">Employee Type</label>
               <select onChange={(e)=>{
                if(e.target.value=='sells'){
                  setActiveSales(true)
                }
                else {
                  setActiveSales(false)
                }
                setemployeedata({...employeedata,type:e.target.value})
               }} value={employeedata.type} name="" id="">
                <option value="sells">Sales</option>
                <option value="hr">HR</option>
                <option value="operation">Operation</option>
                <option value="team_leader">Team Leader</option>
                <option value="marketing">Marketing</option>
               </select>
               {
                activeSales&&(
                  <div style={{width:'100%'}}>
                    <label htmlFor="">Team Leader</label>
                    <select  style={{ width:'100%' }} onChange={(e)=>{
                      setTealId(e.target.value)
                      }} value={tealId} name="" id="">
                      {
                        teals.map((item,index)=>{
                          return (
                            <option value={item.agent_id}>{item.name}</option>
                          )
                        })
                      }
                    </select>
                  </div>
                )
               }
             </form>
        </Modal>

        <Modal title="Edit employee" open={showEdit} onOk={handleEdit} onCancel={()=>{
          setShowEdit(false)
        }} centered>
             <form>
               <input value={rowData.name} onChange={(e)=>{
                 setRowData({...rowData,name:e.target.value});
               }} type="text" placeholder='enter employee name'/>
               <input value={rowData.phone} onChange={(e)=>{
                 setRowData({...rowData,phone:e.target.value});
               }} type="text" placeholder='enter employee phone'/>
               <input value={rowData.password} onChange={(e)=>{
                 setRowData({...rowData,password:e.target.value});
               }} type="text" placeholder='enter employee password'/>
               <input value={rowData.user_email} onChange={(e)=>{
                 setRowData({...rowData,user_email:e.target.value});
               }} type="text" placeholder='enter employee user name'/>
               <label htmlFor="">Employee Type</label>
               <select  onChange={(e)=>{
                if(e.target.value=='sells'){
                  setActiveSales(true)
                }
                else {
                  setActiveSales(false)
                }
                setRowData({...rowData,user_type:e.target.value})
               }} value={rowData.user_type} name="" id="">
                <option value="sells">Sales</option>
                <option value="hr">HR</option>
                <option value="operation">Operation</option>
                <option value="team_leader">Team Leader</option>
                <option value="marketing">Marketing</option>
               </select>
               {console.log(rowData)}
               {
                rowData.user_type=='sells'&&(
                  <div style={{width:'100%'}}>
                    <label htmlFor="">Team Leader</label>
                    <select  style={{ width:'100%' }} onChange={(e)=>{
                      setRowData(e.target.value)
                      }} value={rowData.teal_id} name="" id="">
                      {
                        teals.map((item,index)=>{
                          return (
                            <option value={item.agent_id}>{item.name}</option>
                          )
                        })
                      }
                    </select>
                  </div>
                )
               }
             </form>
         </Modal>

         {
           addloading?(
             <Spin/>
           ):(
             <button className='btn btn-outline-info' type="button"
             onClick={showModal}>
               Add Employe
             </button>
           )
         }
         </div>
        <table className="table table-nowrap table-hover mb-0">
           <thead>
               <tr>
                   <th scope="col">id</th>
                   <th scope="col">name</th>
                   <th scope="col">phone</th>
                   <th scope="col">password</th>
                   <th scope="col">email</th>
                   <th scope="col">type</th>
 {/*                   <th scope="col" style={{ width: "120px" }}>Action</th> */}
               </tr>
           </thead>
           <tbody>
             {
               employeessdata.map((item,index)=>{
                 return(
                   <tr>
                   <th scope="row">{item.agent_id}</th>
                   <td><Link to="#" className="text-dark">{item.name}</Link></td>
                   <td><Link to="#" className="text-dark">{item.phone}</Link></td>
                   <td>
                     {item.password}
                   </td>
                   <td>
                       <span className="badge badge-soft-primary font-size-12">{item.user_email}</span>
                   </td>
                   <td>
                       <span style={{textTransform:'capitalize'}} className="badge badge-soft-primary font-size-12">{item.user_type=='sells'?'sales':item.user_type}</span>
                   </td>
                   <td style={{display:'flex',alignItems:'center',flexWrap:'wrap'}}>
                     <AiFillDelete onClick={()=>{
                      handleDeleteEmployee(item.agent_id);
                     }} style={{color:'red',fontSize:'30px',cursor:'pointer'}}/>
                     <AiOutlineEdit onClick={()=>{
                      setShowEdit(true);
                      setRowData(item)
                     }} style={{color:'green',fontSize:'30px',cursor:'pointer'}}/>
                   </td>
               </tr>
                 )
               })
            }
          </tbody>
        </table>


       </div>
      </div>
   </div>
    )
  }
  </>
}

export default Employees
