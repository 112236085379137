import { FETCH_DATA_REQUEST, FETCH_DATA_SUCCESS, FETCH_DATA_FAILURE } from './actions';

const initialState = {
  loading: false,
  data: null,
  error: null,
};

const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DATA_REQUEST:
      console.log('Fetch request action:', action);
      return { ...state, loading: true, error: null };
    case FETCH_DATA_SUCCESS:
      console.log('Fetch success action:', action);
      return { ...state, loading: false, data: action.payload, error: null };
    case FETCH_DATA_FAILURE:
      console.log('Fetch failure action:', action);
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default dataReducer;
