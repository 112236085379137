import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer";

// Calendar
import Calendar from "./calendar/reducer";

// Ecommerce
import Ecommerce from "./ecommerce/reducer";
import SiteDataSlice from '../store2/SiteData'
// Authentication
import forgetPassword from "./auth/forgetpwd/reducer";
import login from "./auth/login/reducer";
import profile from "./auth/profile/reducer";
import account from "./auth/register/reducer";
import { rootReducers } from "../store2/combineReducers";
import dataReducer from "./color/reducer";

const rootReducer = combineReducers({
  // public
  Layout,
  Calendar,
  forgetPassword,
  login,
  profile,
  account,
  dataReducer,
  Ecommerce,
  // fetchSiteData,
  saga:rootReducers
})

export default rootReducer;
