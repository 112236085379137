import React, { useCallback, useEffect, useMemo, useRef } from 'react'

import { useState } from 'react';
import 'react-tabulator/lib/styles.css';
import "tabulator-tables/dist/css/tabulator.min.css"; //import Tabulator stylesheet
import { ReactTabulator } from 'react-tabulator'
import './SalesLeads.css'
import * as XLSX from 'xlsx';

// import Tabulator from 'tabulator-tables';
import Lottie from 'lottie-react';
import groovyWalkAnimation from "../../assets/images/Animation - 1717928166617.json";
import axios from 'axios';
import { toast } from 'react-toastify';
import { ThreeCircles, ThreeDots } from 'react-loader-spinner';
import { Modal } from 'antd';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import { AgGridReact } from 'ag-grid-react';
// import './sellesAssign.css'
const SalesLeads = () => {
  var filterParams = {
    comparator: (filterLocalDateAtMidnight, cellValue) => {
      var dateAsString = cellValue;
      if (dateAsString == null) return -1;
      var dateParts = dateAsString.split("/");
      var cellDate = new Date(
        Number(dateParts[2]),
        Number(dateParts[1]) ,
        Number(dateParts[0]),
      );
      if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
        return 0;
      }
      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      }
      if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      }
      return 0;
    },
    minValidYear: 2024,
    maxValidYear: 3000,
    inRangeFloatingFilterDateFormat: "Do MMM YYYY",
  };
  const authUser = JSON.parse(localStorage.getItem("authUser"));
  const[selectAll,setSelectAll]=useState(false)
  const gridStyle = useMemo(() => ({ height: "100vh" }), []);
  const gridRef = useRef();

  const [changeLoading,setChangeLoading]=useState(false)
  const [showNewStatusModal,setShowNewStatusModal]=useState(false)
  const [rowData,setRowData]=useState({})
  const [showAddModal, setShowAddModal] = useState(false);
  const [filteredRowCount,setFilteredRowCount]=useState([])
  const [disConf,setDisConf]=useState(true)

  const [newStatus,setNewStatus]=useState('interested')
  const [showCalls, setShowCalls] = useState(false);
  const [calls, setCalls] = useState([]);

  const [newCall, setNewCall] = useState({
    feed_back: "",
    duration: "",
  });
  const [columns, setColumns] = useState([]);
  const [selectedRows,setSelectedRows]=useState([]);


  const [feedBack,setFeedBack]=useState('');
  const [showHistoyModel,setShowHistoyModel]=useState(false);
  const [callLoading, setCallLoading] = useState(false);

  const [callBackData,setCallBackData]=useState({
    time:'',
    date:'',
  })
  const [statusOptions,setStatusOptions]=useState([
    {
      value:"interested",
      label:"interested",
    },
    {
      value:"not_interested",
      label:"not interested",
    },
    {
      value:"low_budget",
      label:"low budget",
    },
    {
      value:"call_back",
      label:"call back",
    },
    {
      value:"follow_meeting",
      label:"Follow Meeting",
    },
    {
      value:"done_deal",
      label:"Done Deal",
    },
    {
      label:"Lost Deal",
      value:"lost_deal",
    },
    // {
    //   value:"delay",
    //   label:"Delay",
    // },
  ])

  const handleUpdateComment = () => {
    // setAddLoading(true)
    const data_send = {
      ...oldData,
    };
    console.log(rowData,"wkwek",ClientId)
    // return ;
    axios
      .post(
        "https://camp-coding.tech/new_quad_wall/quad_crm/admin/update_comment.php",
        JSON.stringify(data_send)
      )
      .then((res) => {
        console.log(res);
        if (res.status == "success") {
          toast.success(res.message);
          setOldComment(null);
          handleGetComments(ClientId?.client_id);
        } else if (res.status == "error") {
          toast.error(res.message);
        } else {
          toast.error("Something Went Error");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setAddLoading(false);
      });
  };
  const rowNumFormatter = (cell) => {
    return cell.getRow().getPosition(true) +0; // Get the row position and add 1 to make it 1-based
};

  const copyContent = async (phone) => {
    try {
      await navigator.clipboard.writeText(phone);
      console.log('Content copied to clipboard');
      toast.success('Content copied to clipboard')
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  }
  const phone1lead= (cell, formatterParams, onRendered)=>{
    const value = cell.getValue();
    const row = cell.getRow();
    const rowData = row.getData();
    const div=document.createElement('div');

    const img = document.createElement("img");
    img.src = rowData?.image;
    img.alt = "Profile";
    img.style.width = "30px";
    img.style.height = "30px";
    img.style.cursor = "pointer";

    const phone=document.createElement('span')
    phone.innerText=rowData.client_phone;
    div.appendChild(phone);
    // div.appendChild(img);

    const imgcopy=document.createElement("img");

    imgcopy.src=rowData.copyimg
    imgcopy.alt = "copy";
    imgcopy.style.width = "30px";
    imgcopy.style.height = "30px";
    imgcopy.style.cursor = "pointer";

    img.addEventListener("click", () => {
      console.log(rowData);
      window.open(`whatsapp://send?text=!&phone=+2${rowData.client_phone.startsWith("0")?rowData.client_phone:`0${rowData.client_phone}`}`,'_blank')
      // alert(JSON.stringify(rowData, null, 2)); // For demonstration purposes
    });
    imgcopy.addEventListener("click", () => {
      console.log(rowData,"copy");
      // window.open(`whatsapp://send?text=!&phone=+2${rowData.client_phone.startsWith("0")?rowData.client_phone:`0${rowData.client_phone}`}`,'_blank')
      // alert(JSON.stringify(rowData, null, 2)); // For demonstration purposes
    });

    const imgs_div=document.createElement('div');
    if(rowData.client_phone!=null){
      imgs_div.appendChild(imgcopy)
      imgs_div.appendChild(img)

    }
    // imgs_div.appendChild(imgcopy);
    // imgs_div.appendChild(img);
    div.appendChild(imgs_div)
    div.style.display='flex';
    div.style.alignItems='center';
    div.style.justifyContent='space-between'
    imgcopy.addEventListener('click',function(){
      // console.log(rowData)
      copyContent(rowData.client_phone)
    })
    return div;
  }

  const phone2lead= (cell, formatterParams, onRendered)=>{
    const value = cell.getValue();
    const row = cell.getRow();
    const rowData = row.getData();
    const div=document.createElement('div');

    const img = document.createElement("img");
    img.src = rowData?.image;
    img.alt = "Profile";
    img.style.width = "30px";
    img.style.height = "30px";
    img.style.cursor = "pointer";

    const phone=document.createElement('span')
    phone.innerText=rowData.client_phone2;
    console.log(rowData)
    div.appendChild(phone);
    // div.appendChild(img);

    const imgcopy=document.createElement("img");

    imgcopy.src=rowData.copyimg
    imgcopy.alt = "copy";
    imgcopy.style.width = "30px";
    imgcopy.style.height = "30px";
    imgcopy.style.cursor = "pointer";

    img.addEventListener("click", () => {
      console.log(rowData);
      window.open(`whatsapp://send?text=!&phone=+2${rowData.client_phone.startsWith("0")?rowData.client_phone:`0${rowData.client_phone}`}`,'_blank')
      // alert(JSON.stringify(rowData, null, 2)); // For demonstration purposes
    });
    imgcopy.addEventListener("click", () => {
      console.log(rowData,"copy");
      // window.open(`whatsapp://send?text=!&phone=+2${rowData.client_phone.startsWith("0")?rowData.client_phone:`0${rowData.client_phone}`}`,'_blank')
      // alert(JSON.stringify(rowData, null, 2)); // For demonstration purposes
    });

    const imgs_div=document.createElement('div');
    if(rowData.client_phone2!=null){
      imgs_div.appendChild(imgcopy)
      imgs_div.appendChild(img)

    }
    div.appendChild(imgs_div)
    div.style.display='flex';
    div.style.alignItems='center';
    div.style.justifyContent='space-between'
    imgcopy.addEventListener('click',function(){
      console.log(rowData)
      copyContent(rowData.client_phone2)
    })
    return div;
  }

  const agentField= (cell, formatterParams, onRendered)=>{
    const value = cell.getValue();
    const row = cell.getRow();
    const rowData = row.getData();
    const div=document.createElement('div');
    div.innerText=rowData?.agent?.name;
    return div;
  }

  const agentEmailField= (cell, formatterParams, onRendered)=>{
    const value = cell.getValue();
    const row = cell.getRow();
    const rowData = row.getData();
    const div=document.createElement('div');
    div.innerText=rowData?.agent?.user_email;
    return div;
  }

  const agentEmailPhone= (cell, formatterParams, onRendered)=>{
    const value = cell.getValue();
    const row = cell.getRow();
    const rowData = row.getData();
    const div=document.createElement('div');
    div.innerText=rowData?.agent?.phone;
    return div;
  }


  var headerMenu = function(){
    var menu = [];
    var columns = this.getColumns();

    for(let column of columns){

        //create checkbox element using font awesome icons
        let icon = document.createElement("i");
        icon.classList.add("fas");
        icon.classList.add(column.isVisible() ? "fa-check-square" : "fa-square");

        //build label
        let label = document.createElement("span");
        let title = document.createElement("span");

        title.textContent = " " + column.getDefinition().title;

        label.appendChild(icon);
        label.appendChild(title);

        //create menu item
        menu.push({
            label:label,
            action:function(e){
                //prevent menu closing
                e.stopPropagation();

                //toggle current column visibility
                column.toggle();

                //change menu item icon
                if(column.isVisible()){
                    icon.classList.remove("fa-square");
                    icon.classList.add("fa-check-square");
                }else{
                    icon.classList.remove("fa-check-square");
                    icon.classList.add("fa-square");
                }
            }
        });
    }

   return menu;
};


  const handleChangeStatus=(rowData)=>{
    console.log(rowData)
    if(changeLoading){
      toast.warn('Something in Action Now')
      return
    }
    setChangeLoading(true)
    const data_send={
      client_id:rowData.client_id,
      agent_id,
      status:'no_answer',
      status_label:'No Answer',
    }
    console.log(data_send)
    axios.post("https://camp-coding.tech/new_quad_wall/quad_crm/admin/change_status.php",JSON.stringify(data_send))
    .then((res)=>{
    console.log(res)
      if(res.status=='success'){
        toast.success(res.message)
        setFeedBack('')
        getMyAssignedCleitns()
      }
      else if(res.status=='error'){
        toast.error(res.message)
      }
      else{
        toast.error("Something Went Error")
      }
    })
    .catch(e=>console.log(e))
    .finally(()=>{
      setChangeLoading(false)
    })
  }


  const handleAddCall = () => {
    // setAddLoading(true)
    const data_send = {
      ...newCall,
      client_id: rowData?.client_id||ClientId.client_id,
      agent_id: authUser?.agent_id,
    };
    console.log(data_send);
    // return ;
    axios
      .post(
        "https://camp-coding.tech/new_quad_wall/quad_crm/admin/add_new_call.php",
        JSON.stringify(data_send)
      )
      .then((res) => {
        console.log(res);
        if (res.status == "success") {
          toast.success(res.message);
          setNewCall({ duration: "", feed_back: "" });
          handleGetCalls(rowData?.client_id);
          setShowAddModal(false);
        } else if (res.status == "error") {
          toast.error(res.message);
        } else {
          toast.error("Something Went Error");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setAddLoading(false);
      });
  };
  const handleOk=()=>{
    console.log(rowData)
    setLoading(true)
    if(changeLoading){
      toast.warn('Something in Action Now')
      return
    }
    setChangeLoading(true)
    const data_send={
      client_id:rowData.client_id,
      agent_id,
      status:newStatus,
      feedBack,
      ...callBackData,

      status_label:statusOptions.filter(it=>it.value==newStatus)[0].label,
    }
    axios.post("https://camp-coding.tech/new_quad_wall/quad_crm/admin/change_status.php",JSON.stringify(data_send))
    .then((res)=>{
    console.log(res)
      if(res.status=='success'){
        setFeedBack('')
        toast.success(res.message)
        getMyAssignedCleitns()
        setShowNewStatusModal(false)
      }
      else if(res.status=='error'){
        toast.error(res.message)
      }
      else{
        toast.error("Something Went Error")
      }
    })
    .catch(e=>console.log(e))
    .finally(()=>{
      setChangeLoading(false)
    setLoading(false)
    })
  }

  const NotAnswerBtn = (cell, formatterParams, onRendered) => {
    const row = cell.getRow();
    const rowData = row.getData();

    // Create the button element
    const button = document.createElement("button");
    button.innerText = "No Answer";
    button.style.cursor = "pointer";
    button?.classList?.add('btn-danger')
    button.style.color='white'
    button.style.backgroundColor='red'
    button.style.border='none'
    button.style.borderRadius='4px'
    button.style.padding='4px'
    // Add click event listener
    button.addEventListener("click", () => {
      // console.log(rowData);
      handleChangeStatus(rowData)
    });

    return button;
  };


    const ActionBtn = (cell, formatterParams, onRendered) => {
      const row = cell.getRow();
      const rowData = row.getData();

      // Create the button element
      const button = document.createElement("button");
      button.innerText = "action";
      button.style.cursor = "pointer";
      button?.classList?.add('btn-success')
      button.style.color='white'
      button.style.backgroundColor='#4eb052'
      button.style.border='none'
      button.style.borderRadius='4px'
      button.style.padding='4px'
      // Add click event listener
      button.addEventListener("click", () => {
        setRowData(rowData);
        setShowNewStatusModal(true)
      });

      return button;
    };


    const [historyLoading,setHistoryLoading]=useState(false)
    const [ClientHistory,setClientHistory]=useState([])
    const handleGetHistoy=(id)=>{
      setHistoryLoading(true)
      const data_send={
        client_id:id,
      }
      axios.post("https://camp-coding.tech/new_quad_wall/quad_crm/admin/get_client_history.php",JSON.stringify(data_send))
      .then((res)=>{
        if(res.status=='success'){
          if(Array.isArray(res.message)){
            setClientHistory(res.message)
          }
        }
      console.log(res)

      })
      .catch(e=>console.log(e))
      .finally(()=>{
        setHistoryLoading(false)
      })
    }

    const HistoryAction = (cell, formatterParams, onRendered) => {
      const row = cell.getRow();
      const rowData = row.getData();

      // Create the button element
      const button = document.createElement("button");
      button.innerText = "History";
      button.style.cursor = "pointer";
      button?.classList?.add('btn-success')
      button.style.color='white'
      button.style.backgroundColor='#0b5ed7'
      button.style.border='none'
      button.style.borderRadius='4px'
      button.style.padding='4px'
      // Add click event listener
      button.addEventListener("click", () => {
        setShowHistoyModel(true)
        console.log(rowData?.client_id)
        handleGetHistoy(rowData?.client_id)
      });

      return button;
    };

    const statusdiv = (cell, formatterParams, onRendered) => {
      const row = cell.getRow();
      const rowData = row.getData();

      // Create the button element
      const pStatus = document.createElement("p");

      if(rowData?.status!=null){
        pStatus.innerText=rowData?.status?.status;
      }
      // console.log(rowData?.status)


      return pStatus;
    };
    const statusLabeldiv = (cell, formatterParams, onRendered) => {
      const row = cell.getRow();
      const rowData = row.getData();

      // Create the button element
      const pStatus = document.createElement("p");

      if(rowData?.status!=null){
        pStatus.innerText=rowData?.status?.status_label;
      }
      // console.log(rowData?.status)


      return pStatus;
    };
    const Feeddiv = (cell, formatterParams, onRendered) => {
      const row = cell.getRow();
      const rowData = row.getData();

      // Create the button element
      const pStatus = document.createElement("p");

      if(rowData?.status!=null){
        pStatus.innerText=rowData?.status?.feed_back??rowData?.feed_back;
      }
      // console.log(rowData?.status)


      return pStatus;
    };

    const sheetLabel = (cell, formatterParams, onRendered) => {
      const row = cell.getRow();
      const rowData = row.getData();

      // Create the button element
      const pStatus = document.createElement("p");

      if(rowData?.sheet!=null){
        pStatus.innerText=rowData?.sheet?.title;
      }
      // console.log(rowData?.status)


      return pStatus;
    };
    const sheetSource = (cell, formatterParams, onRendered) => {
      const row = cell.getRow();
      const rowData = row.getData();

      // Create the button element
      const pStatus = document.createElement("p");

      if(rowData?.sheet!=null){
        pStatus.innerText=rowData?.source_name;
      // console.log(rowData?.sheet?.source)
      }


      return pStatus;
    };


    const handleGetCalls = async (id) => {
      setCallLoading(true);
      const data_send = {
        client_id: id,
      };
      await axios
        .post(
          "https://camp-coding.tech/new_quad_wall/quad_crm/admin/new_client_calls.php",
          JSON.stringify(data_send)
        )
        .then((res) => {
          console.log(res.message);
          if (res.status == "success") {
            if (Array.isArray(res.message)) {
              setCalls(res.message);
            }
          }
        })
        .catch((e) => console.log(e))
        .finally(() => {
          setCallLoading(false);
        });
    };
    const callField = (cell, formatterParams, onRendered) => {
      const value = cell.getValue();
      const row = cell.getRow();
      const rowData = row.getData();
      const div = document.createElement("div");
      const btn = document.createElement("button");
      btn.innerText = "Calls";
      btn.style.color = "white";
      btn.style.backgroundColor = "#0b5ed7";
      btn.style.border = "none";
      btn.style.padding = "10px";
      btn.style.borderRadius = "4px";
      btn.addEventListener("click", function () {
        console.log(rowData);
        handleGetCalls(rowData?.client_id);
        setRowData(rowData?.client_id);
        setShowCalls(true);
      });
      div.appendChild(btn);

      return div;
    };



const [comment, setComment] = useState("");
const [commentsData, setCommentsData] = useState([]);


const handleGetComments = async (id) => {
  setCallLoading(true);
  const data_send = {
    client_id: id,
  };
  await axios
    .post(
      "https://camp-coding.tech/new_quad_wall/quad_crm/admin/get_client_comments.php",
      JSON.stringify(data_send)
    )
    .then((res) => {
      console.log(res.message);
      if (res.status == "success") {
        if (Array.isArray(res.message)) {
          setCommentsData(res.message);
        }
      }
    })
    .catch((e) => console.log(e))
    .finally(() => {
      setCallLoading(false);
    });
};
const handleAddComment = () => {
  // setAddLoading(true)
  console.log(ClientId)
  const data_send = {
    comment: comment,
    client_id: ClientId?.client_id,
    agent_id: authUser?.agent_id,
    };
  console.log(data_send);
  // return ;
  axios
    .post(
      "https://camp-coding.tech/new_quad_wall/quad_crm/admin/insert_comment.php",
      JSON.stringify(data_send)
    )
    .then((res) => {
      console.log(res);
      if (res.status == "success") {
        toast.success(res.message);
        setNewCall({ duration: "", feed_back: "" });
        handleGetComments(ClientId?.client_id);
        setOpenNewCommect(false);
        setComment('')
        setClientId(null)
        setShowAddModal(false);
      } else if (res.status == "error") {
        toast.error(res.message);
      } else {
        toast.error("Something Went Error");
      }
    })
    .catch((e) => console.log(e))
    .finally(() => {
      setAddLoading(false);
    });
};
const [ClientId, setClientId] = useState(null);
const [oldComment, setOldComment] = useState(null);


const commentField = (cell, formatterParams, onRendered) => {
  const value = cell.getValue();
  const row = cell.getRow();
  const rowData = row.getData();
  const div = document.createElement("div");
  div.classList?.add("columnDiv")
  const btn = document.createElement("button");
  const btn2 = document.createElement("button");
  btn.innerText = "Comments";
  btn2.innerText = "Profile";
  btn2.style.color = "white !important";
  btn2.style.backgroundColor = "#0b5ed7";
  btn2.style.color = "#fff";
  btn2.style.border = "none";
  btn2.style.padding = "10px";
  btn2.style.borderRadius = "4px";
  btn.style.color = "white !important";
  btn.style.backgroundColor = "#0b5ed7";
  btn.style.color = "#fff";
  btn.style.border = "none";
  btn.style.padding = "10px";
  btn.style.borderRadius = "4px";
  btn.addEventListener("click", function () {
    handleGetComments(rowData?.client_id);
    setRowData(rowData?.client_id);
    setClientId(rowData);
  });
  div.appendChild(btn);
  div.appendChild(btn2);
  btn2.addEventListener("click", function () {
    window.location.href = "/UserProfile/" + rowData?.client_id;
  });
  return div;
};
const [oldData, setOldData] = useState(null);
const [deleteComment, setDeleteComment] = useState(null);

const [openComment, setOpenNewCommect] = useState(false);
const handleDeleteComment = (comment_id,client_id) => {
  // setAddLoading(true)
  const data_send = {
    comment_id,
  };
  console.log(data_send,"data_send",rowData)
  // return ;
  axios
    .post(
      "https://camp-coding.tech/new_quad_wall/quad_crm/admin/delete_comment.php",
      JSON.stringify(data_send)
    )
    .then((res) => {
      console.log(res);
      if (res.status == "success") {
        toast.success(res.message);
        setDeleteComment(null);
        handleGetComments(client_id);
      } else if (res.status == "error") {
        toast.error(res.message);
      } else {
        toast.error("Something Went Error");
      }
    })
    .catch((e) => console.log(e))
    .finally(() => {
      setAddLoading(false);
    });
};
const commentColumns = [
  // Row number column
  {
    title: "Comment",
    field: "comment",
    sorter: "string",
    headerFilter: "input",
    headerMenu,
  },

  // {
  //   title: "Update",
  //   sorter: "string",
  //   headerFilter: "input",
  //   headerMenu,
  // },
  // ,
  // {
  //   title: "duration",
  //   field: "duration",
  //   sorter: "string",
  //   headerFilter: "input",
  //   headerMenu,
  // },
  // // { title: 'feed_back', field: 'feed_back', sorter: 'string', headerFilter: "input",headerMenu },
  // {
  //   title: "Last Action Date",
  //   field: "date",
  //   sorter: "date",
  //   sorterParams: { format: "YYYY-MM-DD" },
  //   headerFilter: "date",
  //   headerMenu,
  // },
  // {
  //   title: "Agent Name",
  //   field: "agent",
  //   formatter: agentField,
  //   sorter: "string",
  //   headerMenu,
  // },
  // {
  //   title: "Agent Email",
  //   field: "agent",
  //   formatter: agentEmailField,
  //   sorter: "string",
  //   headerMenu,
  // },
  {
    title: "Actions",
    field: "update",
    formatter: (cell) => {
      const row = cell.getRow();
      const rowData = row.getData();
      const div = document.createElement("div");
      const btn = document.createElement("button");
      btn.innerText = "Update";
      btn.style.backgroundColor='#157347';
      btn.style.color='#fff';
      btn.style.border='none';
      btn.style.padding='10px';
      btn.style.borderRadius='4px'
      btn.style.marginRight='4px'
      const btn2 = document.createElement("button");
      btn2.style.backgroundColor='#bb2d3b';
      btn2.style.color='#fff';
      btn2.style.border='none';
      btn2.style.padding='10px';
      btn2.style.borderRadius='4px'
      btn2.style.marginRight='4px'
      btn2.innerText = "Delete";
      btn.addEventListener("click", () => {
        const value = cell.getValue();
        setOldComment(rowData);
        setOldData(rowData);
      });
      btn2.addEventListener("click", () => {
        setDeleteComment((prev) => ({ ...prev, rowData }));
        handleDeleteComment(rowData?.comment_id);
        console.log(rowData)
      });
      div.appendChild(btn);
      div.appendChild(btn2);
      console.log(rowData)
      const table = cell.getTable(); // Get the table instance
      const rowIndex = row.getPosition(); // Get the row index
      const totalRows = table.getRows().length;
      if(rowIndex === totalRows ){
        return div;
      }
    },
    sorter: "string",
    headerMenu,
  },
];




const selectForm = (cell, formatterParams, onRendered) => {
  const value = cell.getValue();
  const row = cell.getRow();
  const rowData = row.getData();
  const check_box = document.createElement("input");
  check_box.type = "checkbox";
  check_box.checked = rowData?.check;
  check_box.style.width = "20px";
  check_box.style.height = "20px";
  check_box.style.cursor = "pointer";
  check_box.addEventListener("click", function () {
    setData(
      data.map((item) => {
        return {
          ...item,
          check:
            item.client_id == rowData.client_id ? !item.check : item.check,
        };
      })
    );
  });
  if(authUser.user_type!='sells'){

    return check_box;
  }
  // check_box.
};




  const historyColumns=[
    { title: 'No', formatter: rowNumFormatter, width: 50, hozAlign: 'center',headerMenu }, // Row number column
    { title: 'feed_back', field: 'feed_back', sorter: 'string', headerFilter: "input",headerMenu },
    { title: 'duration', field: 'duration', sorter: 'string', headerFilter: "input",headerMenu },
    // { title: 'feed_back', field: 'feed_back', sorter: 'string', headerFilter: "input",headerMenu },
    { title: 'Last Action Date', field: 'date', sorter: 'date',sorterParams: { format: 'YYYY-MM-DD HH::MM:ss' }, headerFilter: "date" ,headerMenu},
    { title: 'Agent Name', field: 'agent',formatter:agentField, sorter: 'string',headerMenu},
    { title: 'Agent Email', field: 'agent',formatter:agentEmailField, sorter: 'string', headerMenu},
    { title: 'Agent Phone', field: 'agent',formatter:agentEmailPhone, sorter: 'string', headerMenu},
  ];

  const handleExport = () => {
    const pp = [];
    data.map((item, index) => {
      if (item.check === true) {
        console.log('dsd');
        let newobj = {
          'course name': item.course_name || '',
          total_booking_last_month: item.total_booking_last_month || 0,
          total_booking_this_month: item.total_booking_this_month || 0,
        };
        pp.push(newobj);
      }
    });
    if (pp.length == 0) {
      toast.warn('إختر عناصر أولا');
      return;
    }
    const ids = pp.join('&&');
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(pp);
    XLSX.utils.book_append_sheet(wb, ws, 'MySheet1');
    XLSX.writeFile(wb, 'MyExcel.XLSX');
    setSelectAll(false);
    let allData = [...data];
    setData(
      allData.map(item => {
        return { ...item, check: false };
      }),
    );
    // XLSX.utils.book_append_sheet(wb, ws, 'MySheet1');
    // XLSX.writeFile(wb, 'MyExcel.XLSX');
  };

  const updateColumnTitles=()=>{
    const upColumns = [
      { title: 'No', formatter: rowNumFormatter, width: 50, hozAlign: 'center',headerMenu }, // Row number column
      { title: 'Name', field: 'client_name', sorter: 'string', headerFilter: "input",headerMenu },
      { title: 'Phone', field: 'client_phone',formatter:phone1lead, sorter: 'string', headerFilter: "input", headerFilterPlaceholder: "phone"  ,headerMenu},
      { title: 'Phone2', field: 'phone2',formatter:phone2lead, sorter: 'string', headerFilter: "input", headerFilterPlaceholder: "phone" ,headerMenu},
      // { title: 'ID', field: 'client_id', sorter: 'number', headerFilter: "input" },
      // { title: 'Name', field: 'client_name', sorter: 'string', headerFilter: "input",headerMenu },
      { title: 'project_intrested_in', field: 'project_intrested_in', sorter: 'string', headerFilter: "input",headerMenu },

      { title: 'No Answer', field: 'client_name',formatter:NotAnswerBtn, width: 100 ,headerMenu},
      {
        title: "Comments",
        field: "phone",
        formatter: commentField,
        headerFilterPlaceholder: "phone",
        headerMenu,
      },
      // {
      //   title: "Calls",
      //   field: "phone",
      //   formatter: callField,
      //   headerFilterPlaceholder: "phone",
      //   headerMenu,
      // },

      { title: 'Action', field: 'client_name',formatter:ActionBtn, width: 100 ,headerMenu},
      { title: 'History', field: 'client_name',formatter:HistoryAction, width: 100 },
      { title: 'Calls', field: 'phone',formatter:callField,  headerFilterPlaceholder: "phone" ,headerMenu },

      { title: 'Address', field: 'client_address', sorter: 'string', headerFilter: "input" ,headerMenu},
      { title: 'time_to_call', field: 'time_to_call', sorter: 'string', headerFilter: "input",headerMenu },
      { title: 'unitcode', field: 'unitcode', sorter: 'string', headerFilter: "input" ,headerMenu},
      // { title: 'Added Date', field: 'added_at', sorter: 'date',sorterParams: { format: 'YYYY-MM-DD' }, headerFilter: "date" ,headerMenu},
      { title: 'Status', field: 'added_at',formatter:statusdiv,headerMenu},
      { title: 'Status Label', field: 'added_at',formatter:statusLabeldiv,headerMenu},
      { title: 'Feed Back', field: 'added_at',formatter:Feeddiv,headerMenu},
      { title: 'Label', field: 'added_at',formatter:sheetLabel,headerMenu},
      { title: 'Source', field: 'added_at',formatter:sheetSource,headerMenu},
      { title: 'Added Date', field: 'added_at', sorter: 'date',sorterParams: { format: 'YYYY-MM-DD' }, headerFilter: "date" ,headerMenu},
      { title: "select", field: "client_id", formatter: selectForm},
      // { title: 'Action Number', field: 'call_number', sorter: 'number', headerFilter: "input" },
    ];
    setColumns(upColumns)
  }


  const agent_id=JSON.parse(localStorage.getItem("authUser"))?.agent_id;
  const [clients,setclients]=useState([]);
  const [originalClients,setOriginalClients]=useState([]);
  const [addLoading,setAddLoading]=useState(false)
  const [loading,setLoading]=useState(true);


  const rowMoved = (row) => {
    console.log('Row moved:', row.getData());
  };
  const options = {
    // pagination:true, //enable pagination
    // paginationSize:10, //optional parameter to request a certain number of rows per page
    // paginationInitialPage:1,
    // paginationSizeSelector:[5,10,20, 25,30,40, 50],
    initialSort: [
      { column: 'age', dir: 'asc' }, // sort by age in ascending order
    ],
    movableRows: true,movableColumns: true, // enable row dragging
    rowMoved: rowMoved, // optional, handle row move events
  };
  const [data,setData]=useState([]);
  const getMyAssignedCleitns=()=>{
    setLoading(true)
    const data_send={
      agent_id,
      client_type:'leads',
    }
    axios.post("https://camp-coding.tech/new_quad_wall/quad_crm/admin/salles_clients_by_status.php",JSON.stringify(data_send))
    .then((res)=>{
      console.log(res)
      if(Array.isArray(res.message)){
        setData(res.message.map((item)=>{
          return {...item,check:false,image:require("../../assets/images/whats.png"),copyimg:require("../../assets/images/copy.png")}
        }))
      }
    })
    .catch(e=>console.log(e))
    .finally(()=>{
      setLoading(false)
    })
  }


  const onExportClick = () => {
    // gridApi.api.exportDataAsCsv();
    const selectedNodes = gridRef.current.api.getSelectedNodes();
    const selectedData = selectedNodes.map(node => node.data);
    console.log(selectedData)
    const params = {
      fileName: 'selected_rows.csv',
      columnSeparator: ',',
      skipHeader: false,
      columnKeys: ['make', 'model', 'price']
    };
    const pp = [];
    selectedData.map((item, index) => {
      if (true) {
        console.log('dsd');
        let newobj = {
          'Client name': item.name || '',
          'Client phone': item.phone || '',
          'Client phone2': item.phone2 || '',
          'Client project_intrested_in': item.project_intrested_in || '',
          'Client time_to_call': item.time_to_call || '',
          'Client unitcode': item.unitcode || '',
          'Client client_type': item.client_type || '',
          'Client status': item.status || '',
          'Client status label': item.status_label || '',
          'Client job': item.job || '',
          'Client address': item.address || '',
          // 'agent name': item?.sales[0].name || '',
          // 'agent Email': item?.sales[0].user_email || '',
          // 'agent Phone': item?.sales[0].phone || '',
        };
        pp.push(newobj);
      }
    });
    if (selectedData.length == 0) {
      toast.warn('إختر عناصر أولا');
      return;
    }
    const ids = pp.join('&&');
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(pp);
    XLSX.utils.book_append_sheet(wb, ws, 'MySheet1');
    XLSX.writeFile(wb, 'MyExcel.XLSX');
    // setSelectAll(false);
    let allData = [...data];
    setData(
      allData.map(item => {
        return { ...item, check: false };
      }),
    );
    // gridRef.current.api.exportDataAsCsv(params, {
    //   rowData: selectedData
    // });
  };
  const onFilterChanged = (params) => {
    const rowCount = params.api.getDisplayedRowCount();
    setFilteredRowCount(rowCount);
  };

  const defaultColDef = useMemo(() => {
    return {
      sortable: true,
      editable: true,
      filter: true,
      floatingFilter: true,
      onCellValueChanged: (e) => console.log(e),
      // flex: 10,
      width:'fit-content'
    };
  }, []);

  const onGridReady = useCallback((params) => {
    fetch("https://www.ag-grid.com/example-assets/small-olympic-winners.json")
      .then((resp) => resp.json())
      .then((data) => {
        // setRowData(data);
      });
  }, []);
  const onSelectionChanged = (event) => {
    setSelectedRows(event.api.getSelectedRows())
    if(event.api.getSelectedRows().lengt>0){
      setDisConf(true);
    }
    else {
      setDisConf(false);
    }
  };
  const isRowSelectable = (node) => {
    return true; // or node.data.<column>
  };


  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "#",
      field: "rowNumber",
      valueGetter: (params) => params.node.rowIndex + 1, // This will give you the row number starting from 1
      filter: 'agSetColumnFilter',
      filterParams: {
        caseSensitive: true
      },
      aggFunc:"sum",
      checkboxSelection: true,
      rowDrag: true,
      headerCheckboxSelection: true,
      cellStyle: (params) => ({
        backgroundColor: params.value === "Den" ? "#c3c3c3" : "#b7d8d6",
      }),
      cellClass: (params) =>
        params.value === "Den" ? "class_name" : "another_class_name",
      // tooltipField:"age",
      // headerComponentFramework: CustomHeader
    },
    {
      headerName: 'Name',
      field: "client_name",
      aggFunc:"sum",
      // tooltipField:"age",
    },
    {
      headerName: "",
      aggFunc:"sum",
      // hide: true,
      cellRenderer: (params) => (
        <div>
        {
          params?.data&&params?.data?.client_phone!=null&&params?.data.client_phone!=""&&
          <div>
                <img style={{width:'30px'}} onClick={()=>{
                    window.open(`whatsapp://send?text=!&phone=+2${params.data.client_phone.startsWith("0")?params.data.client_phone:`${params.data.client_phone}`}`,'_blank')
            }} src={require("../../assets/images/whats.png")} alt="" />
            <img style={{width:'30px'}} onClick={()=>{
                    copyContent(params?.data&&params.data.client_phone)
            }} src={require("../../assets/images/copy.png")} alt="" />
          </div>
        }
      </div>
      ),
    },
    {
      headerName: "phone",
      field: "phone",
      aggFunc:"sum",

      sortable: true,
      // hide: true,
      cellRenderer: (params) => (
        <div>
        {
          params.data&&params.data.client_phone!=null&&params.data.client_phone!=""&&
          <div>
            <p>{params.data.client_phone}</p>
          </div>
        }
      </div>
      ),
    },
    {
      headerName: "",
      field: "phone2",
      aggFunc:"sum",
      // hide: true,
      cellRenderer: (params) => (
        <div>
          {console.log(params.data)}
        {
          params.data&&params.data.client_phone2!=null&&params.data.client_phone2!=""&&
          <div>
                          <img style={{width:'30px'}} onClick={()=>{
                    window.open(`whatsapp://send?text=!&phone=+2${params.data.client_phone2.startsWith("0")?params.data.client_phone2:`${params.data.client_phone2}`}`,'_blank')
            }} src={require("../../assets/images/whats.png")} alt="" />
            <img style={{width:'30px'}} onClick={()=>{
                    copyContent(params.data.client_phone2)
            }} src={require("../../assets/images/copy.png")} alt="" />
          </div>
        }
      </div>
      ),
    },
    {
      headerName: "phone2",
      field: "phone2",
      aggFunc:"sum",
      sortable: true,
      // hide: true,
      cellRenderer: (params) => (
        <div>
          {/* {console.log(params.data)} */}
        {
          params.data&&params.data.client_phone2!=null&&params.data.client_phone2!=""&&
          <div>
            <p>{params.data.client_phone2}</p>
          </div>
        }
      </div>
      ),
    },


    {
      headerName: "project intrested in",
      aggFunc:"sum",
      field: "project_intrested_in",
      cellRenderer: (params) => (
        <div>
          {/* {console.log(params.data)} */}
        {

            <p>{params.data&&params.data.project_intrested_in}</p>

        }
      </div>
      ),
      // tooltipField:"age",
      sortable: true,
    },
    {
      headerName: "No Answer",
      field: "phone2",
      aggFunc:"sum",
      sortable: true,
      // hide: true,
      cellRenderer: (params) => (
        <div>
          {/* {console.log(params.data)} */}
        {
          <div>
            <button onClick={()=>{
              handleChangeStatus(params.data)
            }} className='btn btn-danger'>No Answer</button>
          </div>
        }
      </div>
      ),
    },
    {
      headerName: "Action",
      field: "phone2",
      aggFunc:"sum",
      sortable: true,
      // hide: true,
      cellRenderer: (params) => (
        <div>
          {/* {console.log(params.data)} */}
        {
          <div>
            <button onClick={()=>{
                setRowData(params.data);
                setShowNewStatusModal(true)
            }} className='btn btn-primary'>Action</button>
          </div>
        }
      </div>
      ),
    },
    {
      headerName: "added at",
      field: "added_at",
      filter: 'agDateColumnFilter',
      filterParams:filterParams,
      cellEditorParams: {
        minDate: new Date('2000-01-01'), // Set the minimum date
        maxDate: new Date('2030-12-31')  // Set the maximum date
      },
      valueFormatter: params => {
        const date = new Date(params.value);
        return date.toLocaleDateString();
      },
      sortable: true,
    },
    {
      headerName: "last_action_date",
      field: "last_action_date",
      filter: 'agDateColumnFilter',
      filterParams:filterParams,
      cellEditorParams: {
        minDate: new Date('2000-01-01'), // Set the minimum date
        maxDate: new Date('2030-12-31')  // Set the maximum date
      },
      valueFormatter: params => {
        const date = new Date(params.value);
        return date.toLocaleDateString();
      },
      sortable: true,
    },
    {
      headerName: "Comments",
      aggFunc:"sum",
      field: "acttion",
      cellRenderer: (params) => (
        <div style={{ flexDirection:'column' }} className='d-flex flex-column gap-3'>
            <button className="btn btn-primary"
              onClick={()=>{
                handleGetComments(params?.data?.client_id);
                setRowData(params.data);
                console.log(params?.data)
                setClientId(params?.data);
              }}
            >Comments</button>

        </div>
      ),
      sortable: false,
    },
    {
      headerName: "Calls",
      aggFunc:"sum",
      field: "acttion",
      cellRenderer: (params) => (
        <div style={{ flexDirection:'column' }} className='d-flex flex-column gap-3'>
            <button className="btn btn-primary"
              onClick={()=>{
                handleGetCalls(params.data.client_id)
                setRowData(params.data)
                // setClientId(params.data)
                setShowCalls(true)
              }}
            >Calls</button>

        </div>
      ),
      sortable: false,
    },
    {
      headerName: "Profile",
      aggFunc:"sum",
      field: "acttion",
      cellRenderer: (params) => (
        <button className="btn btn-primary"
              onClick={()=>{
                window.location.href = "/UserProfile/" + params?.data?.client_id;

              }}
            >Profile</button>
      ),
      sortable: false,
    },
    {
      headerName: "History",
      aggFunc:"sum",
      field: "acttion",
      cellRenderer: (params) => (
        <button className="btn btn-primary"
              onClick={()=>{
                setShowHistoyModel(true)
                console.log(params.data,"paramsparams")
                handleGetHistoy(params?.data?.client_id)
              }}
            >History</button>
      ),
      sortable: false,
    },
    {
      headerName: "Source",
      aggFunc:"sum",
      field: "project_intrested_in",
      cellRenderer: (params) => (
        <div>
          {/* {console.log(params.data)} */}
        {

            <p>{params.data&&params.data.source_name}</p>

        }
      </div>
      ),
      // tooltipField:"age",
      sortable: true,
    },
    {
      headerName: "Feed Back",
      aggFunc:"sum",
      field: "project_intrested_in",
      cellRenderer: (params) => (
        <div>
          {/* {console.log(params.data)} */}
        {

            <p>{params.data&&params.data.feed_back}</p>

        }
      </div>
      ),
      // tooltipField:"age",
      sortable: true,
    },


    {
      headerName: "Email",
      field: "email",
      sortable: true,
    },
    // {
    //   headerName: "sale name",
    //   field: "email",
    //   sortable: true,
    //   cellRenderer: (params) => (
    //     <div>
    //       {
    //         params?.data&&<div>
    //           <p>{params?.data?.sales&&params?.data?.sales[0]&&params?.data?.sales[0].name}</p>
    //         </div>
    //       }
    //     </div>
    //   ),
    // },
    // {
    //   headerName: "sale email",
    //   field: "email",
    //   sortable: true,
    //   cellRenderer: (params) => (
    //     <div>
    //       {
    //         params?.data&&<div>
    //           <p>{params?.data?.sales&&params?.data?.sales[0]&&params?.data?.sales[0].user_email}</p>
    //         </div>
    //       }
    //     </div>
    //   ),
    // },
    // {
    //   headerName: "sale phone",
    //   field: "email",
    //   sortable: true,
    //   cellRenderer: (params) => (
    //     <div>
    //       {
    //         params?.data&&<div>
    //           <p>{params?.data?.sales&&params?.data?.sales[0]&&params?.data?.sales[0].phone}</p>
    //         </div>
    //       }
    //     </div>
    //   ),
    // },
    {
      headerName: "address",
      field: "address",
      sortable: true,
    },

    {
      headerName: "last action date",
      field: "added_at",
      sortable: true,
      hide: true,
    },

  ]);

  const [columnComsDefs, setColumnComsDefs] = useState([
    {
      headerName: "#",
      field: "rowNumber",
      valueGetter: (params) => params.node.rowIndex + 1, // This will give you the row number starting from 1
      filter: 'agSetColumnFilter',
      filterParams: {
        caseSensitive: true
      },
      // aggFunc:"sum",
      checkboxSelection: true,
      rowDrag: true,
      headerCheckboxSelection: true,
      cellStyle: (params) => ({
        backgroundColor: params.value === "Den" ? "#c3c3c3" : "#b7d8d6",
      }),
      cellClass: (params) =>
        params.value === "Den" ? "class_name" : "another_class_name",
      // tooltipField:"age",
      // headerComponentFramework: CustomHeader
    },
    {
      headerName: 'comment',
      field: "comment",
      // aggFunc:"sum",
      // tooltipField:"age",
    },
    {
      headerName: "update",
      // aggFunc:"sum",
      field: "acttion",
      cellRenderer: (params) =>{
        const rowIndex = params.node.rowIndex;
        const displayedRowCount = params.api.getDisplayedRowCount();
        const isLastRow = rowIndex === displayedRowCount - 1;
        return (
          <div style={{ flexDirection:'column' }} className='d-flex flex-column gap-3'>
          {/* {console.log(params,"ekllerk")} */}

            {
              isLastRow&&
              <button className="btn btn-success"
              onClick={()=>{
                setOldComment(params?.data);
                setOldData(params?.data);
              }}
              >Update</button>
            }

              {console.log(rowData,"ewklewkllkwe")}
        </div>
        )
      },
      sortable: false,
    },
    {
      headerName: "delete",
      // aggFunc:"sum",
      field: "acttion",
      cellRenderer: (params) => {
        const rowIndex = params.node.rowIndex;
        const displayedRowCount = params.api.getDisplayedRowCount();
        const isLastRow = rowIndex === displayedRowCount - 1;

        return (
          <div style={{ flexDirection: 'column' }} className='d-flex flex-column gap-3'>
            {console.log(params, "ekllerk")}
            {isLastRow && (
              <button onClick={() => {
                let data = params?.data;
                console.log("Current Row Number:", rowIndex);
                setDeleteComment((prev) => ({ ...prev, data, rowIndex })); // Optionally store rowIndex
                handleDeleteComment(params?.data?.comment_id,params?.data?.client_id);
                setRowData(params?.data);
              }} className='btn btn-danger'>
                Delete
              </button>
            )}
          </div>
        );
      },
      sortable: false,
    },
  ]);
  const [columnHisDefs, setColumnHisDefs] = useState([
    {
      headerName: "#",
      field: "rowNumber",
      valueGetter: (params) => params.node.rowIndex + 1, // This will give you the row number starting from 1
      filter: 'agSetColumnFilter',
      filterParams: {
        caseSensitive: true
      },
      // aggFunc:"sum",
      checkboxSelection: true,
      rowDrag: true,
      headerCheckboxSelection: true,
      cellStyle: (params) => ({
        backgroundColor: params.value === "Den" ? "#c3c3c3" : "#b7d8d6",
      }),
      cellClass: (params) =>
        params.value === "Den" ? "class_name" : "another_class_name",
      // tooltipField:"age",
      // headerComponentFramework: CustomHeader
    },
    {
      headerName: 'status',
      field: "status",
      // aggFunc:"sum",
      // tooltipField:"age",
    },
    {
      headerName: 'status_label',
      field: "status_label",
      // aggFunc:"sum",
      // tooltipField:"age",
    },
    {
      headerName: 'feed_back',
      field: "feed_back",
      // aggFunc:"sum",
      // tooltipField:"age",
    },
    {
      headerName: 'call_back_time',
      field: "call_back_time",
      // aggFunc:"sum",
      // tooltipField:"age",
    },
    {
      headerName: 'call_back_date',
      field: "call_back_date",
      // aggFunc:"sum",
      // tooltipField:"age",
    },


  ]);
  const [columnCallsDefs, setColumnCallsDefs] = useState([
    {
      headerName: "#",
      field: "rowNumber",
      valueGetter: (params) => params.node.rowIndex + 1, // This will give you the row number starting from 1
      filter: 'agSetColumnFilter',
      filterParams: {
        caseSensitive: true
      },
      // aggFunc:"sum",
      checkboxSelection: true,
      rowDrag: true,
      headerCheckboxSelection: true,
      cellStyle: (params) => ({
        backgroundColor: params.value === "Den" ? "#c3c3c3" : "#b7d8d6",
      }),
      cellClass: (params) =>
        params.value === "Den" ? "class_name" : "another_class_name",
      // tooltipField:"age",
      // headerComponentFramework: CustomHeader
    },

    {
      headerName: 'Feed Back',
      field: "feed_back",
      // aggFunc:"sum",
      // tooltipField:"age",
    },
    {
      headerName: 'Call Date',
      field: "date",
      filter: 'agDateColumnFilter',
      filterParams:filterParams,
      cellEditorParams: {
        minDate: new Date('2000-01-01'), // Set the minimum date
        maxDate: new Date('2030-12-31')  // Set the maximum date
      },
      valueFormatter: params => {
        const date = new Date(params.value);
        return date.toLocaleDateString();
      },
      // aggFunc:"sum",
      // tooltipField:"age",
    },
    {
      headerName: "sale Name",
      field: "email",
      sortable: true,
      cellRenderer: (params) => (
        <div>
          {
            params?.data&&<div>
              <p>{params?.data&&params?.data?.agent?.name}</p>
            </div>
          }
        </div>
      ),
    },
    {
      headerName: "sale Email",
      field: "email",
      sortable: true,
      cellRenderer: (params) => (
        <div>
          {
            params?.data&&<div>
              <p>{params?.data&&params?.data?.agent?.user_email}</p>
            </div>
          }
        </div>
      ),
    },
    {
      headerName: "sale phone",
      field: "email",
      sortable: true,
      cellRenderer: (params) => (
        <div>
          {
            params?.data&&<div>
              <p>{params?.data&&params?.data?.agent?.phone}</p>
            </div>
          }
        </div>
      ),
    },


  ]);
  const [columnsCallDefsSales,setColumnCallsDefsSales]=useState([
    {
      headerName: "#",
      field: "rowNumber",
      valueGetter: (params) => params.node.rowIndex + 1, // This will give you the row number starting from 1
      filter: 'agSetColumnFilter',
      filterParams: {
        caseSensitive: true
      },
      // aggFunc:"sum",
      checkboxSelection: true,
      rowDrag: true,
      headerCheckboxSelection: true,
      cellStyle: (params) => ({
        backgroundColor: params.value === "Den" ? "#c3c3c3" : "#b7d8d6",
      }),
      cellClass: (params) =>
        params.value === "Den" ? "class_name" : "another_class_name",
      // tooltipField:"age",
      // headerComponentFramework: CustomHeader
    },

    {
      headerName: 'Feed Back',
      field: "feed_back",
      // aggFunc:"sum",
      // tooltipField:"age",
    },
    {
      headerName: 'Call Date',
      field: "date",
      filter: 'agDateColumnFilter',
      filterParams:filterParams,
      cellEditorParams: {
        minDate: new Date('2000-01-01'), // Set the minimum date
        maxDate: new Date('2030-12-31')  // Set the maximum date
      },
      valueFormatter: params => {
        const date = new Date(params.value);
        return date.toLocaleDateString();
      },
      // aggFunc:"sum",
      // tooltipField:"age",
    },

  ])

  useEffect(()=>{
    updateColumnTitles()
  },[])
  useEffect(()=>{
    setData(data.map((item)=>{
      return{...item,check:selectAll?true:false}
    }))
    updateColumnTitles()
  },[selectAll])

  useEffect(()=>{
    getMyAssignedCleitns()
  },[])
  return (
    <>
      {
        loading?(
          <div style={{
            minHeight:'100vh',
            display:'flex',
            alignItems:'center',
            justifyContent:'center'
          }}>
            <Lottie style={{ width:'40%' }}  animationData={groovyWalkAnimation} loop={true} />
            {/* <img src={require("../../assets/images/Animation - 1717926811158(1).gif")} alt="" /> */}
          </div>
          )
        :(
          <div className='container py-6' style={{marginTop:'78px',overflow:'auto',minHeight:'100vh',maxWidth:'100%'}}>
            {
              changeLoading&&<div>
                <ThreeDots/>
              </div>
            }
            {/* <div className='d-flex align-items-center justify-content-between'>
              <div className='d-flex align-items-center gap-2 my-2'>
                <h4>Select All</h4>
                <input onChange={(e)=>{
                  setSelectAll(!selectAll)
                }} checked={selectAll} style={{
                  width:'15px',
                  height:'15px',

                }} type="checkbox" />
              </div>
              <div className="my-3">
                <button
                  onClick={() => {
                    handleExport();
                  }}
                  className="btn btn-primary text-light"
                >
                  Export
                </button>
              </div>
            </div> */}
            {/* <ReactTabulator
              data={data}
              options={
                options
              }
              // sortOrderReverse={true}
              columns={columns}
              layout={"fitData"}
              /> */}

            <div   style={gridStyle}
              className={
                "ag-theme-quartz-dark"
              }>
            <AgGridReact
            // autoGroupColumnDef ={}
            onFilterChanged={()=>{
               onFilterChanged()
            }}
            enableAdvancedFilter={true}
            grandTotalRow={"top"}
                ref={gridRef}
                rowData={data}
                // sideBar={true}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                onGridReady={onGridReady}
                rowSelection="multiple"
                // columnsi
                rowDragManaged={true}
                tooltipShowDelay={{tooltipShowDelay: 2}}
                onSelectionChanged={onSelectionChanged}
                rowMultiSelectWithClick={true}
                // ref={gridRef}
                isRowSelectable={isRowSelectable}
          // pagination={true}
          // quickFilterText={quickSearchValue}


              />

          </div>



      <Modal title="Change Client Status" open={showNewStatusModal} onOk={()=>{
        if(addLoading){
          toast.warn('request is running now Please Wait');
          return
        }
        // handleAddClient()
        // handleOk()
        handleOk()
      }} onCancel={()=>{
        setShowNewStatusModal(false)
      }} centered>
        <div>
          <label htmlFor="">New Status</label>
          <select value={newStatus} onChange={(e)=>{
            setNewStatus(e.target.value)
          }} style={{width:'100%',padding:'4px',border:'1px solid #ccc',borderRadius:'10px',backgroundColor:'transparent'}} name="" id="">
            {
              statusOptions&&statusOptions.map((item,index)=>{
                return(
                  <option value={item.value}>{item.label}</option>
                )
              })
            }
          </select>
        </div>
        <div>
          {
            newStatus=='call_back'&&
            <div className='my-2 feed_back'>
              <div>
                <label htmlFor="">Date</label>
                <input type="date" onChange={(e)=>{
                  setCallBackData({
                    ...callBackData,
                    date:e.target.value
                  })
                }} />
              </div>
              <div>
                <label htmlFor="">Time</label>
                <input type="time" onChange={(e)=>{
                  setCallBackData({
                    ...callBackData,
                    time:e.target.value
                  })
                }} />
              </div>
            </div>
          }
        </div>
        <div>
          {
            true&&
            <div className='my-2 feed_back'>
              <label htmlFor="">Feed Back</label>
              <input value={feedBack} placeholder='FeedBack' type="text" onChange={(e)=>{
                setFeedBack(e.target.value)
              }} />
            </div>
          }
        </div>
      </Modal>



      <Modal className='client_history_model' style={{ width:'90vw' }} title="Client History" open={showHistoyModel} onOk={()=>{
        setShowHistoyModel(false)
      }} onCancel={()=>{
        setShowHistoyModel(false)
      }} centered>
        {
          historyLoading?
          <div style={{
            minHeight:'30vh',
            display:'flex',
            alignItems:'center',
            justifyContent:'center'
          }}>
            <Lottie style={{ width:'40%' }}  animationData={groovyWalkAnimation} loop={true} />
            {/* <img src={require("../../assets/images/Animation - 1717926811158(1).gif")} alt="" /> */}
          </div>
          :
          <>
            {/* <ReactTabulator
              data={ClientHistory}
              options={
                options
              }
              columns={historyColumns}
              layout={"fitData"}
              /> */}
              <div style={gridStyle} className="ag-theme-quartz-dark">
                <AgGridReact
                // autoGroupColumnDef ={}
                    onFilterChanged={()=>{
                  onFilterChanged()
                }}
                enableAdvancedFilter={true}
                grandTotalRow={"top"}
                    // ref={gridRef}
                    rowData={ClientHistory}
                    // sideBar={true}
                    columnDefs={columnHisDefs}
                    defaultColDef={defaultColDef}
                    onGridReady={onGridReady}
                    rowSelection="multiple"
                    // columnsi
                    rowDragManaged={true}
                    tooltipShowDelay={{tooltipShowDelay: 2}}
                    onSelectionChanged={onSelectionChanged}
                    rowMultiSelectWithClick={true}
                  />
              </div>
          </>
        }
      </Modal>


      {/* <Modal
            className=""
            style={{ width: "90vw" }}
            title="Client Calls"
            open={showAddModal}
            onOk={() => {
              // setShowAddModal(false)
              handleAddCall();
            }}
            onCancel={() => {
              setShowAddModal(false);
            }}
            centered
          >
            <form style={{ width: "100%" }} action="">
              <div style={{ width: "100%" }}>
                <label className="w-100" htmlFor="">
                  FeedBack
                </label>
                <input
                  value={newCall?.feed_back}
                  onChange={(e) => {
                    setNewCall({ ...newCall, feed_back: e.target.value });
                  }}
                  className="w-100"
                  type="text"
                  placeholder="FeedBack"
                />
              </div>
              <div style={{ width: "100%" }}>
                <label className="w-100" htmlFor="">
                  Call Duration
                </label>
                <input
                  value={newCall?.duration}
                  onChange={(e) => {
                    setNewCall({ ...newCall, duration: e.target.value });
                  }}
                  className="w-100"
                  type="text"
                  placeholder="Call Duration"
                />
              </div>
            </form>
          </Modal> */}

          <Modal className='client_history_model' style={{ width:'90vw' }} title="Client History" open={showHistoyModel} onOk={()=>{
        setShowHistoyModel(false)
      }} onCancel={()=>{
        setShowHistoyModel(false)
      }} centered>
        {
          historyLoading?
          <div style={{
            minHeight:'30vh',
            display:'flex',
            alignItems:'center',
            justifyContent:'center'
          }}>
            <Lottie style={{ width:'40%' }}  animationData={groovyWalkAnimation} loop={true} />
            {/* <img src={require("../../assets/images/Animation - 1717926811158(1).gif")} alt="" /> */}
          </div>
          :
          <>
            {/* <ReactTabulator
              data={ClientHistory}
              options={
                options
              }
              columns={historyColumns}
              layout={"fitData"}
              /> */}
              <div style={gridStyle} className="ag-theme-quartz-dark">
                <AgGridReact
                // autoGroupColumnDef ={}
                    onFilterChanged={()=>{
                  onFilterChanged()
                }}
                enableAdvancedFilter={true}
                grandTotalRow={"top"}
                    // ref={gridRef}
                    rowData={ClientHistory}
                    // sideBar={true}
                    columnDefs={columnHisDefs}
                    defaultColDef={defaultColDef}
                    onGridReady={onGridReady}
                    rowSelection="multiple"
                    // columnsi
                    rowDragManaged={true}
                    tooltipShowDelay={{tooltipShowDelay: 2}}
                    onSelectionChanged={onSelectionChanged}
                    rowMultiSelectWithClick={true}
                  />
              </div>
          </>
        }
      </Modal>



      <Modal className='client_history_model' style={{ width:'90vw' }} title="Client Calls" open={showCalls} onOk={()=>{
        setShowCalls(false)
      }} onCancel={()=>{
        setShowCalls(false)
      }} centered>
        {
          callLoading?
          <div style={{
            minHeight:'30vh',
            display:'flex',
            alignItems:'center',
            justifyContent:'center'
          }}>
            <Lottie style={{ width:'40%' }}  animationData={groovyWalkAnimation} loop={true} />
            {/* <img src={require("../../assets/images/Animation - 1717926811158(1).gif")} alt="" /> */}
          </div>
          :
          <>
            <div  style={{width:'fit-content',cursor:'pointer'}} className='my-2 gap-2 d-flex align-items-center justify-content-end w-100'>
              <div  onClick={()=>{
              setShowAddModal(true)
            }} className='d-flex gap-2 align-items-center btn btn-success'>
                <AiOutlinePlusCircle className='text-success text-light' style={{ cursor:'pointer',fontSize:'20px' }}/>
                <h4 className='text-light'>New Call</h4>
              </div>
            </div>
            {/* {console.log(calls)} */}
            {/* <ReactTabulator
              data={calls}
              options={
                options
              }
              columns={historyColumns}
              layout={"fitData"}
              /> */}
              <div style={gridStyle}
              className={
                "ag-theme-quartz-dark"
              }>
                <AgGridReact
                  onFilterChanged={()=>{
                onFilterChanged()
              }}
              enableAdvancedFilter={true}
              grandTotalRow={"top"}
                  rowData={calls}
                  columnDefs={authUser?.user_type=='sells'?columnCallsDefs:columnsCallDefsSales}
                  defaultColDef={defaultColDef}
                  onGridReady={onGridReady}
                  rowSelection="multiple"
                  rowDragManaged={true}
                  tooltipShowDelay={{tooltipShowDelay: 2}}
                  onSelectionChanged={onSelectionChanged}
                  rowMultiSelectWithClick={true}
                />
              </div>
          </>
        }
      </Modal>


      <Modal className='' style={{ width:'90vw' }} title="Client Calls" open={showAddModal} onOk={()=>{
        // setShowAddModal(false)
        handleAddCall();
      }} onCancel={()=>{
        setShowAddModal(false)
      }} centered>
        <form style={{width:'100%'}} action="">
          <div style={{width:'100%'}}>
            <label className='w-100' htmlFor="">FeedBack</label>
            <input value={newCall?.feed_back} onChange={(e)=>{
              setNewCall({...newCall,feed_back:e.target.value})
            }} className='w-100' type="text" placeholder='FeedBack' />
          </div>
          {/* <div style={{width:'100%'}}>
            <label className='w-100' htmlFor="">Call Duration</label>
            <input value={newCall?.duration} onChange={(e)=>{
              setNewCall({...newCall,duration:e.target.value})
            }} className='w-100' type="text" placeholder='Call Duration' />
          </div> */}
        </form>
      </Modal>

      <Modal
        className=""
        style={{ width: "90vw", zIndex: 123325412 }}
        title="Client Comments"
        open={openComment}
        onOk={() => {
          // setShowAddModal(false)
          handleAddComment();
        }}
        onCancel={() => {
          setOpenNewCommect(false);
        }}
        centered
      >
        <form style={{ width: "100%" }} action="">
          <div style={{ width: "100%" }}>
            <label className="w-100" htmlFor="">
              Comment
            </label>
            <textarea
              className="w-100"
              type="text"
              placeholder="Comment"
              value={comment}
              onChange={(e) => {
                setComment(e.target.value);
              }}
            ></textarea>
          </div>
        </form>
      </Modal>
      <Modal
        className="client_history_model"
        style={{ width: "90vw" }}
        title="Client Comments"
        open={ClientId}
        onOk={() => {
          setClientId(false);
        }}
        onCancel={() => {
          setClientId(false);
        }}
        centered
      >
        {callLoading ? (
          <div
            style={{
              minHeight: "30vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Lottie
              style={{ width: "40%" }}
              animationData={groovyWalkAnimation}
              loop={true}
            />
            {/* <img src={require("../../assets/images/Animation - 1717926811158(1).gif")} alt="" /> */}
          </div>
        ) : (
          <>
          <div

style={{ width: "fit-content", cursor: "pointer" }}
className="my-2 gap-2 d-flex align-items-center w-100 d-flex align-items-center justify-content-end"
>
<div onClick={() => {
  setOpenNewCommect(true);
}} className='btn btn-success d-flex align-items-center gap-2'>
  <AiOutlinePlusCircle
    className="text-success text-light"
    style={{ cursor: "pointer", fontSize: "20px" }}
  />
  <h4 className='text-light'>New Comment</h4>
</div>
</div>
<div style={gridStyle}
              className={
                "ag-theme-quartz-dark"
              }>
                <AgGridReact
              // autoGroupColumnDef ={}
                  onFilterChanged={()=>{
                onFilterChanged()
              }}
              enableAdvancedFilter={true}
              grandTotalRow={"top"}
                  // ref={gridRef}
                  rowData={commentsData}
                  // sideBar={true}
                  columnDefs={columnComsDefs}
                  defaultColDef={defaultColDef}
                  onGridReady={onGridReady}
                  rowSelection="multiple"
                  // columnsi
                  rowDragManaged={true}
                  tooltipShowDelay={{tooltipShowDelay: 2}}
                  onSelectionChanged={onSelectionChanged}
                  rowMultiSelectWithClick={true}
                />
              </div>
            {/* <ReactTabulator
              data={commentsData}
              options={options}
              columns={commentColumns}
              layout={"fitData"}
            /> */}
          </>
        )}
      </Modal>

      <Modal
        className=""
        style={{ width: "90vw", zIndex: 1233223412 }}
        title="Client Comments"
        open={oldComment}
        onOk={() => {
          // setShowAddModal(false)
          handleUpdateComment();
        }}
        onCancel={() => {
          setOldComment(false);
        }}
        centered
      >
        <form style={{ width: "100%" }} action="">
          <div style={{ width: "100%" }}>
            <label className="w-100" htmlFor="">
              Comment
            </label>
            <textarea
              className="w-100"
              type="text"
              placeholder="Comment"
              value={oldData?.comment}
              onChange={(e) => {
                setOldData({
                  ...oldData,
                  comment: e.target.value,
                });
              }}
            ></textarea>
          </div>
        </form>
      </Modal>


          </div>
        )
      }
    </>
  )
}

export default SalesLeads
