import React from "react";
import PropTypes from "prop-types";

import SidebarContent from "./SidebarContent";

// import Img
import logo from "../../assets/images/logo-sm.svg";

// MetisMenu
import withRouter from "../../components/Common/withRouter";
import { Link } from "react-router-dom";

//redux
import { useDispatch, useSelector } from "react-redux";
import { changeSidebarType } from '../../store/actions';
import { leftSidebarTypes } from '../../constants/layout';

//i18n
import { withTranslation } from "react-i18next";

const Sidebar = (props) => {
  const authUser=localStorage.getItem("authUser");
  let uData=JSON.parse(authUser);
    const dispatch = useDispatch();

    function tToggle() {
        var body = document.body;
        if (window.screen.width <= 998) {
          body.classList.toggle("sidebar-enable");
        } else {
          body.classList.toggle("vertical-collpsed");
          body.classList.toggle("sidebar-enable");
          dispatch(changeSidebarType(leftSidebarTypes.ICON ))
        }
      }

      const color = useSelector((state) => state.dataReducer);
      // console.log(color)

      document.head.insertAdjacentHTML(
        "beforeend",
        `<style>
           .vertical-menu * {
             color: ${color?.data&&color?.data?.primary_text_color} !important;
           }
             .has-arrow{
              color:${color?.data&&color?.data?.primary_text_color} !important
             }
              .mm-show .mm-active *{
              color:${color?.data&&color?.data?.primary_text_color} !important
              }
              .mm-active a{
                color:${color?.data&&color?.data?.primary_text_color} !important
              }
                .has-arrow.active{
                  color:${color?.data&&color?.data?.primary_text_color} !important
                }
         </style>`
      );

    return (
        <React.Fragment>
            <div style={{
              backgroundColor:color.data&&color?.data?.primary_color
            }} className="vertical-menu">
                <div style={{
              backgroundColor:color.data&&color?.data?.primary_color
            }}  className="navbar-brand-box">
                    <Link to={uData.user_type=='sells'?"/SalesDashboard":"/dashboard"} className="logo logo-dark">
                        <span className="logo-sm">
                            <img style={{width:'140px'}} src={require("../../assets/images/logo3.png")}  alt="" height="22" />
                        </span>
                        <span className="logo-lg">
                            <img style={{width:'140px'}} src={require("../../assets/images/logo3.png")}  alt="" height="22" />{" "}
                            <span className="logo-txt"></span>
                        </span>
                    </Link>
                    <Link to={uData.user_type=='sells'?"/SalesDashboard":"/dashboard"} className="logo logo-light">
                        <span className="logo-lg">
                            <img style={{width:'140px'}} src={require("../../assets/images/logo3.png")} alt=""/>{" "}
                            {/* <span className="logo-txt">Quad-walls  CRM</span> */}
                        </span>
                        <span className="logo-sm">
                            <img style={{width:'140px'}} src={require("../../assets/images/logo3.png")}  alt="" height="22" />
                        </span>
                    </Link>
                </div>
                <button
                    type="button"
                    className="btn btn-sm px-3 font-size-16 header-item vertical-menu-btn"
                    onClick={() => {
                        tToggle();
                      }}
                >
                    <i className="fa fa-fw fa-bars"></i>
                </button>
                    {props.type !== "condensed" ? <SidebarContent /> : <SidebarContent />}
            </div>
        </React.Fragment>
    )
}

Sidebar.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
};

export default (withRouter(withTranslation()(Sidebar)));
