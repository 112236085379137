import { Card, Col, Modal, Select } from 'antd';
import React, { useEffect, useState } from 'react'
import { AiOutlinePlus, AiOutlinePlusCircle } from 'react-icons/ai';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useContext } from 'react';
import { AppContext } from '../../components/context/AppContextProvider';
import moment from 'moment';
import groovyWalkAnimation from "../../assets/images/Animation - 1717928166617.json";

import { ReactTabulator } from 'react-tabulator'
import 'react-tabulator/lib/styles.css';
import "tabulator-tables/dist/css/tabulator.min.css";
import Lottie from 'lottie-react';
const Delay = () => {
  const authUser=localStorage.getItem("authUser");
  let uData=JSON.parse(authUser);
  // console.log(uData,"uData")
  const [calls,setCalls]=useState([])

  const {user}=useContext(AppContext);
  const rowMoved = (row) => {
    console.log('Row moved:', row.getData());
  };
  const options = {
    // pagination:true, //enable pagination
    // paginationSize:10, //optional parameter to request a certain number of rows per page
    // paginationInitialPage:1,
    // paginationSizeSelector:[5,10,20, 25,30,40, 50],
    initialSort: [
      { column: 'age', dir: 'asc' }, // sort by age in ascending order
    ],
    movableRows: true,movableColumns: true, // enable row dragging
    rowMoved: rowMoved, // optional, handle row move events
  };
  // console.log(user)
  let agentData=JSON.parse(localStorage.getItem("authUser"));
  const [pageloading,setpageloading]=useState(true);
  const [newCall,setNewCall]=useState({
    feed_back:'',
    duration:'',
  })


const callField= (cell, formatterParams, onRendered)=>{
  const value = cell.getValue();
  const row = cell.getRow();
  const rowData = row.getData();
  const div=document.createElement('div');
  const btn=document.createElement('button');
  btn.innerText="Calls";
  btn.style.color="white";
  btn.style.backgroundColor="#0b5ed7"
  btn.style.border='none'
  btn.style.padding='10px'
  btn.style.borderRadius='4px'
  btn.addEventListener('click',function(){
    console.log(rowData);
    handleGetCalls(rowData?.client_id)
    setRowData(rowData?.client_id)
    setShowCalls(true)
  })
  div.appendChild(btn)

  return div
}
const handleGetCalls=async(id)=>{
  setCallLoading(true)
  const data_send={
    client_id:id
  }
  await axios.post("https://camp-coding.tech/new_quad_wall/quad_crm/admin/new_client_calls.php",JSON.stringify(data_send))
  .then((res)=>{
    console.log(res.message)
    if(res.status=='success'){
      if(Array.isArray(res.message)){
        setCalls(res.message);
      }
    }
  })
  .catch(e=>console.log(e))
  .finally(()=>{
    setCallLoading(false)
  })
}

const agentField= (cell, formatterParams, onRendered)=>{
  const value = cell.getValue();
  const row = cell.getRow();
  const rowData = row.getData();
  const div=document.createElement('div');
  div.innerText=rowData?.agent?.name;
  return div;
}

const agentEmailField= (cell, formatterParams, onRendered)=>{
  const value = cell.getValue();
  const row = cell.getRow();
  const rowData = row.getData();
  const div=document.createElement('div');
  div.innerText=rowData?.agent?.user_email;
  return div;
}

const agentEmailPhone= (cell, formatterParams, onRendered)=>{
  const value = cell.getValue();
  const row = cell.getRow();
  const rowData = row.getData();
  const div=document.createElement('div');
  div.innerText=rowData?.agent?.phone;
  return div;
}
const rowNumFormatter = (cell) => {
  return cell.getRow().getPosition(true) +0; // Get the row position and add 1 to make it 1-based
};
  const [loading,setLoading]=useState(true);
  const historyColumns=[
    { title: 'No', formatter: rowNumFormatter, width: 50, hozAlign: 'center',headerMenu }, // Row number column
    { title: 'status', field: 'status', sorter: 'string', headerFilter: "input",headerMenu },
    { title: 'status label', field: 'status_label', sorter: 'string', headerFilter: "input",headerMenu },
    { title: 'feed_back', field: 'feed_back', sorter: 'string', headerFilter: "input",headerMenu },
    { title: 'duration', field: 'duration', sorter: 'string', headerFilter: "input",headerMenu },
    // { title: 'feed_back', field: 'feed_back', sorter: 'string', headerFilter: "input",headerMenu },
    { title: 'Call Date', field: 'date', sorter: 'date',sorterParams: { format: 'YYYY-MM-DD' }, headerFilter: "date" ,headerMenu},
    { title: 'Agent Name', field: 'agent',formatter:agentField, sorter: 'string',headerMenu},
    { title: 'Agent Email', field: 'agent',formatter:agentEmailField, sorter: 'string', headerMenu},
    { title: 'Agent Phone', field: 'agent',formatter:agentEmailPhone, sorter: 'string', headerMenu},
  ];
  const [addLoading,setAddLoading]=useState(false)
  const [data,setData]=useState([]);
  const [historyLoading,setHistoryLoading]=useState(false)
const [ClientHistory,setClientHistory]=useState([])
  const [showCalls,setShowCalls]=useState(false);
  const [callLoading,setCallLoading]=useState(false);
  const [showNewStatusModal,setShowNewStatusModal]=useState(false)
  const [rowData,setRowData]=useState({})
  const [newStatus,setNewStatus]=useState('interested')
  const [feedBack,setFeedBack]=useState('');
  const [changeLoading,setChangeLoading]=useState(false)
  const [showHistoyModel,setShowHistoyModel]=useState(false);
  const [callBackData,setCallBackData]=useState({
    time:'',
    date:'',
  })
  const [statusOptions,setStatusOptions]=useState([
    {
      value:"interested",
      label:"interested",
    },
    {
      value:"not_interested",
      label:"not interested",
    },
    {
      value:"low_budget",
      label:"low budget",
    },
    {
      value:"call_back",
      label:"call back",
    },
    {
      value:"follow_meeting",
      label:"Follow Meeting",
    },
    {
      value:"done_deal",
      label:"Done Deal",
    },
    {
      label:"Lost Deal",
      value:"lost_deal",
    },
    // {
    //   value:"delay",
    //   label:"Delay",
    // },
  ])
  const agent_id=JSON.parse(localStorage.getItem("authUser"))?.agent_id;
  const [originalData,setOriginalData]=useState([]);
  const [showAddModal,setShowAddModal]=useState(false)
  const copyContent = async (phone) => {
    try {
      await navigator.clipboard.writeText(phone);
      console.log('Content copied to clipboard');
      toast.success('Content copied to clipboard')
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  }
  const nav =useNavigate()

  const phone1lead= (cell, formatterParams, onRendered)=>{
    const value = cell.getValue();
    const row = cell.getRow();
    const rowData = row.getData();
    const div=document.createElement('div');

    const img = document.createElement("img");
    img.src = rowData?.image;
    img.alt = "Profile";
    img.style.width = "30px";
    img.style.height = "30px";
    img.style.cursor = "pointer";

    const phone=document.createElement('span')
    phone.innerText=rowData.client_phone;
    div.appendChild(phone);
    // div.appendChild(img);

    const imgcopy=document.createElement("img");

    imgcopy.src=rowData.copyimg
    imgcopy.alt = "copy";
    imgcopy.style.width = "30px";
    imgcopy.style.height = "30px";
    imgcopy.style.cursor = "pointer";

    img.addEventListener("click", () => {
      console.log(rowData);
      window.open(`whatsapp://send?text=!&phone=+2${rowData.client_phone.startsWith("0")?rowData.client_phone:`0${rowData.client_phone}`}`,'_blank')
      // alert(JSON.stringify(rowData, null, 2)); // For demonstration purposes
    });
    imgcopy.addEventListener("click", () => {
      console.log(rowData,"copy");
      // window.open(`whatsapp://send?text=!&phone=+2${rowData.client_phone.startsWith("0")?rowData.client_phone:`0${rowData.client_phone}`}`,'_blank')
      // alert(JSON.stringify(rowData, null, 2)); // For demonstration purposes
    });

    const imgs_div=document.createElement('div');
    if(rowData.client_phone!=null){
      imgs_div.appendChild(imgcopy);
      imgs_div.appendChild(img);

    }
    div.appendChild(imgs_div)
    div.style.display='flex';
    div.style.alignItems='center';
    div.style.justifyContent='space-between'
    imgcopy.addEventListener('click',function(){
      // console.log(rowData)
      copyContent(rowData.client_phone)
    })
    return div;
  }

  const phone2lead= (cell, formatterParams, onRendered)=>{
    const value = cell.getValue();
    const row = cell.getRow();
    const rowData = row.getData();
    const div=document.createElement('div');

    const img = document.createElement("img");
    img.src = rowData?.image;
    img.alt = "Profile";
    img.style.width = "30px";
    img.style.height = "30px";
    img.style.cursor = "pointer";

    const phone=document.createElement('span')
    phone.innerText=rowData.client_phone2;
    div.appendChild(phone);
    // div.appendChild(img);

    const imgcopy=document.createElement("img");

    imgcopy.src=rowData.copyimg
    imgcopy.alt = "copy";
    imgcopy.style.width = "30px";
    imgcopy.style.height = "30px";
    imgcopy.style.cursor = "pointer";

    img.addEventListener("click", () => {
      console.log(rowData);
      window.open(`whatsapp://send?text=!&phone=+2${rowData.client_phone2.startsWith("0")?rowData.client_phone2:`0${rowData.client_phone2}`}`,'_blank')
      // alert(JSON.stringify(rowData, null, 2)); // For demonstration purposes
    });
    imgcopy.addEventListener("click", () => {
      console.log(rowData,"copy");
      // window.open(`whatsapp://send?text=!&phone=+2${rowData.client_phone.startsWith("0")?rowData.client_phone:`0${rowData.client_phone}`}`,'_blank')
      // alert(JSON.stringify(rowData, null, 2)); // For demonstration purposes
    });

    const imgs_div=document.createElement('div');
    if(rowData.client_phone2!=null){
      imgs_div.appendChild(imgcopy);
      imgs_div.appendChild(img);

    }
    // imgs_div.appendChild(imgcopy)
    // imgs_div.appendChild(img)
    div.appendChild(imgs_div)
    div.style.display='flex';
    div.style.alignItems='center';
    div.style.justifyContent='space-between'
    imgcopy.addEventListener('click',function(){
      console.log(rowData)
      copyContent(rowData.client_phone)
    })
    return div;
  }



const handleChangeStatus=(rowData)=>{
  console.log(rowData)
  if(changeLoading){
    toast.warn('Something in Action Now')
    return
  }
  setChangeLoading(true)
  const data_send={
    client_id:rowData.client_id,
    agent_id,
    status:'no_answer',
    status_label:'No Answer',
  }
  axios.post("https://camp-coding.tech/new_quad_wall/quad_crm/admin/change_status.php",JSON.stringify(data_send))
  .then((res)=>{
  console.log(res)
    if(res.status=='success'){
      toast.success(res.message)
      getNotAnswer()
    }
    else if(res.status=='error'){
      toast.error(res.message)
    }
    else{
      toast.error("Something Went Error")
    }
  })
  .catch(e=>console.log(e))
  .finally(()=>{
    setChangeLoading(false)
  })
}

const NotAnswerBtn = (cell, formatterParams, onRendered) => {
  const row = cell.getRow();
  const rowData = row.getData();

  // Create the button element
  const button = document.createElement("button");
  button.innerText = "Not Answer";
  button.style.cursor = "pointer";
  button?.classList?.add('btn-danger')
  button.style.color='white'
  button.style.backgroundColor='red'
  button.style.border='none'
  button.style.borderRadius='4px'
  button.style.padding='4px'
  // Add click event listener
  button.addEventListener("click", () => {
    // console.log(rowData);
    handleChangeStatus(rowData)
  });

  return button;
};


  const ActionBtn = (cell, formatterParams, onRendered) => {
    const row = cell.getRow();
    const rowData = row.getData();

    // Create the button element
    const button = document.createElement("button");
    button.innerText = "action";
    button.style.cursor = "pointer";
    button?.classList?.add('btn-success')
    button.style.color='white'
    button.style.backgroundColor='#4eb052'
    button.style.border='none'
    button.style.borderRadius='4px'
    button.style.padding='4px'
    // Add click event listener
    button.addEventListener("click", () => {
      setRowData(rowData);
      setShowNewStatusModal(true)
    });

    return button;
  };
  const statusdiv = (cell, formatterParams, onRendered) => {
    const row = cell.getRow();
    const rowData = row.getData();

    // Create the button element
    const pStatus = document.createElement("p");

    if(rowData?.status!=null){
      pStatus.innerText=rowData?.status;
    }
    // console.log(rowData?.status)


    return pStatus;
  };
  const statusLabeldiv = (cell, formatterParams, onRendered) => {
    const row = cell.getRow();
    const rowData = row.getData();

    // Create the button element
    const pStatus = document.createElement("p");

    if(rowData?.status!=null){
      pStatus.innerText=rowData?.status_label;
    }
    // console.log(rowData?.status)


    return pStatus;
  };
  const Feeddiv = (cell, formatterParams, onRendered) => {
    const row = cell.getRow();
    const rowData = row.getData();

    // Create the button element
    const pStatus = document.createElement("p");

    if(rowData?.status!=null){
      pStatus.innerText=rowData?.feed_back;
    }
    // console.log(rowData?.status)


    return pStatus;
  };

  const sheetLabel = (cell, formatterParams, onRendered) => {
    const row = cell.getRow();
    const rowData = row.getData();

    // Create the button element
    const pStatus = document.createElement("p");

    if(rowData?.sheet!=null){
      pStatus.innerText=rowData?.sheet?.title;
    }
    // console.log(rowData?.status)


    return pStatus;
  };
  const sheetSource = (cell, formatterParams, onRendered) => {
    const row = cell.getRow();
    const rowData = row.getData();

    // Create the button element
    const pStatus = document.createElement("p");

    if(rowData?.sheet!=null){
      pStatus.innerText=rowData?.source_name;
    // console.log(rowData?.sheet?.source)
    }


    return pStatus;
  };

  var headerMenu = function(){
    var menu = [];
    var columns = this.getColumns();

    for(let column of columns){

        //create checkbox element using font awesome icons
        let icon = document.createElement("i");
        icon.classList.add("fas");
        icon.classList.add(column.isVisible() ? "fa-check-square" : "fa-square");

        //build label
        let label = document.createElement("span");
        let title = document.createElement("span");

        title.textContent = " " + column.getDefinition().title;

        label.appendChild(icon);
        label.appendChild(title);

        //create menu item
        menu.push({
            label:label,
            action:function(e){
                //prevent menu closing
                e.stopPropagation();

                //toggle current column visibility
                column.toggle();

                //change menu item icon
                if(column.isVisible()){
                    icon.classList.remove("fa-square");
                    icon.classList.add("fa-check-square");
                }else{
                    icon.classList.remove("fa-check-square");
                    icon.classList.add("fa-square");
                }
            }
        });
    }

   return menu;
};
const [comment, setComment] = useState("");
const [commentsData, setCommentsData] = useState([]);


const handleGetComments = async (id) => {
  setCallLoading(true);
  const data_send = {
    client_id: id,
  };
  await axios
    .post(
      "https://camp-coding.tech/new_quad_wall/quad_crm/admin/get_client_comments.php",
      JSON.stringify(data_send)
    )
    .then((res) => {
      console.log(res.message);
      if (res.status == "success") {
        if (Array.isArray(res.message)) {
          setCommentsData(res.message);
        }
      }
    })
    .catch((e) => console.log(e))
    .finally(() => {
      setCallLoading(false);
    });
};
const handleAddComment = () => {
  // setAddLoading(true)
  const data_send = {
    comment: comment,
    client_id: ClientId?.client_id,
    agent_id: uData?.agent_id,
  };
  console.log(data_send);
  // return ;
  axios
    .post(
      "https://camp-coding.tech/new_quad_wall/quad_crm/admin/insert_comment.php",
      JSON.stringify(data_send)
    )
    .then((res) => {
      console.log(res);
      if (res.status == "success") {
        toast.success(res.message);
        setNewCall({ duration: "", feed_back: "" });
        handleGetComments(rowData);
        setOpenNewCommect(false);

        setClientId(null)
        setShowAddModal(false);
      } else if (res.status == "error") {
        toast.error(res.message);
      } else {
        toast.error("Something Went Error");
      }
    })
    .catch((e) => console.log(e))
    .finally(() => {
      setAddLoading(false);
    });
};
const [ClientId, setClientId] = useState(null);
const [oldComment, setOldComment] = useState(null);


const commentField = (cell, formatterParams, onRendered) => {
  const value = cell.getValue();
  const row = cell.getRow();
  const rowData = row.getData();
  const div = document.createElement("div");
  div.classList?.add("columnDiv")
  const btn = document.createElement("button");
  const btn2 = document.createElement("button");
  btn.innerText = "Comments";
  btn2.innerText = "Profile";
  btn2.style.color = "white !important";
  btn2.style.backgroundColor = "#0b5ed7";
  btn2.style.border = "none";
  btn2.style.padding = "10px";
  btn2.style.borderRadius = "4px";
  btn.style.color = "white !important";
  btn.style.backgroundColor = "#0b5ed7";
  btn.style.border = "none";
  btn.style.padding = "10px";
  btn.style.borderRadius = "4px";
  btn.addEventListener("click", function () {
    handleGetComments(rowData?.client_id);
    setRowData(rowData?.client_id);
    setClientId(rowData);
  });
  div.appendChild(btn);
  div.appendChild(btn2);
  btn2.addEventListener("click", function () {
    window.location.href = "/UserProfile/" + rowData?.client_id;
  });
  return div;
};
const [oldData, setOldData] = useState(null);
const [deleteComment, setDeleteComment] = useState(null);

const [openComment, setOpenNewCommect] = useState(false);
const handleDeleteComment = (comment_id) => {
  // setAddLoading(true)
  const data_send = {
    comment_id,
  };
  console.log(data_send,"data_send")
  // return ;
  axios
    .post(
      "https://camp-coding.tech/new_quad_wall/quad_crm/admin/delete_comment.php",
      JSON.stringify(data_send)
    )
    .then((res) => {
      console.log(res);
      if (res.status == "success") {
        toast.success(res.message);
        setDeleteComment(null);
        handleGetComments(rowData);
      } else if (res.status == "error") {
        toast.error(res.message);
      } else {
        toast.error("Something Went Error");
      }
    })
    .catch((e) => console.log(e))
    .finally(() => {
      setAddLoading(false);
    });
};

const handleUpdateComment = () => {
  // setAddLoading(true)
  const data_send = {
    ...oldData,
  };
  // return ;
  axios
    .post(
      "https://camp-coding.tech/new_quad_wall/quad_crm/admin/update_comment.php",
      JSON.stringify(data_send)
    )
    .then((res) => {
      console.log(res);
      if (res.status == "success") {
        toast.success(res.message);
        setOldComment(null);
        handleGetComments(rowData);
      } else if (res.status == "error") {
        toast.error(res.message);
      } else {
        toast.error("Something Went Error");
      }
    })
    .catch((e) => console.log(e))
    .finally(() => {
      setAddLoading(false);
    });
};
const commentColumns = [
  // Row number column
  {
    title: "Comment",
    field: "comment",
    sorter: "string",
    headerFilter: "input",
    headerMenu,
  },

  // {
  //   title: "Update",
  //   sorter: "string",
  //   headerFilter: "input",
  //   headerMenu,
  // },
  // ,
  // {
  //   title: "duration",
  //   field: "duration",
  //   sorter: "string",
  //   headerFilter: "input",
  //   headerMenu,
  // },
  // // { title: 'feed_back', field: 'feed_back', sorter: 'string', headerFilter: "input",headerMenu },
  // {
  //   title: "Last Action Date",
  //   field: "date",
  //   sorter: "date",
  //   sorterParams: { format: "YYYY-MM-DD" },
  //   headerFilter: "date",
  //   headerMenu,
  // },
  // {
  //   title: "Agent Name",
  //   field: "agent",
  //   formatter: agentField,
  //   sorter: "string",
  //   headerMenu,
  // },
  // {
  //   title: "Agent Email",
  //   field: "agent",
  //   formatter: agentEmailField,
  //   sorter: "string",
  //   headerMenu,
  // },
  {
    title: "Actions",
    field: "update",
    formatter: (cell) => {
      const row = cell.getRow();
      const rowData = row.getData();
      const div = document.createElement("div");
      const btn = document.createElement("button");
      btn.innerText = "Update";
      const btn2 = document.createElement("button");
      btn2.innerText = "Delete";
      btn.addEventListener("click", () => {
        const value = cell.getValue();
        setOldComment(rowData);
        setOldData(rowData);
      });
      btn2.addEventListener("click", () => {
        setDeleteComment((prev) => ({ ...prev, rowData }));
        handleDeleteComment(rowData?.comment_id);
        console.log(rowData)
      });
      div.appendChild(btn);
      div.appendChild(btn2);
      return div;
    },
    sorter: "string",
    headerMenu,
  },
];
const handleGetHistoy=(id)=>{
  setHistoryLoading(true)
  const data_send={
    client_id:id,
  }
  axios.post("https://camp-coding.tech/new_quad_wall/quad_crm/admin/get_client_history.php",JSON.stringify(data_send))
  .then((res)=>{
    if(res.status=='success'){
      if(Array.isArray(res.message)){
        setClientHistory(res.message)
      }
    }
  console.log(res)

  })
  .catch(e=>console.log(e))
  .finally(()=>{
    setHistoryLoading(false)
  })
}
const HistoryAction = (cell, formatterParams, onRendered) => {
  const row = cell.getRow();
  const rowData = row.getData();

  // Create the button element
  const button = document.createElement("button");
  button.innerText = "History";
  button.style.cursor = "pointer";
  button?.classList?.add('btn-success')
  button.style.color='white'
  button.style.backgroundColor='#0b5ed7'
  button.style.border='none'
  button.style.borderRadius='4px'
  button.style.padding='4px'
  // Add click event listener
  button.addEventListener("click", () => {
    setShowHistoyModel(true)
    console.log(rowData?.client_id)
    handleGetHistoy(rowData?.client_id)
  });

  return button;
};

  const columns = [
    // { title: 'ID', field: 'client_id', sorter: 'number', headerFilter: "input" },
    { title: 'No', formatter: rowNumFormatter, width: 50, hozAlign: 'center' ,headerMenu}, // Row number column
    { title: 'Name', field: 'name', sorter: 'string', headerFilter: "input" ,headerMenu},
    { title: 'Phone', field: 'client_phone',formatter:phone1lead, sorter: 'string', headerFilter: "input", headerFilterPlaceholder: "phone",headerMenu  },
    { title: 'Phone2', field: 'client_phone2',formatter:phone2lead, sorter: 'string', headerFilter: "input", headerFilterPlaceholder: "phone" ,headerMenu},
    { title: 'project interested in', field: 'project_intrested_in', sorter: 'string', headerFilter: "input" ,headerMenu},
    { title: 'History', field: 'client_name',formatter:HistoryAction, width: 100 },

    {
      title: "Comments",
      field: "phone",
      formatter: commentField,
      headerFilterPlaceholder: "phone",
      headerMenu,
    },
    { title: 'Calls', field: 'phone',formatter:callField,  headerFilterPlaceholder: "phone" ,headerMenu },


    { title: 'No Answer', field: 'name',formatter:NotAnswerBtn, width: 100 ,headerMenu},
    { title: 'Action', field: 'name',formatter:ActionBtn, width: 100 ,headerMenu},
    { title: 'Address', field: 'address', sorter: 'string', headerFilter: "input" ,headerMenu},
    { title: 'Status', field: 'added_at',formatter:statusdiv,headerMenu},
    { title: 'Status Label', field: 'added_at',formatter:statusLabeldiv,headerMenu},
    { title: 'Feed Back', field: 'added_at',formatter:Feeddiv,headerMenu},
    { title: 'Label', field: 'added_at',formatter:sheetLabel,headerMenu},
    { title: 'Source', field: 'added_at',formatter:sheetSource,headerMenu},

    // { title: 'Added Date', field: 'added_at', sorter: 'date',sorterParams: { format: 'YYYY-MM-DD' }, headerFilter: "date" },
    // { title: 'Action Number', field: 'call_number', sorter: 'number', headerFilter: "input" },
  ];


  const getNotAnswer=()=>{
    setpageloading(true)
    const data_send={
      type:uData.user_type,
      agent_id:uData.agent_id,
      status:'call_back',
      client_type:"all"
    }
    axios.post('https://camp-coding.tech/new_quad_wall/quad_crm/admin/delay_clients.php',JSON.stringify(data_send))
    .then((res)=>{
      console.log(res,"this is may response")
      if(Array.isArray(res.message)){
        setData(res.message.map((item)=>{
          return {...item,image:require("../../assets/images/whats.png"),copyimg:require("../../assets/images/copy.png")}
        }))
        setOriginalData(res.message.map((item)=>{
          return {...item,image:require("../../assets/images/whats.png"),copyimg:require("../../assets/images/copy.png")}
        }))
      }
    })
    .catch(e=>console.log(e))
    .finally(()=>{
      setpageloading(false)
    })
  }
  const handleSearch=(txt)=>{
    // if(txt==''){
    //   setData(originalData)
    // }
    // else {
    //   const pushed=[];
    //   for(let i=0;i<originalData.length;i++){
    //     if(originalData[i].name.includes(txt)||originalData[i].client_phone.includes(txt))
    //     pushed.push(originalData[i]);
    //   }
    //   setData(pushed)
    // }
    if(txt==''){
      setData(originalData)
    }
    else {
      if(true){
        // console.log('rere')
        let pushed=originalData.filter((it)=>{
          // console.log(it.name)
          if(it.name!=null){
            if(it.name.includes(txt)||it.client_phone.includes(txt)){
              return {...it}
            }
          }
          return null
        });
        console.log(pushed)
        setData(pushed)
        // setData(originalData.filter(it=>it.name.includes(txt)||it.client_phone.includes(txt)));
      }
      // else {
      //   setclients(originalColds.filter(it=>it.name.includes(txt)||it.client_phone.includes(txt)));
      // }
    }

  }



  const handleOk=()=>{
    console.log(rowData)
    setLoading(true)
    setAddLoading(true)
    if(changeLoading){
      toast.warn('Something in Action Now')
      return
    }
    setChangeLoading(true)
    const data_send={
      client_id:rowData.client_id,
      agent_id,
      status:newStatus,
      feedBack,
      status_label:statusOptions.filter(it=>it.value==newStatus)[0].label,
    }
    axios.post("https://camp-coding.tech/new_quad_wall/quad_crm/admin/change_status.php",JSON.stringify(data_send))
    .then((res)=>{
    console.log(res)
      if(res.status=='success'){
        toast.success(res.message)
        getNotAnswer()
        setShowNewStatusModal(false)
      }
      else if(res.status=='error'){
        toast.error(res.message)
      }
      else{
        toast.error("Something Went Error")
      }
    })
    .catch(e=>console.log(e))
    .finally(()=>{
      setChangeLoading(false)
    setLoading(false)
    setAddLoading(false)
    })
  }


  const handleAddCall=()=>{
    // setAddLoading(true)
    const data_send={
      ...newCall,
      client_id:rowData,
      agent_id:agentData?.agent_id
    }
    console.log(data_send)
    // return ;
    axios.post("https://camp-coding.tech/new_quad_wall/quad_crm/admin/add_new_call.php",JSON.stringify(data_send))
    .then((res)=>{
      console.log(res)
      if(res.status=='success'){
        toast.success(res.message)
        setNewCall({duration:'',feed_back:''})
        handleGetCalls(rowData)
        setShowAddModal(false)
      }
      else if(res.status=='error'){
        toast.error(res.message)
      }
      else{
        toast.error("Something Went Error")
      }
    })
    .catch(e=>console.log(e))
    .finally(()=>{
      setAddLoading(false)
    })
  }
  useEffect(()=>{
    getNotAnswer()
  },[])

  return (
    <>
      {
        pageloading?(
          [1, 2, 3].map(x => (
            <Col xs={24} md={12} lg={8} key={x}>
              <Card loading minHeight={200} />
            </Col>
          ))
        ):(
          <div style={{
            marginTop:'30px',
            overflow:'auto',
            minWidth:'100%'
          }} className='py-5 container'>


        <ReactTabulator
          data={data}
          options={
            options
          }
          // sortOrderReverse={true}
          columns={columns}
          layout={"fitData"}
        />

<Modal title="Change Client Status" open={showNewStatusModal} onOk={()=>{
        if(addLoading){
          toast.warn('request is running now Please Wait');
          return
        }
        // handleAddClient()
        // handleOk()
        handleOk()
      }} onCancel={()=>{
        setShowNewStatusModal(false)
      }} centered>
        <div>
          <label htmlFor="">New Status</label>
          <select value={newStatus} onChange={(e)=>{
            setNewStatus(e.target.value)
          }} style={{width:'100%',padding:'4px',border:'1px solid #ccc',borderRadius:'10px',backgroundColor:'transparent'}} name="" id="">
            {
              statusOptions&&statusOptions.map((item,index)=>{
                return(
                  <option value={item.value}>{item.label}</option>
                )
              })
            }
          </select>
        </div>
        <div>
          {
            true&&
            <div className='my-2 feed_back'>
              <label htmlFor="">Feed Back</label>
              <input value={feedBack} placeholder='FeedBack' type="text" onChange={(e)=>{
                setFeedBack(e.target.value)
              }} />
            </div>
          }
        </div>
      </Modal>

      <Modal className='client_history_model' style={{ width:'90vw' }} title="Client History" open={showHistoyModel} onOk={()=>{
        setShowHistoyModel(false)
      }} onCancel={()=>{
        setShowHistoyModel(false)
      }} centered>
        {
          historyLoading?
          <div style={{
            minHeight:'30vh',
            display:'flex',
            alignItems:'center',
            justifyContent:'center'
          }}>
            <Lottie style={{ width:'40%' }}  animationData={groovyWalkAnimation} loop={true} />
            {/* <img src={require("../../assets/images/Animation - 1717926811158(1).gif")} alt="" /> */}
          </div>
          :
          <>
            <ReactTabulator
              data={ClientHistory}
              options={
                options
              }
              columns={historyColumns}
              layout={"fitData"}
              />
          </>
        }
      </Modal>



      <Modal className='client_history_model' style={{ width:'90vw' }} title="Client Calls" open={showCalls} onOk={()=>{
        setShowCalls(false)
      }} onCancel={()=>{
        setShowCalls(false)
      }} centered>
        {
          callLoading?
          <div style={{
            minHeight:'30vh',
            display:'flex',
            alignItems:'center',
            justifyContent:'center'
          }}>
            <Lottie style={{ width:'40%' }}  animationData={groovyWalkAnimation} loop={true} />
            {/* <img src={require("../../assets/images/Animation - 1717926811158(1).gif")} alt="" /> */}
          </div>
          :
          <>
            <div onClick={()=>{
              setShowAddModal(true)
            }} style={{width:'fit-content',cursor:'pointer'}} className='my-2 gap-2 d-flex align-items-center '>
              <AiOutlinePlusCircle className='text-success' style={{ cursor:'pointer',fontSize:'20px' }}/>
              <h4>New Call</h4>
            </div>
            <ReactTabulator
              data={calls}
              options={
                options
              }
              columns={historyColumns}
              layout={"fitData"}
              />
          </>
        }
      </Modal>


      <Modal className='' style={{ width:'90vw' }} title="Client Calls" open={showAddModal} onOk={()=>{
        // setShowAddModal(false)
        handleAddCall();
      }} onCancel={()=>{
        setShowAddModal(false)
      }} centered>
        <form style={{width:'100%'}} action="">
          <div style={{width:'100%'}}>
            <label className='w-100' htmlFor="">FeedBack</label>
            <input value={newCall?.feed_back} onChange={(e)=>{
              setNewCall({...newCall,feed_back:e.target.value})
            }} className='w-100' type="text" placeholder='FeedBack' />
          </div>
          <div style={{width:'100%'}}>
            <label className='w-100' htmlFor="">Call Duration</label>
            <input value={newCall?.duration} onChange={(e)=>{
              setNewCall({...newCall,duration:e.target.value})
            }} className='w-100' type="text" placeholder='Call Duration' />
          </div>
        </form>
      </Modal>

      <Modal
        className=""
        style={{ width: "90vw", zIndex: 123325412 }}
        title="Client Comments"
        open={openComment}
        onOk={() => {
          // setShowAddModal(false)
          handleAddComment();
        }}
        onCancel={() => {
          setOpenNewCommect(false);
        }}
        centered
      >
        <form style={{ width: "100%" }} action="">
          <div style={{ width: "100%" }}>
            <label className="w-100" htmlFor="">
              Comment
            </label>
            <textarea
              className="w-100"
              type="text"
              placeholder="Comment"
              value={comment}
              onChange={(e) => {
                setComment(e.target.value);
              }}
            ></textarea>
          </div>
        </form>
      </Modal>
      <Modal
        className="client_history_model"
        style={{ width: "90vw" }}
        title="Client Comments"
        open={ClientId}
        onOk={() => {
          setClientId(false);
        }}
        onCancel={() => {
          setClientId(false);
        }}
        centered
      >
        {callLoading ? (
          <div
            style={{
              minHeight: "30vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Lottie
              style={{ width: "40%" }}
              animationData={groovyWalkAnimation}
              loop={true}
            />
            {/* <img src={require("../../assets/images/Animation - 1717926811158(1).gif")} alt="" /> */}
          </div>
        ) : (
          <>
            <div
              onClick={() => {
                setOpenNewCommect(true);
              }}
              style={{ width: "fit-content", cursor: "pointer" }}
              className="my-2 gap-2 d-flex align-items-center "
            >
              <AiOutlinePlusCircle
                className="text-success"
                style={{ cursor: "pointer", fontSize: "20px" }}
              />
              <h4>New Comment</h4>
            </div>
            <ReactTabulator
              data={commentsData}
              options={options}
              columns={commentColumns}
              layout={"fitData"}
            />
          </>
        )}
      </Modal>

      <Modal
        className=""
        style={{ width: "90vw", zIndex: 1233223412 }}
        title="Client Comments"
        open={oldComment}
        onOk={() => {
          // setShowAddModal(false)
          handleUpdateComment();
        }}
        onCancel={() => {
          setOldComment(false);
        }}
        centered
      >
        <form style={{ width: "100%" }} action="">
          <div style={{ width: "100%" }}>
            <label className="w-100" htmlFor="">
              Comment
            </label>
            <textarea
              className="w-100"
              type="text"
              placeholder="Comment"
              value={oldData?.comment}
              onChange={(e) => {
                setOldData({
                  ...oldData,
                  comment: e.target.value,
                });
              }}
            ></textarea>
          </div>
        </form>
      </Modal>

          </div>
        )
      }
    </>
  )
}

export default Delay
