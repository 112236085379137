import { Card, Checkbox, Modal, Space, Table } from 'antd';
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import './adminreqs.css'
import * as XLSX from 'xlsx';

import moment from 'moment';
const AdminRequests = () => {
  const [calls,setcalls]=useState([]);
  const [calldata,setcallreq]=useState({});
  const [from_date,setfrom_date]=useState("all")
  const [to_date,setto_date]=useState("all")
  const [selectcheck,setselectcheck]=useState(false);
  const getcalls=()=>{

    const data_send={
      from_date:from_date,
      to_date:to_date
    }
    console.log(data_send)
    axios.post("https://camp-coding.tech/new_quad_wall/quad_crm/admin/select_call_request_data.php",data_send)
    .then((res)=>{
      let newdata=res.message;
      newdata.map((item)=>{
        return {...item,checked:false}
      })
      console.log(res);
      setcalls(newdata);
    })
  }
  useEffect(()=>{
    getcalls();
  },[])
  const [isModalOpencall, setIsModalOpencall] = useState(false);
  const showModalcall = () => {
    setIsModalOpencall(true);
  };
  const handleOkcall = () => {
    setIsModalOpencall(false);
  };
  const handleCancelcall = () => {
    setIsModalOpencall(false);
  };

  const changereadystatus = id => {
    let arr = [...calls];
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].call_id == id) {
        arr[i].checked = !arr[i].checked;
      }
    }
    setcalls(arr);
  };

  const rendercalls=[
    {
      title: 'action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle" style={{ display: 'flex', flexDirection: 'column' }}>
          <span
            style={{
              border: 'none',
              borderRadius: 8,
              width: 60,
              cursor: 'pointer',
            }}
          >
            <Checkbox
              onChange={e => {
                e.preventDefault();
                //_checkItem(item, e);
                changereadystatus(record.call_id);
              }}
              checked={record.checked}
            ></Checkbox>
          </span>
        </Space>
      ),
    },
    {
      title:"agent id",
      dataIndex:"agent_id",
      key:"agent_id",
    },
    {
      title:"agent name",
      dataIndex:"agent_name",
      key:"agent_name",
    },
    {
      title:"response",
      dataIndex:"response",
      key:"response",
    },
    {
      title:"added date",
      dataIndex:"date_added",
      render:(_,record)=>(
        <Space>
          {moment(record.date_added).format("YYYY-MM-DD")}
          {/* <h4>{record.date_added}</h4> */}
        </Space>
      )
    },
    {
      title:"time",
      dataIndex:"time",
      key:"time",
    },
    {
      title:"answer details",
      dataIndex:"answer_details",
      key:"answer_details",
    },
    {
      title:"client name",
      dataIndex:"client_name",
      key:"client_name",
    },
    {
      title:"date to follow",
      dataIndex:"date_to_follow",
      key:"date_to_follow",
    },
    {
      title:"action",
      key:"action",
      render:(_,record)=>(
        <Space>
          {
            (record.response=="answer"&& record.answer_details=="interested")
            ?
            (<button onClick={()=>{
              setcallreq(record.request_data)
              showModalcall()
            }} className="btn btn-sm btn-link bg-primary text-white  text-dark text-decoration-none">request</button>)
            :
            (null)
          }
        </Space>
      )
    },
  ]

  const handleexport1 = () => {
    const pp = [];
    calls.map((item, index) => {
      if (item.checked === true) {
        let newobj = {
          "agent name": item?.agent_name || '',
          "client name": item?.client_name || '',
          "client phone":item.client_phone||'',
          "date added": moment(item?.date_added).format("YYYY-MM-DD"),
          "date to follow": item?.date_to_follow || '',
          "response": item?.response || '',
          // "branch name": item?.branch_name || '',
          "time": item?.time || '',
          "user offer": item?.user_offer || '',
          "bathroom": item?.request_data?.bathroom || '',
          "bedrooms": item?.request_data?.bedrooms || '',
          "compound name": item?.request_data?.compound_name || '',
          "delivery date": item?.request_data?.delivery_date || '',
          "down payment plan": item?.request_data?.down_payment_plan || '',
          "finishing": item?.request_data?.finishing || '',
          "installment year": item?.request_data?.installment_year || '',
          "max price": item?.request_data?.max_price || '',
          "min price": item?.request_data?.min_price || '',
          "max square meter": item?.request_data?.max_square_meter || '',
          "min square meter": item?.request_data?.min_square_meter || '',
          "monthly payment plan": item?.request_data?.monthly_payment_plan || '',
          "property type": item?.request_data?.property_type || '',
          "sale type": item?.request_data?.sale_type || '',
          "sub location": item?.request_data?.sub_location || '',
          "location": item?.request_data?.location_data?.location_name|| '',
        };
        pp.push(newobj);
      }
    });
    const ids = pp.join('&&');
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(pp);
    XLSX.utils.book_append_sheet(wb, ws, 'MySheet1');
    XLSX.writeFile(wb, 'MyExcel.XLSX');
  };

  return (
    <div className='py-5 container' style={{marginTop:'30px'}}>
      <div className="filter_inputs">
      <div>
      <div>
          <h4>from:</h4>
        <input onChange={(e)=>{setfrom_date(`${e.target.value} 01:00:00.000`)}} type="date" />
        </div>
        <div>
          <h4>to:</h4>
        <input  onChange={(e)=>{setto_date(`${e.target.value} 12:00:00.000`)}} type="date" />
        </div>
      </div>
        <button className='btn btn-primary'
          onClick={()=>{
            getcalls();
          }}
        >filter</button>
      </div>
      <div style={{
        display:'flex',
        alignItems:'center',
        justifyContent:'space-between',
        flexWrap:'wrap',
        marginBottom:'10px'
      }}>
        <div style={{display:'flex',alignItems:'center'}}>
          <span>select all</span>
          <Checkbox
                onChange={e => {
                  setselectcheck(!selectcheck);
                  if (selectcheck == true) {
                    setcalls(
                      calls.map(item => {
                        return { ...item, checked: false };
                      }),
                    );
                  } else {
                    setcalls(
                      calls.map(item => {
                        return { ...item, checked: true };
                      }),
                    );
                  }
                }}
                checked={selectcheck}
              ></Checkbox>
        </div>
      <button
        className="btn btn-success"
        style={{ textTransform: 'capitalize', color: 'white' }}
        onClick={handleexport1}
      >
        download excel
      </button>
      </div>
      <Table dataSource={calls} columns={rendercalls}/>
      <Modal title="Basic Modal" open={isModalOpencall} onOk={handleOkcall} onCancel={handleCancelcall}>
                <Card
                  style={{
                    width: '100%',
                  }}
                >
                  <div style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                    <p>bathroom:{calldata?.bathroom}</p>
                    <p>bedrooms:{calldata?.bathroom}</p>
                  </div>
                   <div style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                  <p>compound name:{calldata?.compound_name}</p>
                  <p>delivery date:{calldata?.delivery_date}</p>
                  </div>
                  <div style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                  <p>down payment plan:{calldata?.down_payment_plan}</p>
                  <p>monthly payment plan:{calldata?.monthly_payment_plan}</p>

                  </div>
                  <div style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                  <p>finishing:{calldata?.installment_year}</p>
                  <p>finishing:{calldata?.finishing}</p>

                  </div>
                  <div style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                  <p>max square meter:{calldata?.max_square_meter}</p>
                  <p>min square meter:{calldata?.min_square_meter}</p>
                  </div>

                <div style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                  <p>property type:{calldata?.property_type}</p>
                  <p>sale type:{calldata?.sale_type}</p>
                  </div>
                  <div style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                  <p>sup location name:{calldata?.sub_location}</p>
                  <p>location name:{calldata?.location_data?.location_name}</p>

                  </div>
                </Card>
              </Modal>
    </div>
  )
}

export default AdminRequests
