import { call, put, takeLatest } from 'redux-saga/effects';
import { FETCH_DATA_REQUEST, fetchDataSuccess, fetchDataFailure } from './actions';

function* fetchDataSaga(action) {
  const authUser=JSON.parse(localStorage.getItem("authUser"));
  try {
    const response = yield call(fetch, 'https://camp-coding.tech/new_quad_wall/quad_crm/admin/get_user_colors.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ id: action.payload }),
    });
 // Replace with your API endpoint
    // console.log(response,"responseresponse")
    // alert(response);
    const data = yield response.json();
    yield put(fetchDataSuccess(data.message));
  } catch (error) {
    yield put(fetchDataFailure(error.toString()));
  }
}

export default function* rootSaga() {
  yield takeLatest(FETCH_DATA_REQUEST, fetchDataSaga);
}
