import React, { useEffect } from 'react'

import { useState } from 'react';
import 'react-tabulator/lib/styles.css';
import "tabulator-tables/dist/css/tabulator.min.css"; //import Tabulator stylesheet
import { ReactTabulator } from 'react-tabulator'

// import Tabulator from 'tabulator-tables';
import Lottie from 'lottie-react';
import groovyWalkAnimation from "../../assets/images/Animation - 1717928166617.json";
import axios from 'axios';
import { AiOutlinePlus } from 'react-icons/ai';
import { Modal } from 'antd';
import Select from 'react-select';
// import './sellesAssign.css'
import './Teams.css'
import { toast } from 'react-toastify';
const Teams = () => {
  const rowNumFormatter = (cell) => {
    return cell.getRow().getPosition(true) +0; // Get the row position and add 1 to make it 1-based
};

var headerMenu = function(){
  var menu = [];
  var columns = this.getColumns();

  for(let column of columns){

      //create checkbox element using font awesome icons
      let icon = document.createElement("i");
      icon.classList.add("fas");
      icon.classList.add(column.isVisible() ? "fa-check-square" : "fa-square");

      //build label
      let label = document.createElement("span");
      let title = document.createElement("span");

      title.textContent = " " + column.getDefinition().title;

      label.appendChild(icon);
      label.appendChild(title);

      //create menu item
      menu.push({
          label:label,
          action:function(e){
              //prevent menu closing
              e.stopPropagation();

              //toggle current column visibility
              column.toggle();

              //change menu item icon
              if(column.isVisible()){
                  icon.classList.remove("fa-square");
                  icon.classList.add("fa-check-square");
              }else{
                  icon.classList.remove("fa-check-square");
                  icon.classList.add("fa-square");
              }
          }
      });
  }

 return menu;
};


  const columns = [
    { title: 'No', formatter: rowNumFormatter, width: 50, hozAlign: 'center' ,headerMenu}, // Row number column
    // { title: 'ID', field: 'client_id', sorter: 'number', headerFilter: "input" },
    { title: 'Name', field: 'team_name', sorter: 'string', headerFilter: "input" ,headerMenu,},
    { title: 'team type', field: 'team_type', sorter: 'string', headerFilter: "input" ,headerMenu},
    { title: 'leader email', field: 'leader_email', sorter: 'string', headerFilter: "input" ,headerMenu},
    { title: 'leader phone', field: 'leader_phone', sorter: 'string', headerFilter: "input" ,headerMenu},
  ];



  const agent_id=JSON.parse(localStorage.getItem("authUser"))?.agent_id;
  const [clients,setclients]=useState([]);
  const [originalClients,setOriginalClients]=useState([]);
  const [addLoading,setAddLoading]=useState(false)
  const [loading,setloading]=useState(true);
  const [showleco,setshowleco]=useState("leads");
  const [showAddModal,setShowAddModal]=useState(false)
  const [newTeam,setNewTeam]=useState({
    team_name:'',
    agent_id:'',
    team_leader_id:'',
    team_type:'',
  });
  const [selectedAgents,setSelectedAgents]=useState([]);
  const rowMoved = (row) => {
    console.log('Row moved:', row.getData());
  };
  const options = {
    initialSort: [
      { column: 'age', dir: 'asc' }, // sort by age in ascending order
    ],
    movableRows: true,movableColumns: true, // enable row dragging
    rowMoved: rowMoved, // optional, handle row move events
  };
  const [data,setData]=useState([]);
  const [leaders,setLeaders]=useState([]);
  const [agents,setAgents]=useState([]);
  const getData=()=>{
    setloading(true)
    axios.get('https://camp-coding.tech/new_quad_wall/quad_crm/admin/teams.php')
    .then((res)=>{
      console.log(res)
      if(Array.isArray(res.message)){
        setData(res.message)
      }
    })
    .catch(e=>console.log(e))
    .finally(()=>{
      setloading(false)
    })
  }
  const getLeader=()=>{
    axios.get("https://camp-coding.tech/new_quad_wall/quad_crm/admin/get_team_leader.php")
    .then((res)=>{
      // console.log(get_team_leader)
      // console.log(res)
      if(Array.isArray(res.message)){
        setLeaders(res.message)
      }
    })
    .catch(e=>console.log(e))
  }
  const getAgentsNotLeaders=()=>{
    axios.get("https://camp-coding.tech/new_quad_wall/quad_crm/admin/get_agents_not_leaders.php")
    .then((res)=>{
      if(Array.isArray(res.message)){
        setAgents(res.message)
      }
    })
    .catch(e=>console.log(e))
  }

  const handleAddTeam=()=>{
    setAddLoading(true)
    const Agents=[...selectedAgents];
    console.log(Agents)
    let agents="";
    for(let i=0;i<Agents.length;i++){
      if(i==0){
        agents+=Agents[i]['value']
      }
      else {
        agents+="**UAD**"+Agents[i]['value']
      }
    }
    // console.log(leaders)
// return
// console.log(newTeam.team_leader_id)
// return
    const data_send={
      team_name:newTeam.team_name,
      team_leader_id:newTeam.team_leader_id,
      team_type:leaders.filter(it=>it.agent_id==newTeam.team_leader_id)[0].user_type,
      agents
      }
      console.log(data_send)
      // setAddLoading(false)
      // return
    axios.post("https://camp-coding.tech/new_quad_wall/quad_crm/admin/add_new_team.php",JSON.stringify(data_send))
    .then((res)=>{
      console.log(res)
      if(res.status=='success'){
        toast.success(res.message)
        getData()
        setShowAddModal(false)
      }
      else if(res.status=='error'){
        toast.error(res.message)
      }
      else {
        toast.error("Something Went Error")
      }
    })
    .catch(e=>console.log(e))
    .finally(()=>{
      setAddLoading(false)
    })
  }

  useEffect(()=>{
    getData()
    getLeader()
    getAgentsNotLeaders()
  },[])
  return (
    <>
      {
        loading?(
          <div style={{
            minHeight:'100vh',
            display:'flex',
            alignItems:'center',
            justifyContent:'center'
          }}>
            <Lottie style={{ width:'40%' }}  animationData={groovyWalkAnimation} loop={true} />
            {/* <img src={require("../../assets/images/Animation - 1717926811158(1).gif")} alt="" /> */}
          </div>
          )
        :(
          <div className='container py-6' style={{marginTop:'78px',overflow:'auto',minHeight:'100vh',minWidth:'100%'}}>
            <div onClick={()=>{
              setShowAddModal(true)
            }} style={{width:'fit-content',margin:'20px 0px'}} className='btn btn-success d-flex align-items-center gap-2'>
              <h5 className='text-light'>Add New Team</h5>
              <AiOutlinePlus/>
            </div>
            <ReactTabulator
              data={data}
              options={
                options
              }
              // sortOrderReverse={true}
              columns={columns}
              layout={"fitData"}
              />
              <Modal title="Add New Team" open={showAddModal}onCancel={()=>{
                setShowAddModal(false)
              }} centered>
                <div className='form-control'>
                  <label htmlFor="">Team Name</label>
                  <input type="text" onChange={(e)=>{
                    setNewTeam({...newTeam,team_name:e.target.value});
                  }} />
                </div>
                <div className='form-control'>
                  <label htmlFor="">Team Leader</label>
                  <select
                    style={{display:'block'}}
                    value={newTeam.team_leader_id}
                    onChange={(e)=>{
                      setNewTeam({...newTeam,team_leader_id:e.target.value});
                    }}
                  >
                    {console.log(leaders)}
                    {
                      leaders.map((item,index)=>{
                        return(
                          <option value={item.agent_id}>{item.name}</option>
                        )
                      })
                    }
                  </select>
                </div>
                <div className='form-control'>
                  <label htmlFor="">Agents</label>
                  <Select
                  isMulti
                    name="colors"
                    value={selectedAgents}
                    onChange={(e)=>{
                      // console.log(e)
                      setSelectedAgents(e)
                    }}
                    options={agents.map((item,index)=>{
                      return {label:item.name,value:item.agent_id}
                    })}
                    className="basic-multi-select"
                    classNamePrefix="select"
                  />
                </div>
                <div className='my-2'>
                  <button onClick={()=>{
                    handleAddTeam()
                  }} className='btn btn-success'>
                    Add Team
                  </button>
                </div>
              </Modal>

          </div>
        )
      }
    </>
  )
}

export default Teams
