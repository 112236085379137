import React, { useEffect } from 'react';
import Routes from "./Routes";

// Import Scss
import './assets/scss/theme.scss';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-tabulator/lib/styles.css';
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the Data Grid
import "ag-grid-community/styles/ag-theme-quartz.css";
// Fake Backend
import fakeBackend from "./helpers/AuthType/fakeBackend";
import { useDispatch, useSelector } from 'react-redux';
import { fetchDataRequest } from './store/color/actions';
import TestTable from './Pages/TestTable';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
fakeBackend();

// Firebase
// Import Firebase Configuration file
// import { initFirebaseBackend } from "./helpers/firebase_helper"

// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_APIKEY,
//   authDomain: process.env.REACT_APP_AUTHDOMAIN,
//   databaseURL: process.env.REACT_APP_DATABASEURL,
//   projectId: process.env.REACT_APP_PROJECTID,
//   storageBucket: process.env.REACT_APP_STORAGEBUCKET,
//   messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
//   appId: process.env.REACT_APP_APPID,
//   measurementId: process.env.REACT_APP_MEASUREMENTID,
// }

// init firebase backend
// initFirebaseBackend(firebaseConfig)

function App() {
  const authUser=JSON.parse(localStorage.getItem("authUser"));
  // console.log(authUser)
  const navigate=useNavigate()
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchDataRequest(authUser?.agent_id)); // Dispatch with ID = 3
  }, [dispatch]);
  const color = useSelector((state) => state.dataReducer);

  document.head.insertAdjacentHTML(
    "beforeend",
    `<style>
       .table-nowrap *{
        color:${color&&color?.data?.second_text_color} !important
      }
        .tabulator,.tabulator >*,.tabulator-col{
          background-color:${color&&color?.data?.primary_color} !important
        }

          .tabulator-cell{
            background-color:${color&&color?.data?.second_color} !important
          }
        .tabulator *{
          color:${color&&color?.data?.second_text_color} !important
        }
        .table thead tr{
          background-color:${color&&color?.data?.primary_color} !important
        }
        .table thead tr *{
          color:${color&&color?.data?.primary_text_color} !important
        }
        .tabulator-col *{
          color:${color&&color?.data?.primary_text_color} !important
        }
     </style>`
  );

  function logAll(){
    let localLog=localStorage.getItem("logall");
    let logAllData=localLog&&JSON.parse(localLog);
    if(logAllData==null){
      localStorage.removeItem("authUser");
      navigate("/login")
    }
  }

  function checkExpired(){
    let current_day=moment().format('l');
    // console.log(current_day)
    if(authUser!=null){
      if(authUser&&authUser.current_day!=current_day){
        localStorage.removeItem("authUser");
        navigate("/login")
      }
    }
  }

  useEffect(()=>{
    logAll()
    checkExpired()
  },[])

  return (
    <React.Fragment>
      <Routes />

      <ToastContainer/>
    </React.Fragment>
  );
}

export default App;
