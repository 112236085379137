import React, { useEffect } from 'react'
import { Button, Card, Checkbox, Col, Modal, Select, Spin } from 'antd';
import { useState } from 'react';
import * as XLSX from 'xlsx'
import { data } from '../Tables/advanceTdata';
import { object } from 'yup';
import SupLocation from './SupLocation';
import './building.css'
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { AiOutlinePlus } from 'react-icons/ai';

function Building() {
  const [inputList, setinputList]= useState([{amenite_data :''}]);
  const [inputList2, setinputList2]= useState([{title:'', value:''}]);
  const [isModalOpen5, setIsModalOpen5] = useState(false);
  const [isModalOpen6, setIsModalOpen6] = useState(false);
  const [imagesarr,setimagesarr]=useState([]);
  const [buildid,setbuildid]=useState(null);
  const navigate=useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [addloading,setaddloading]=useState(false);
  const [allcategories,setallcategories]=useState([]);
  const [name, setname] = useState([])
  const [Price, setPrice] = useState([])
  const [Category, setCategory] = useState([])
  const [newLocation, setnewLocation] = useState([])
  const [newSupLocation, setnewSupLocation] = useState([])
  const [numBedrooms, setnumBedrooms] = useState([])
  const [sublocation,setsublocations]=useState([]);
  const [image,setimage]=useState("");
  const [uploadloading,setuploadloading]=useState(false);
  const [uploadedimages,setuploadedimages]=useState([]);
  const [Location, setLocation] = useState([])
  const [alllocations,setalllocations]=useState([]);
  const [companies,setcompanies]=useState([]);
  const [selectedcompanies,setselectedcompanies]=useState("");
  const [location_id,setlocation_id]=useState("");
  const [sub_location_id,setsub_location_id]=useState("");
  const [company_id,setcompany_id]=useState("");
  const [upbuildingloading,setupbuildingloading]=useState(false);
  const [buildingdata,setbuildingdata]=useState({
    //category_id:'',
    //company_id:'',
    //location_id:'',
    //sub_location_id:'',
    title:'',
    item_describtion:'',
    item_type :'sale',
    price:'',
    images: '',
    size:'',
    num_room:'',
    bathroom_num:'',
    parking:'',
  });
  const [alldata,setalldata]=useState([]);
  const [pageloadding,setpageloadding]=useState(true);
  const [category_id,setcategory_id]=useState("");
  const showModal = () => {
    setIsModalOpen(true);
  };
  const showModal2 = () => {
    setIsModalOpen2(true);
  };
  const handleOk = () => {
    if(uploadedimages.length==0){
      toast.warn("enter building image(s)");
      return;
    }
    if(buildingdata.title==""){
      toast.warn("enter building title");
      return;
    }
    if(buildingdata.item_describtion==""){
      toast.warn("enter building describtion");
      return;
    }
    if(buildingdata.item_type==""){
      toast.warn("enter building item type");
      return;
    }
    if(company_id==null){
      toast.warn("enter building comapny");
      return;
    }
    if(buildingdata.price==""){
      toast.warn("enter building price");
      return;
    }
    if(buildingdata.size==""){
      toast.warn("enter building size");
      return;
    }
    if(buildingdata.num_room==""){
      toast.warn("enter building number of room");
      return;
    }
    if(buildingdata.bathroom_num==""){
      toast.warn("enter building number of bathroom");
      return;
    }
    if(location_id==""){
      toast.warn("enter building`s location");
      return;
    }
    if(category_id==""){
      toast.warn("enter building`s category");
      return;
    }
    if(buildingdata.parking==""){
      toast.warn("enter number of parking");
      return;
    }
    if(sub_location_id==""){
      toast.warn("enter building`s sub location");
      return;
    }
    let allimages=[...uploadedimages];
    let allimagestxt="";
    for (let i=0;i<allimages.length;i++){
      if(i==0){
        allimagestxt+=allimages[i];
      }
      else {
        allimagestxt+="**UAD**"+allimages[i];
      }
    }
    const data_send={
      ...buildingdata,
      sub_location_id,
      location_id,
      images:allimagestxt,
      company_id,
      category_id,
    }
    console.log(data_send);
    setupbuildingloading(true)
    axios.post("https://camp-coding.tech/new_quad_wall/quad_crm/admin/insert_builder.php",JSON.stringify(data_send))
    .then((res)=>{
      console.log(res);
      if(res.status=="success"){
        toast.success(res.message);
        //setbuildingdata({});
        //setlocation_id("");
        //setsub_location_id("");
        //setcompany_id("");
      }
      else if(res.status=="error"){
        toast.error(res.message);
      }
      else {
        toast.error("something is error try agin")
      }
    }).finally(()=>{
      setupbuildingloading(false);
    }).catch(err=>{
      console.log(err);
    })
    setIsModalOpen(false);

  };
  const handleCancel = () => {
    setIsModalOpen(false);

  };
  const handleOk2 = () => {
    setIsModalOpen2(false);

  };
  const handleCancel2 = () => {
    setIsModalOpen2(false);

  };
  const getallbuildings=()=>{
    axios.get("https://camp-coding.tech/quad_crm/user/select_all_builders.php")
    .then((res)=>{
      console.log(res)
      console.log(res.message);
      let alldata=[...res.message];
      let filtereddata=alldata.map((item)=>{
        return {...item,checked:false}
      })
      setalldata(filtereddata)
    }).finally(()=>{
      setpageloadding(false);
    })
  }
  useEffect(()=>{
    getallbuildings();
  },[])
  // const [xlsData, setxlsData] = useState([])

// const handleFileUpload=(e)=>{
//   const reader=new FileReader();
//   reader.readAsBinaryString(e.target.files[0]);
//   reader.onload=(e)=>{
//     const data=e.target.result;
//     const workbook=XLSX.read(data,{type:'binary'});
//     const sheetname=workbook.SheetNames[0]
//     const sheet=workbook.Sheets[sheetname]
//     const parseData=XLSX.utils.sheet_to_json(sheet)
//     setxlsData(parseData)
//   }
// }
const uploadimage=()=>{
  if(image==""){
    toast.warn("enter image");
    return;
  }
  const formdata=new FormData();
  formdata.append("image",image);
  setuploadloading(true);
  axios.post("https://camp-coding.tech/new_quad_wall/quad_crm/admin/upload_img.php",formdata)
  .then((res)=>{
    console.log(res)
    if(res=="error"){
      toast.error(res);
    }
    else {
      toast.success("done");
      setimagesarr([...imagesarr,res]);
    }
  /*   if(res.status==="success"){
      toast.success(res.message);
      setimagesarr([...imagesarr,res.message]);
    }
    else if(res.status==="error"){
      toast.error(res.message);
    }
    else{
      toast.error("something is error try again");
    } */
  }).finally(()=>{
    setuploadloading(false);
  })
}
//   const uploadimage=()=>{
//     const formdata=new FormData();
//     formdata.append("image",image);
//     console.log(formdata);
//     setuploadloading(true);
//     axios.post("https://camp-coding.tech/new_quad_wall/quad_crm/admin/upload_img.php",formdata)
//     .then((res)=>{
//       console.log(res);
//       setuploadedimages([...uploadedimages,res]);
//       //setbuildingdata({...buildingdata,images:images+res})
// /*       if(res.status==="success"){
//         toast.success(res.message);
//       }
//       else if(res.status==="error"){
//         toast.error(res.message);
//       }
//       else {
//         toast.error("something is error try again")
//       } */
//     }).finally(()=>{
//       setuploadloading(false);
//     })
//   }
  // const addbuildingfunc=()=>{
  //   const data_send={

  //   }
  //   setaddloading(true)
  //   axios.post("",data_send)
  //   .then((res)=>{
  //     if(res.status==="success"){
  //       toast.success(res.message);
  //     }
  //     else if(res.status=="error"){
  //       toast.error(res.message);
  //     }
  //     else {
  //       toast.error("something is error try again")
  //     }
  //   }).finally(()=>{
  //     setaddloading(false);
  //   })
  // }
  async function getdata(){
    let Location_data=await axios.get(`https://camp-coding.tech/quad_crm/user/select_locations.php`).catch(err=>{
      console.log(err);
    })
    //setLocation(Location_data.message)
    let category_data=await axios.get(`https://camp-coding.tech/quad_crm/user/select_category.php`).catch(err=>{
      console.log(err);
    })
    let companies=await axios.get("https://camp-coding.tech/new_quad_wall/quad_crm/admin/select_company.php").catch(err=>{
      console.log(err);
    })
    setbuildingdata({...buildingdata,location_id:Location_data?.message[0]?.location_id});
    setlocation_id(Location_data?.message[0]?.location_id);
    //console.log(Location_data?.message[0]?.location_id)
    setbuildingdata({...buildingdata,category_id:category_data?.message[0]?.category_id});
    setallcategories(category_data?.message)
    setcategory_id(category_data?.message[0]?.category_id);
    setalllocations(Location_data?.message)
    setcompanies(companies?.message);
    setcompany_id(companies?.message[0]?.company_id);
    //setselectedcompanies({...buildingdata,companies:companies?.message[0]?.company_id});
    setbuildingdata({...buildingdata,company_id:companies?.message[0]?.company_id});
    setsublocations(Location_data?.message[0].sub_location);
    setbuildingdata({...buildingdata,sub_location_id:Location_data?.message[0].sub_location[0].sub_id});
  }
    //console.log(Location_data.message[0]?.location_id)
    //setCategory(category_data?.message);
    //console.log(category_data?.message);
    //setLocation(Location_data?.message);
    //console.log(Location_data?.message);
  useEffect(()=>{
    getdata()
  },[])
  const getsublocationsfunc=async()=>{
    let Location_data=await axios.get(`https://camp-coding.tech/quad_crm/user/select_locations.php`).catch(err=>{
      console.log(err);
    })
    const filtereddata=Location_data.message.filter(item=>item.location_id==location_id)
    //console.log(filtereddata[0])
    setsublocations(filtereddata[0]?.sub_location);
    //console.log(filtereddata[0]?.sub_location)
    setsub_location_id(filtereddata[0]?.sub_location[0]?.sub_id);
    //console.log(filtereddata[0]?.sub_location[0])
    //console.log(filtereddata[0]?.sub_location[0]?.sub_id)
    setbuildingdata({...buildingdata,sub_location_id:filtereddata[0]?.sub_location[0]?.sub_id});
  }
  useEffect(()=>{
    getsublocationsfunc();
  },[location_id]);
  const handleaddclick4=()=>{
    setinputList([...inputList, {amenities_data :''}]);
  }
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const showModal4 = () => {
    setIsModalOpen4(true);
  };
  const handleOk4 = () => {
    setIsModalOpen4(false);
    const sendedarr=[...inputList];
    const arr=[...inputList];
    let money="";

    for(let i=0;i<arr.length;i++){
      if(i==0){
        const str=arr[i].amenite_data;
        //console.log(str)
        money+=str;
        //console.log(money);
      }
      else{
        const str="**UAD**"+arr[i].amenite_data;
        //console.log(str)
        money+=str;
        //console.log(money);
      }
    }
    let arrdata=alldata.filter((item)=>item.checked==true);
    let myid=arrdata[0].item_id;
    const data_send={
      builder_id:myid,
      amenities_data:money,
    }
    console.log(data_send);
    axios.post("https://camp-coding.tech/new_quad_wall/quad_crm/admin/insert_amenities.php",JSON.stringify(data_send))
    .then((res)=>{
      if(res.status==="success"){
        toast.success(res.message);
      }
      else if(res.status==="error"){
        toast.error(res.message);
      }
      else{
        toast.error("something is error try again");
      }
    })
  };
  const handleCancel4 = () => {
    setIsModalOpen4(false);
  };
  const handleinputchange4=(e, index)=>{
    const {name, value}= e.target;
    const list= [...inputList];
    list[index][name]= value;
    setinputList(list);
  }


  const handleaddclick5=()=>{
    setinputList2([...inputList2, { title:'', value:''}]);
  }
  const handleinputchange5=(e, index)=>{
    const {name, value}= e.target;
    const list= [...inputList2];
    list[index][name]= value;
    setinputList(list);
  }

  const handleOk5 = () => {

    const arr=[...inputList2];
    let money="";

    for(let i=0;i<arr.length;i++){
      if(i==0){
        const str=arr[i].title+"**"+arr[i].value+" "+"sq feet";
        money+=str;
      }
      else{
        const str="**UAD**"+arr[i].title+"**"+arr[i].value+" "+"sq feet";
        money+=str;
      }
    }
    let arrdata=alldata.filter((item)=>item.checked==true);
    let myid=arrdata[0].item_id;
    const data_send={
      feature_data:money,
      builder_id:myid,
    }
    axios.post("https://camp-coding.tech/new_quad_wall/quad_crm/admin/insert_facts_features.php",JSON.stringify(data_send))
      .then((res)=>{
      if(res.status==="success"){
        toast.success(res.message);
      }
      else if(res.status==="error"){
        toast.error(res.message);
      }
      else{
        toast.error("something is error try again");
      }
    })
    setIsModalOpen5(false);

  };
  const showModal5 = () => {
    setIsModalOpen5(true);
  };
  const handleCance5 = () => {
    setIsModalOpen5(false);
  };


  const showModal6 = () => {
    setIsModalOpen6(true);
  };

  const handleOk6 = () => {
    setIsModalOpen6(false);
    const allimages=[...imagesarr];
    let imagestxt="";
    for(let i=0;i<allimages.length;i++){
      if(i==0){
        imagestxt+=allimages[i];
      }
      else{
        imagestxt+="**UAD**"+allimages[i];
      }
    }
    let arrdata=alldata.filter((item)=>item.checked==true);
    let myid=arrdata[0].item_id;

    const data_Send={
      image_data:imagestxt,
      builder_id:myid
    }
    console.log(data_Send);
    axios.post("https://camp-coding.tech/new_quad_wall/quad_crm/admin/insert_floor_plan.php",JSON.stringify(data_Send))
    .then((res)=>{
      if(res.status==="success"){
        toast.success(res.message);
      }
      else if(res.status==="error"){
        toast.error(res.message);
      }
      else{
        toast.error("something is error try again");
      }
    })

  };

  const handleCancel6 = () => {
    setIsModalOpen6(false);
  };

  return <>
  {
    pageloadding?(
      [1, 2, 3].map(x => (
        <Col  xs={24} md={12} lg={8} key={x}>
          <Card loading minHeight={200} />
        </Col>
      ))
    ):(
      <div className="container py-5">

      <div className="row py-5 justify-content-between">
        <div className='right-text col-4'>
          <h4>Add Realstate</h4>
        </div>
  {/*       <div className='left-text col-4 text-center'>
          <button className='btn btn-outline-info' type="button"
          onClick={showModal2}>
            Add Mullti Building
          </button>
        </div> */}

        <div className='left-text col-4 text-end'>
          {
            addloading?(
              <Spin/>
            ):(
              <button className='btn btn-outline-info' type="button"
              onClick={showModal}>
                Add Realstate
              </button>
            )
          }
        </div>

        <Modal title="Add New RealState" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} centered>
            <form
              onSubmit={(e)=>{
                e.preventDefault();
                //addbuildingfunc();
              }}
            >
              <div className='upform'>
              <input
                onChange={(e)=>{
                  setimage(e.target.files[0])
                }}
                style={{
                width:'100%',
                height:'100%'
              }} type="file" id="file-input" className='form-control' name="upload_file" />
                                <button
                    onClick={()=>{
                      uploadimage();
                    }}
                  >
                    {
                      uploadloading?(
                        <Spin style={{color:'white'}}/>
                      ):(
                        "up"
                      )
                    }
                  </button>
              </div>
              <input
              value={buildingdata.title}
              type="text" onChange={(e)=>{
                setbuildingdata({...buildingdata,title:e.target.value})
              }} placeholder='enter building title'/>
              <textarea placeholder='enter building description' style={{
                width:'100%',
                resize:'none',
                height:'80px',
                outline:'none',
                border:'1px solid #ccc',
                padding:'10px',
                borderRadius:'5px'
              }} onChange={(e)=>{
                setbuildingdata({...buildingdata,item_describtion:e.target.value})
              }}>

              </textarea>
              {/* hire */}
              <div
                style={{
                  display:'flex',
                  alignItems:'center',
                  justifyContent:'space-between',
                  width:'100%',
                }}
              >
              <Select
                value={buildingdata.item_type}
                style={{
                  width:'50%'
                }}
                onChange={(e)=>{
                  setbuildingdata({...buildingdata,item_type:e});
                }}
                options={[
          {
            value: 'sale',
            label: 'sale',
          },
          {
            value: 'rent',
            label: 'rent',
          },

                ]}
              />
              <Select style={{
                  width:'50%'
                }}
                value={company_id}
                className=" nice-select"
                placeholder="select location"
                onChange={(e)=>{
                  console.log(e)
                  setcompany_id(e);
                  //setbuildingdata({...buildingdata,company_id:e});
                }}  id="location_id"
                options={companies.map((item,index)=>{
                  return {label:`${item.company_name}`, value:`${item.company_id}`};
                })
              }
                >
              </Select>
              </div>
              <div style={{
                display:'flex',
                alignItems:'center',
                justifyContent:'space-between',
                width:'100%'
              }}>
              <input sety type='text' className='w-50 form-control my-2'  name='price' value={buildingdata.price}
              onChange={e=>{
                setbuildingdata({...buildingdata,price:e.target.value})
              }}  placeholder='Add Price'/>
              <input
                type='text'
                className='w-50 form-control my-2'
                name='category'
                value={buildingdata.size}
                onChange={e=>{
                  setbuildingdata({...buildingdata,size:e.target.value})
                }}
                placeholder='enter building size'
              />
              </div>
              <div style={{
                display:'flex',
                alignItems:'center',
                justifyContent:'space-between',
                width:'100%'
              }}>
              <input type='text' className='w-50 form-control my-2'  name='Location' value={buildingdata.num_room} onChange={e=>{
                setbuildingdata({...buildingdata,num_room:e.target.value});
              }}  placeholder='enter number of room '/>
              <input type='text' className='w-50 form-control my-2'  name='suplocation' value={buildingdata.bathroom_num} onChange={e=>{
                setbuildingdata({...buildingdata,bathroom_num:e.target.value})
              }}  placeholder='enter number of bathroom'/>
              </div>
              <div className='locadiv' style={{
                display:'flex',
                alignItems:'center',
                justifyContent:'space-between'
              }}>
    {/*             <Select style={{
                  width:'50%'
                }}
                value={buildingdata.location_id}
                className=" nice-select"
                placeholder="select location"
                onChange={(e)=>{
                  let data=alllocations.filter(item=>item.location_id==e);
                  setsublocations(data[0]?.sub_location);
                  console.log(data[0]?.sub_location)
                  setbuildingdata({...buildingdata,sub_location_id:data[0]?.sub_location[0]?.sub_id});
                  console.log(data[0]?.sub_location[0]?.sub_id)
                  setbuildingdata({...buildingdata,location_id:e});
                }}  id="location_id"
                options={alllocations.map((item,index)=>{
                  return {label:`${item.location_name}`, value:`${item.location_id}`};
                })
              }
                >
                </Select> */}
                <Select
        style={{
          width:'50%'
        }}
        value={location_id}
        className=" nice-select"
        placeholder="select location"
        onChange={(e)=>{
          let data=alllocations.filter(item=>item.location_id==e);
          setsublocations(data[0]?.sub_location);
          setlocation_id(e);
          setbuildingdata({...buildingdata,sub_location_id:data[0]?.sub_location[0]?.sub_id});
          setlocation_id(e);
          //setbuildingdata({...buildingdata,location_id:e});
        }}  id=""
        options={alllocations.map((item,index)=>{
          return {label:`${item.location_name}`, value:`${item.location_id}`};
        })
      }
      />
                <Select
                  value={category_id}
                style={{
                  width:'50%'
                }}  className="nice-select"
                  onChange={(e)=>{
                    setcategory_id(e);
                    //setbuildingdata({...buildingdata,category_id:e});
                }} id="SupLocation"
                options={allcategories.map((item,index)=>{
                  return {label:`${item.category_name}`, value:`${item.category_id}`};
                })
              }
                >
                </Select>
              </div>
              <div style={{
                width:'100%',
                display:'flex',
                alignItems:'center',
                justifyContent:'space-between'
              }}>
              <input style={{
                width:'100%'
              }} type="text"
              value={buildingdata.parking}
              onChange={(e)=>{
                setbuildingdata({...buildingdata,parking:e.target.value})
              }} placeholder='enter number of parking'/>
              <Select
                style={{
                  width:'100%'
                }}
                value={buildingdata.sub_location_id}
                className=" nice-select"
                placeholder="select location"
                onChange={(e)=>{
                  console.log(e)
                  setsub_location_id(e);
                  setbuildingdata({...buildingdata,sub_location_id:e});
                }}  id=""
              // options={Location?.find(item=>item.location_id==buildingdata.location_id)?.sub_location?.map(item=>{
              //   return {label:`${item.name}`, value:`${item.sub_id}`};
              // })}
              options={sublocation?.map((item,index)=>{
                return {label:`${item.name}`, value:`${item.sub_id}`};
                })}
              />
              </div>
            </form>
        </Modal>
        <Modal title="Add Multi Building" open={isModalOpen2} onOk={handleOk2} onCancel={handleCancel2} centered>
            <form>
              <input type='file' accept='.xlsx ,.xls'
              /*  onChange={handleFileUpload} */
              />
            </form>
        </Modal>
      </div>
      <div className="addfeatures_building">
          <div className="addfeature">
          features
      <AiOutlinePlus style={{cursor:'pointer'}} onClick={showModal4}/>
      <Modal title="Add New Feature" open={isModalOpen4} onOk={handleOk4} onCancel={handleCancel4}>
      {
        inputList.map( (x,i)=>{
          return(
          <div className="row mb-3"
          key={i}
          >
          <div class="div_input feature_div col-md-6">
          <label>Feature</label>
              <input type="text"  name="amenite_data" class="form-control" placeholder="Enter feature name" onChange={ e=>handleinputchange4(e,i) }/>
          </div>
          <div class="div_input col-md-12 mt-12">
          { inputList.length-1===i &&
          <button  className="btn btn-success" onClick={ handleaddclick4}>Add More</button>
          }
          </div>
        </div>
          );
        } )}
      </Modal>
          </div>
          <div >
          <h6>facts and feature</h6>
          <AiOutlinePlus style={{cursor:'pointer'}} onClick={showModal5}/>
      <Modal title="Add Feature And Facts" open={isModalOpen5} onOk={handleOk5} onCancel={handleCance5}>
      {
        inputList2.map( (x,i)=>{
        return(
        <div className="row fact_feature mb-3"
        key={i}
        >
          <div class="div_input col-md-6">
          <label >Money Title</label>
            <input type="text"  name="title" class="form-control"  placeholder="Enter feature title" onChange={ e=>handleinputchange5(e,i)} />
        </div>
        <div class="div_input col-md-6">
        <label >Money Value</label>
            <input type="text"  name="value" class="form-control"   placeholder="Enter feature value" onChange={ e=>handleinputchange5(e,i) }/>
        </div>
        <div class="div_input col-md-12 mt-12">
        { inputList2.length-1===i &&
        <button style={{ marginTop:'5px' }}  className="btn btn-success" onClick={ handleaddclick5}>Add More</button>
        }
        </div>
      </div>
        );
      } )}
      </Modal>
        </div>
        <div >
          <h6>floor plans</h6>
          <AiOutlinePlus style={{cursor:'pointer'}} onClick={showModal6}/>
          <Modal title="Floor Plan" open={isModalOpen6} onOk={handleOk6} onCancel={handleCancel6}>
            <div className='uploadimage_idv'>
              <input type="file" onChange={(e)=>{
                  setimage(e.target.files[0]);
                  //setimagesarr([...imagesarr,e.target.files[0]]);
  /*                 if(images==""){
                    setimages(e.target.value)
                  }
                  else setimages(images,"**UAD**",e.target.value); */
                }}/>
                <button
                  disabled={uploadloading}
                  style={{ border:'none' }}
                  onClick={(e)=>{
                    uploadimage();
                  }}
                >
                  {
                    uploadloading?(
                      <Spin/>
                    ):(
                      'up'
                    )
                  }
                </button>
            </div>
          </Modal>
        </div>
        </div>
      <div className='row'>
          <div className='col-md-12'>
              <table class="table table-hover">
                <thead>
                  <tr>
                    <th scope="col">id</th>
                    <th scope="col">company image</th>
                    <th scope="col">Image Building</th>
                    <th scope="col">Name Building</th>
                    <th scope="col">Price Building</th>
                    <th scope="col">Category</th>
                    <th scope="col">location</th>
                    <th scope="col">action</th>

                  </tr>
                </thead>
                <tbody>
                  {
                    alldata.map((item,index)=>{
                      return(
                        <tr>
                          <th style={{
                            textDecoration:'underline',
                            color:'blue',
                            cursor:'pointer'
                          }} th scope="row"
                            onClick={()=>{
                              navigate("/buildingdetails",{state:{data:item}})
                            }}
                          >{item.location_id}</th>
                          <th th scope="row">
                            <img style={{width:'60px',height:'60px',borderRadius:'50%'}} src={item.company_logo} alt="" />
                          </th>
                          <td><img style={{width:'60px',height:'60px',borderRadius:'50%'}} src={item.builder_image[0].image_url}/></td>
                          <td>{item.item_name}</td>
                          <td>{item.item_price}</td>
                          <td>{item.category_name}</td>
                          <td>{item.sub_location}</td>
                          <td><Checkbox checked={item.checked} onClick={()=>{
                            let itm_id=item.item_id;
                            setalldata(
                              alldata.map((item)=>{
                                if(item.item_id==itm_id){
                                  return {...item,checked:true}
                                }
                                else return {...item,checked:false}
                              })
                            )

                          }}></Checkbox></td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
          </div>
      </div>
    </div>
    )
  }
</>
}

export default Building
