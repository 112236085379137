import React, { useEffect, useRef, useState } from "react";

import { Container, Row, Col } from "reactstrap";
import groovyWalkAnimation from "../../assets/images/Animation - 1717928166617.json";

// import Breadcrumbs from "../../components/Common/Breadcrumb";
// import "./index.css";
// import SalesStatistics from "./salesStatistics";
// import SalesByCategory from "./salesByCategory";
// import ManageOrder from "./manageOrder";
// import TopCountries from "./topCountries";
// import TopUser from "./topUsers";
// import OrderActivity from "./orderActivity";
// import BestProduct from "./bestProduct";
// import groovyWalkAnimation from "../../assets/images/Animation - 1717928166617.json";

// import WelcomeBoard from "./welcomeBoard";
// import LineCharts from "./lineCharts";
// import EarningByItem from "./earningByItem";
import axios from "axios";
import Lottie from "lottie-react";
import { ReactTabulator } from "react-tabulator";
import { toast } from "react-toastify";
import { Button, Input, Modal, Select, Space, Table } from "antd";
import { AiOutlinePlusCircle, AiOutlineSearch } from "react-icons/ai";
import { useSearchParams } from "react-router-dom";
import Highlighter from "react-highlight-words";

const SalesDashboard = () => {
  const rowMoved = (row) => {
    console.log("Row moved:", row.getData());
  };
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const [showCalls, setShowCalls] = useState(false);
  const [callLoading, setCallLoading] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [page, setPage] = useState();
  const [calls, setCalls] = useState([]);
  const [coulmns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [rowData, setRowData] = useState({});
  const [selectedStatuses,setSelectedStatuses]=useState([]);
  const [projectInterested,setProjectInterested]=useState([]);
  const [projects,setProjects]=useState([]);

  const [newCall, setNewCall] = useState({
    feed_back: "",
    duration: "",
  });

  const copyContent = async (phone) => {
    try {
      await navigator.clipboard.writeText(phone);
      console.log("Content copied to clipboard");
      toast.success("Content copied to clipboard");
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  const phone1lead = (cell, formatterParams, onRendered) => {
    const value = cell.getValue();
    const row = cell.getRow();
    const rowData = row.getData();
    const div = document.createElement("div");

    const br = document.createElement("br");
    const img = document.createElement("img");
    img.src =
      "https://res.cloudinary.com/duovxefh6/image/upload/v1720018846/logos--whatsapp-icon_o48mcw.svg";
    img.alt = "Profile";
    img.style.width = "30px";
    img.style.height = "30px";
    img.style.cursor = "pointer";

    const phone = document.createElement("span");
    phone.innerText = rowData.phone;
    div.appendChild(phone);
    // div.appendChild(img);

    const imgcopy = document.createElement("img");

    imgcopy.src =
      "https://res.cloudinary.com/duovxefh6/image/upload/v1720018964/fa-regular--copy_flp0st.svg";
    imgcopy.alt = "copy";
    imgcopy.style.width = "30px";
    imgcopy.style.height = "30px";
    imgcopy.style.cursor = "pointer";

    img.addEventListener("click", () => {
      console.log(rowData);
      window.open(
        `whatsapp://send?text=!&phone=+2${
          rowData.phone.startsWith("0") ? rowData.phone : `${rowData.phone}`
        }`,
        "_blank"
      );
      // alert(JSON.stringify(rowData, null, 2)); // For demonstration purposes
    });
    imgcopy.addEventListener("click", () => {
      console.log(rowData, "copy");
      // window.open(`whatsapp://send?text=!&phone=+2${rowData.phone.startsWith("0")?rowData.phone:`0${rowData.phone}`}`,'_blank')
      // alert(JSON.stringify(rowData, null, 2)); // For demonstration purposes
    });

    const imgs_div = document.createElement("div");
    imgs_div.appendChild(imgcopy);
    imgs_div.appendChild(img);
    // div.appendChild(imgs_div)

    if (rowData?.phone != null && rowData?.phone != "") {
      div.appendChild(imgs_div);
      div.appendChild(br);
      div.appendChild(phone);
    }

    div.style.display = "flex";
    div.style.alignItems = "center";
    div.style.justifyContent = "space-between";
    imgcopy.addEventListener("click", function () {
      // console.log(rowData)
      copyContent(rowData.phone);
    });
    return div;
  };

  const phone2lead = (cell, formatterParams, onRendered) => {
    const value = cell.getValue();
    const row = cell.getRow();
    const rowData = row.getData();
    const div = document.createElement("div");

    const img = document.createElement("img");
    img.src =
      "https://res.cloudinary.com/duovxefh6/image/upload/v1720018846/logos--whatsapp-icon_o48mcw.svg";
    img.alt = "Profile";
    img.style.width = "30px";
    img.style.height = "30px";
    img.style.cursor = "pointer";

    const phone = document.createElement("span");
    phone.innerText = rowData.phone2;

    // div.appendChild(img);

    const imgcopy = document.createElement("img");

    imgcopy.src =
      "https://res.cloudinary.com/duovxefh6/image/upload/v1720018964/fa-regular--copy_flp0st.svg";
    imgcopy.alt = "copy";
    imgcopy.style.width = "30px";
    imgcopy.style.height = "30px";
    imgcopy.style.cursor = "pointer";

    img.addEventListener("click", () => {
      console.log(rowData);
      window.open(
        `whatsapp://send?text=!&phone=+2${
          rowData.phone2.startsWith("0") ? rowData.phone2 : `${rowData.phone2}`
        }`,
        "_blank"
      );
      // alert(JSON.stringify(rowData, null, 2)); // For demonstration purposes
    });
    imgcopy.addEventListener("click", () => {
      console.log(rowData, "copy");
      // window.open(`whatsapp://send?text=!&phone=+2${rowData.phone.startsWith("0")?rowData.phone:`0${rowData.phone}`}`,'_blank')
      // alert(JSON.stringify(rowData, null, 2)); // For demonstration purposes
    });

    const imgs_div = document.createElement("div");
    imgs_div.appendChild(imgcopy);
    imgs_div.appendChild(img);
    // console.log(rowData?.phone2)
    if (rowData?.phone2 != null && rowData?.phone2 != "") {
      div.appendChild(imgs_div);
      div.appendChild(phone);
    }
    div.style.display = "flex";
    div.style.alignItems = "center";
    div.style.justifyContent = "space-between";
    imgcopy.addEventListener("click", function () {
      console.log(rowData);
      copyContent(rowData.phone);
    });
    return div;
  };
  const rowNumFormatter = (cell) => {
    return cell.getRow().getPosition(true) + 0; // Get the row position and add 1 to make it 1-based
  };

  const selectForm = (cell, formatterParams, onRendered) => {
    const value = cell.getValue();
    const row = cell.getRow();
    const rowData = row.getData();
    const check_box = document.createElement("input");
    check_box.type = "checkbox";
    check_box.checked = rowData?.check;
    check_box.style.width = "20px";
    check_box.style.height = "20px";
    check_box.style.cursor = "pointer";
    check_box.addEventListener("click", function () {
      setData(
        data.map((item) => {
          return {
            ...item,
            check:
              item.client_id == rowData.client_id ? !item.check : item.check,
          };
        })
      );
    });

    return check_box;
    // check_box.
  };
  const handleGetCalls = async (id) => {
    setCallLoading(true);
    const data_send = {
      client_id: id,
    };
    await axios
      .post(
        "https://camp-coding.tech/new_quad_wall/quad_crm/admin/new_client_calls.php",
        JSON.stringify(data_send)
      )
      .then((res) => {
        console.log(res.message);
        if (res.status == "success") {
          if (Array.isArray(res.message)) {
            setCalls(res.message);
          }
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setCallLoading(false);
      });
  };

  const handleGetComments = async (id) => {
    setCallLoading(true);
    const data_send = {
      client_id: id,
    };
    await axios
      .post(
        "https://camp-coding.tech/new_quad_wall/quad_crm/admin/get_client_comments.php",
        JSON.stringify(data_send)
      )
      .then((res) => {
        console.log(res.message);
        if (res.status == "success") {
          if (Array.isArray(res.message)) {
            setCommentsData(res.message);
          }
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setCallLoading(false);
      });
  };
  const callField = (cell, formatterParams, onRendered) => {
    const value = cell.getValue();
    const row = cell.getRow();
    const rowData = row.getData();
    const div = document.createElement("div");
    const btn = document.createElement("button");
    btn.innerText = "Calls";
    btn.style.color = "white";
    btn.style.backgroundColor = "#0b5ed7";
    btn.style.border = "none";
    btn.style.padding = "10px";
    btn.style.borderRadius = "4px";
    btn.addEventListener("click", function () {
      console.log(rowData);
      handleGetCalls(rowData?.client_id);
      setRowData(rowData?.client_id);
      setShowCalls(true);
    });
    div.appendChild(btn);

    return div;
  };

  const commentField = (cell, formatterParams, onRendered) => {
    const value = cell.getValue();
    const row = cell.getRow();
    const rowData = row.getData();
    const div = document.createElement("div");
    div.classList?.add("columnDiv")
    const btn = document.createElement("button");
    const btn2 = document.createElement("button");
    btn.innerText = "Comments";
    btn2.innerText = "Profile";
    btn2.style.color = "white !important";
    btn2.style.backgroundColor = "#0b5ed7";
    btn2.style.border = "none";
    btn2.style.padding = "10px";
    btn2.style.borderRadius = "4px";
    btn.style.color = "white !important";
    btn.style.backgroundColor = "#0b5ed7";
    btn.style.border = "none";
    btn.style.padding = "10px";
    btn.style.borderRadius = "4px";
    btn.addEventListener("click", function () {
      handleGetComments(rowData?.client_id);
      setRowData(rowData?.client_id);
      setClientId(rowData);
    });
    div.appendChild(btn);
    div.appendChild(btn2);
    btn2.addEventListener("click", function () {
      window.location.href = "/UserProfile/" + rowData?.client_id;
    });
    return div;
  };
  var headerMenu = function () {
    var menu = [];
    var columns = this.getColumns();

    for (let column of columns) {
      //create checkbox element using font awesome icons
      let icon = document.createElement("i");
      icon.classList.add("fas");
      icon.classList.add(column.isVisible() ? "fa-check-square" : "fa-square");

      //build label
      let label = document.createElement("span");
      let title = document.createElement("span");

      title.textContent = " " + column.getDefinition().title;

      label.appendChild(icon);
      label.appendChild(title);

      //create menu item
      menu.push({
        label: label,
        action: function (e) {
          //prevent menu closing
          e.stopPropagation();

          //toggle current column visibility
          column.toggle();

          //change menu item icon
          if (column.isVisible()) {
            icon.classList.remove("fa-square");
            icon.classList.add("fa-check-square");
          } else {
            icon.classList.remove("fa-check-square");
            icon.classList.add("fa-square");
          }
        },
      });
    }

    return menu;
  };
  const updateColumnTitles = (dataLength) => {
    const updatedColumns = [
      {
        title: "No",
        formatter: rowNumFormatter,
        width: 50,
        hozAlign: "center",
        headerMenu,
      }, // Row number column
      {
        title: "Name",
        field: "name",
        sorter: "string",
        headerFilter: "input",
        headerMenu,
      },
      {
        title: "Status",
        field: "status",
        sorter: "string",
        headerFilter: "input",
        headerMenu,
      },
      {
        title: "Last Action",
        field: "last_action_date",
        sorter: "string",
        headerFilter: "input",
        headerMenu,
      },
      {
        title: "Time To Call",
        field: "time_to_call",
        sorter: "string",
        headerFilter: "input",
        headerMenu,
      },
      {
        title: "Phone",
        field: "phone",
        formatter: phone1lead,
        sorter: "string",
        headerFilter: "input",
        headerFilterPlaceholder: "phone",
        headerMenu,
      },
      {
        title: "Phone2",
        field: "phone2",
        formatter: phone2lead,
        sorter: "string",
        headerFilter: "input",
        headerFilterPlaceholder: "phone",
        headerMenu,
      },
      {
        title: "Address",
        field: "address",
        sorter: "string",
        headerFilter: "input",
        headerMenu,
      },
      {
        title: "Added Date",
        field: "added_at",
        sorter: "date",
        sorterParams: { format: "YYYY-MM-DD" },
        headerFilter: "date",
        headerMenu,
      },
      // { title: 'Action Number', field: 'call_number', sorter: 'number', headerFilter: "input" },
      {
        title: "Calls",
        field: "phone",
        formatter: callField,
        headerFilterPlaceholder: "phone",
        headerMenu,
      },
      {
        title: "Comments",
        field: "phone",
        formatter: commentField,
        headerFilterPlaceholder: "phone",
        headerMenu,
      },
      // { title: 'select', field: 'client_id',formatter:selectForm,width:30 },
    ];
    setColumns(updatedColumns);
  };

  const agentField = (cell, formatterParams, onRendered) => {
    const value = cell.getValue();
    const row = cell.getRow();
    const rowData = row.getData();
    const div = document.createElement("div");
    div.innerText = rowData?.agent?.name;
    return div;
  };

  const agentEmailField = (cell, formatterParams, onRendered) => {
    const value = cell.getValue();
    const row = cell.getRow();
    const rowData = row.getData();
    const div = document.createElement("div");
    div.innerText = rowData?.agent?.user_email;
    return div;
  };

  const agentEmailPhone = (cell, formatterParams, onRendered) => {
    const value = cell.getValue();
    const row = cell.getRow();
    const rowData = row.getData();
    const div = document.createElement("div");
    div.innerText = rowData?.agent?.phone;
    return div;
  };

  const callColumns = [
    {
      title: "No",
      formatter: rowNumFormatter,
      width: 50,
      hozAlign: "center",
      headerMenu,
    }, // Row number column
    {
      title: "feed_back",
      field: "feed_back",
      sorter: "string",
      headerFilter: "input",
      headerMenu,
    },
    {
      title: "duration",
      field: "duration",
      sorter: "string",
      headerFilter: "input",
      headerMenu,
    },
    // { title: 'feed_back', field: 'feed_back', sorter: 'string', headerFilter: "input",headerMenu },
    {
      title: "Last Action Date",
      field: "date",
      sorter: "date",
      sorterParams: { format: "YYYY-MM-DD" },
      headerFilter: "date",
      headerMenu,
    },
    {
      title: "Agent Name",
      field: "agent",
      formatter: agentField,
      sorter: "string",
      headerMenu,
    },
    {
      title: "Agent Email",
      field: "agent",
      formatter: agentEmailField,
      sorter: "string",
      headerMenu,
    },
    {
      title: "Agent Phone",
      field: "agent",
      formatter: agentEmailPhone,
      sorter: "string",
      headerMenu,
    },
  ];
  const [oldData, setOldData] = useState(null);
  const [openComment, setOpenNewCommect] = useState(false);
  const commentColumns = [
    // Row number column
    {
      title: "Comment",
      field: "comment",
      sorter: "string",
      headerFilter: "input",
      headerMenu,
    },

    // {
    //   title: "Update",
    //   sorter: "string",
    //   headerFilter: "input",
    //   headerMenu,
    // },
    // ,
    // {
    //   title: "duration",
    //   field: "duration",
    //   sorter: "string",
    //   headerFilter: "input",
    //   headerMenu,
    // },
    // // { title: 'feed_back', field: 'feed_back', sorter: 'string', headerFilter: "input",headerMenu },
    // {
    //   title: "Last Action Date",
    //   field: "date",
    //   sorter: "date",
    //   sorterParams: { format: "YYYY-MM-DD" },
    //   headerFilter: "date",
    //   headerMenu,
    // },
    // {
    //   title: "Agent Name",
    //   field: "agent",
    //   formatter: agentField,
    //   sorter: "string",
    //   headerMenu,
    // },
    // {
    //   title: "Agent Email",
    //   field: "agent",
    //   formatter: agentEmailField,
    //   sorter: "string",
    //   headerMenu,
    // },
    {
      title: "Actions",
      field: "update",
      formatter: (cell) => {
        const row = cell.getRow();
        const rowData = row.getData();
        const div = document.createElement("div");
        const btn = document.createElement("button");
        btn.innerText = "Update";
        const btn2 = document.createElement("button");
        btn2.innerText = "Delete";
        btn.addEventListener("click", () => {
          const value = cell.getValue();
          setOldComment(rowData);
          setOldData(rowData);
        });
        btn2.addEventListener("click", () => {
          setDeleteComment((prev) => ({ ...prev, rowData }));
          handleDeleteComment(rowData?.comment_id);
        });
        div.appendChild(btn);
        div.appendChild(btn2);
        return div;
      },
      sorter: "string",
      headerMenu,
    },
  ];
  const options = {
    initialSort: [
      { column: "age", dir: "asc" }, // sort by age in ascending order
    ],
    movableRows: true,
    movableColumns: true, // enable row dragging
    rowMoved: rowMoved, // optional, handle row move events
  };
  const authUser = localStorage.getItem("authUser");
  let uData = JSON.parse(authUser);
  const [pageLoading, setPageloading] = useState(false);
  const [showResult, setShowResult] = useState(true);
  const [searchLoading, setSearchLoading] = useState(false);
  const [statisticsData, setStatisticsData] = useState({});
  const [searchData, setSearchData] = useState([]);
  const getStatistics = () => {
    setPageloading(true);
    const data_send = {
      agent_id: uData?.agent_id,
      type: uData?.user_type,
      page: page,
    };
    console.log(data_send)
    axios
      .post(
        "https://camp-coding.tech/new_quad_wall/quad_crm/admin/statistics.php",
        data_send
      )
      .then((res) => {
        console.log(res, "resres");
        if (res.status == "success") {
          setStatisticsData(res.message);
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setPageloading(false);
      });
  };


  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<AiOutlineSearch/>}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <AiOutlineSearch
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handlesearch2 = (txt, txtPage) => {
    if (txt == "") {
      setShowResult(false);
      return;
    }
    setParams((prevParams) => {
      const newParams = new URLSearchParams(prevParams);

      newParams.set("page", txtPage);
      return newParams;
    });
    setSearchLoading(true);
    // alert(txtPage)
    const data_send = {
      search_text: txt,
      page: txtPage,
      type:uData.user_type,
      // ‪966 54 406 3012‬
      agent_id:uData?.agent_id
    };
    axios
      .post(
        "https://camp-coding.tech/new_quad_wall/quad_crm/admin/search_client.php",
        JSON.stringify(data_send)
      )
      .then((res) => {
        if (res.status == "success") {
          setParams((prevParams) => {
            const newParams = new URLSearchParams(prevParams);
            newParams.set(
              "tb",

              res.total_pages
            );

            newParams.set(
              "page",

              txtPage
            );
            return newParams;
          });
          if (Array.isArray(res.message)) {
            setSearchData(res.message);
          }
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setSearchLoading(false);
      });
  };

  const [comment, setComment] = useState("");
  const [commentsData, setCommentsData] = useState([]);
  const [ClientId, setClientId] = useState(null);

  const handleAddCall = () => {
    // setAddLoading(true)
    const data_send = {
      ...newCall,
      client_id: rowData,
      agent_id: uData?.agent_id,
    };
    console.log(data_send);
    // return ;
    axios
      .post(
        "https://camp-coding.tech/new_quad_wall/quad_crm/admin/add_new_call.php",
        JSON.stringify(data_send)
      )
      .then((res) => {
        console.log(res);
        if (res.status == "success") {
          toast.success(res.message);
          setNewCall({ duration: "", feed_back: "" });
          handleGetCalls(rowData);
          setShowAddModal(false);
        } else if (res.status == "error") {
          toast.error(res.message);
        } else {
          toast.error("Something Went Error");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setAddLoading(false);
      });
  };
  const [params, setParams] = useSearchParams();

  const handleAddComment = () => {
    // setAddLoading(true)
    const data_send = {
      comment: comment,
      client_id: ClientId?.client_id,
      agent_id: uData?.agent_id,
    };
    console.log(data_send);
    // return ;
    axios
      .post(
        "https://camp-coding.tech/new_quad_wall/quad_crm/admin/insert_comment.php",
        JSON.stringify(data_send)
      )
      .then((res) => {
        console.log(res);
        if (res.status == "success") {
          toast.success(res.message);
          setNewCall({ duration: "", feed_back: "" });
          handleGetComments(rowData);
          setShowAddModal(false);
        } else if (res.status == "error") {
          toast.error(res.message);
        } else {
          toast.error("Something Went Error");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setAddLoading(false);
      });
  };

  const [oldComment, setOldComment] = useState(null);
  const [deleteComment, setDeleteComment] = useState(null);
  const handleUpdateComment = () => {
    // setAddLoading(true)
    const data_send = {
      ...oldData,
    };
    // return ;
    axios
      .post(
        "https://camp-coding.tech/new_quad_wall/quad_crm/admin/update_comment.php",
        JSON.stringify(data_send)
      )
      .then((res) => {
        console.log(res);
        if (res.status == "success") {
          toast.success(res.message);
          setOldComment(null);
          handleGetComments(rowData);
        } else if (res.status == "error") {
          toast.error(res.message);
        } else {
          toast.error("Something Went Error");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setAddLoading(false);
      });
  };

  const handleDeleteComment = (comment_id) => {
    // setAddLoading(true)
    const data_send = {
      comment_id,
    };
    // return ;
    axios
      .post(
        "https://camp-coding.tech/new_quad_wall/quad_crm/admin/delete_comment.php",
        JSON.stringify(data_send)
      )
      .then((res) => {
        console.log(res);
        if (res.status == "success") {
          toast.success(res.message);
          setDeleteComment(null);
          handleGetComments(rowData);
        } else if (res.status == "error") {
          toast.error(res.message);
        } else {
          toast.error("Something Went Error");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setAddLoading(false);
      });
  };


  const getProjects=()=>{
    axios.get("https://camp-coding.tech/new_quad_wall/quad_crm/admin/projects_interested.php")
    .then((res)=>{
      console.log(res.message)
      if(res.status=='success'){
        if(Array.isArray(res.message)){
          setProjects(res.message)
        }
      }
    })
    .catch(e=>console.log(e))
  }
  useEffect(() => {
    getStatistics();
    getProjects()
  }, []);

  useEffect(() => {
    updateColumnTitles(searchData.length);
  }, [searchData]);

  const handlePageChange = (increment) => {
    let newPage = null;
    setParams((prevParams) => {
      const newParams = new URLSearchParams(prevParams);
      const currentPage = parseInt(params.get("page")) || 1;
      const totalPages = parseInt(params.get("tb")) || 1;
      newPage = currentPage;

      if (increment) {
        newPage = currentPage < totalPages ? currentPage + 1 : currentPage;
      } else {
        newPage = currentPage > 1 ? currentPage - 1 : currentPage;
      }

      newParams.set("page", newPage);
      return newParams;
    });

    handlesearch2(params.get("searchValue"), newPage);
  };

  const [filterData, setFilterData] = useState({
    agent_id: null,
    client_type: null,
    date: null,
    client_status: null,
    to_date:null
  });
  const [agents, setAgents] = useState([]);
  const handleGetSalles = () => {
    axios
      .get(
        "https://camp-coding.tech/new_quad_wall/quad_crm/admin/select_selles.php"
      )
      .then((res) => {
        console.log(res);
        if (res.status == "success") {
          setAgents(res?.message);
        } else if (res.status == "error") {
          toast.error(res.message);
        } else {
          toast.error("Something Went Error");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setAddLoading(false);
      });
  };
  useEffect(() => {
    handleGetSalles();
  }, []);
  const resetFilters = () => {
    setFilterData({
      date: "",
      client_type: "",
      client_status: "",
      agent_id: "",
    });
  };

  const [statsFilteredData, setStatsFilteredData] = useState([]);
  const getFilteredData = () => {
    setPageloading(true);
    const data_send = {
      ...filterData,
      agent_id:uData?.agent_id,
      agentsTxt:uData?.agent_id,
      projectInterested:projectInterested.length>0?projectInterested.join(','):null,
      client_statuses:selectedStatuses.length>0?selectedStatuses.join(','):null,
    };
    axios
      .post(
        "https://camp-coding.tech/new_quad_wall/quad_crm/admin/filter_clients.php",
        data_send
      )
      .then((res) => {
        console.log(res, "resres");
        if (res.status == "success") {
          setStatsFilteredData(res.message);
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setPageloading(false);
      });
  };



  const renderusers = [
    {
      title: 'Number',
      key: 'number',
      width: '10%',
      render: (text, record, index) => index + 1,
    },
    {
      title: 'Select',
      dataIndex: 'name',
      key: 'name',
      width: '30%',
      render: (_, record) => (
        <Space>
          <div style={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
            <input onClick={()=>{
              setSearchData(searchData.map((item)=>{
                return {...item,check:item.client_id==record.client_id?!record.check:item.check}
              }))
            }} checked={record.check} type="checkbox" style={{ width:'15px',height:'15px' }}/>
          </div>
        </Space>
        )
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: '30%',
      ...getColumnSearchProps('name'),
    },

    {
      title: 'status',
      dataIndex: 'status',
      key: 'status',
      width: '20%',
      ...getColumnSearchProps('status'),
    },
    {
      title: 'Last Action',
      dataIndex: 'last_action_date',
      key: 'last_action_date',
      width: '20%',
      ...getColumnSearchProps('last_action_date'),
    },
    {
      title: 'Time To Call',
      dataIndex: 'time_to_call',
      key: 'time_to_call',
      width: '20%',
      ...getColumnSearchProps('time_to_call'),
    },
    {
      title: 'phone',
      dataIndex: 'phone',
      key: 'phone',
      width: '20%',
      ...getColumnSearchProps('phone'),
    },
    {
      title: 'phone2',
      dataIndex: 'phone2',
      key: 'phone2',
      width: '20%',
      ...getColumnSearchProps('phone2'),
    },
    {
      title: 'Sale Name',
      dataIndex: 'phone2',
      key: 'phone2',
      width: '20%',
      render: (_, record) => (
        <Space>
          <div style={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
            {
              record?.sales&&record?.sales.map((item)=>{
                return (
                  <p>{item.name}</p>
                )
              })
            }
          </div>
        </Space>
        )
    },
    {
      title: 'Sale Email',
      dataIndex: 'phone2',
      key: 'phone2',
      width: '20%',
      render: (_, record) => (
        <Space>
          <div style={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
            {
              record?.sales&&record?.sales.map((item)=>{
                return (
                  <p>{item.user_email}</p>
                )
              })
            }
          </div>
        </Space>
        )
    },
    {
      title: 'Sale phone',
      dataIndex: 'phone2',
      key: 'phone2',
      width: '20%',
      render: (_, record) => (
        <Space>
          <div style={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
            {
              record?.sales&&record?.sales.map((item)=>{
                return (
                  <p>{item.phone}</p>
                )
              })
            }
          </div>
        </Space>
        )
    },
    {
      title: 'Calls',
      dataIndex: 'phone2',
      key: 'phone2',
      width: '20%',
      render: (_, record) => (
        <Space>
          <div style={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
            <button onClick={()=>{
          handleGetCalls(record?.client_id);
          setRowData(record?.client_id);
              setShowCalls(true);
            }} className="btn btn-primary">
              Calls
            </button>
          </div>
        </Space>
        )
    },
    {
      title: 'Comments',
      dataIndex: 'phone2',
      key: 'phone2',
      width: '20%',
      render: (_, record) => (
        <Space>
          <div style={{ display: "flex", flexWrap: "wrap", width: "100%",rowGap:'5px' }}>
            <button onClick={()=>{
                handleGetComments(rowData?.client_id);
                setRowData(rowData?.client_id);
                setClientId(rowData);
            }} className="btn btn-primary">
              Comments
            </button>
            <button onClick={()=>{
                window.location.href = "/UserProfile/" + record?.client_id;
            }} className="btn btn-primary">
              Profile
            </button>

          </div>
        </Space>
        )
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
      ...getColumnSearchProps('address'),
      sorter: (a, b) => a.address.length - b.address.length,
      sortDirections: ['descend', 'ascend'],
    },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          {pageLoading ? (
            <div
              style={{
                minHeight: "100vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Lottie
                style={{ width: "40%" }}
                animationData={groovyWalkAnimation}
                loop={true}
              />
              {/* <img src={require("../../assets/images/Animation - 1717926811158(1).gif")} alt="" /> */}
            </div>
          ) : (
            <>
              {" "}
              <div className="filter-container">
                <div className="filter-item">
                  <label htmlFor="date-filter">From Date</label>
                  <input
                    id="date-filter"
                    type="date"
                    value={filterData.date}
                    onChange={(e) =>
                      setFilterData({ ...filterData, date: e.target.value })
                    }
                  />
                </div>
                <div className="filter-item">
                  <label htmlFor="date-filter">To Date</label>
                  <input
                    id="date-filter"
                    type="date"
                    value={filterData.to_date}
                    onChange={(e) =>{
                      setFilterData({ ...filterData, to_date: e.target.value })
                      console.log(e.target.value)
                    }
                    }
                  />
                </div>
                <div className="filter-item">
                  <label htmlFor="client-type-filter">Client Type</label>
                  <select
                    id="client-type-filter"
                    value={filterData.client_type}
                    onChange={(e) => {
                      setFilterData({
                        ...filterData,
                        client_type: e.target.value,
                      });
                    }}
                  >
                    <option value="">Select Type</option>
                    {[
                      { id: "1", type: "cold" },
                      { id: "2", type: "leads" },
                    ].map((item) => {
                      return (
                        <option key={item.id} value={item.type}>
                          {item.type}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="filter-item">
                  <label htmlFor="client-status-filter">Client Status</label>
                  <Select
                    mode="multiple"
                    isMulti
                    name="colors"
                    value={selectedStatuses}
                    onChange={(e)=>{
                      console.log(e,"ewwel;")
                      setSelectedStatuses(e)
                    }}
                    options={[
                      { value: "unassigned", label: "unassigned" },
                      { value: "assigned", label: "assigned" },
                      { value: "pending", label: "pending" },
                      { value: "interested", label: "interested" },
                      { value: "not_interested", label: "not_interested" },
                      { value: "low_budget", label: "low_budget" },
                      { value: "no_answer", label: "no_answer" },
                      { value: "call_back", label: "call_back" },
                      { value: "Re_assign", label: "Re_assign" },
                    ]}
                    className="basic-multi-select"
                    classNamePrefix="select"
                  />
                  {/* <select
                    id="client-status-filter"
                    value={selectedStatuses}
                    onChange={(e) => {
                      // setFilterData({
                      //   ...filterData,
                      //   client_status: e.target.value,
                      // });
                      setSelectedStatuses(e)
                    }}
                  >
                    <option value="">Select Status</option>
                    {[
                      { id: "1", type: "unassigned" },
                      { id: "2", type: "assigned" },
                      { id: "3", type: "pending" },
                      { id: "4", type: "interested" },
                      { id: "5", type: "not_interested" },
                      { id: "6", type: "low_budget" },
                      { id: "7", type: "no_answer" },
                      { id: "8", type: "call_back" },
                      { id: "9", type: "Re_assign" },
                    ].map((item) => {
                      return (
                        <option key={item.id} value={item.type}>
                          {item.type}
                        </option>
                      );
                    })}
                  </select> */}
                </div>
                <div className="filter-item">
                  <label htmlFor="agent-filter">Project Interested</label>
                  {/* <Select
                    className="basic-single"
                    classNamePrefix="select"
                    // isSearchable={false}
                    name="color2"
                    options={
                      projects&&projects.map((item)=>{
                        return {label:item.project_intrested_in,value:item.project_intrested_in}
                      })
                    }
                  /> */}
                  <Select
                  mode="multiple"
                  isMulti
                    name="colors2"
                    isSearchable={true}
                    value={projectInterested}
                    // value={selectedAgents}
                    onChange={(e)=>{
                      setProjectInterested(e);
                    }}
                    options={
                      projects&&projects.map((item)=>{
                        return {label:item.project_intrested_in,value:item.project_intrested_in}
                      })
                    }
                    className="basic-multi-select"
                    classNamePrefix="select"
                  />
                </div>
                {/* <div className="filter-item">
                  <label htmlFor="agent-filter">Agent</label>
                  <select
                    id="agent-filter"
                    value={filterData.agent_id}
                    onChange={(e) => {
                      setFilterData({
                        ...filterData,
                        agent_id: e.target.value,
                      });
                    }}
                  >
                    <option value="">Select Agent</option>
                    {agents &&
                      agents.length &&
                      agents.map((item) => {
                        return (
                          <option key={item.agent_id} value={item.agent_id}>
                            {item.name}
                          </option>
                        );
                      })}
                  </select>
                </div> */}
                <br />
                <div className="filter-buttons">
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      getFilteredData(filterData);
                    }}
                  >
                    Apply Filters
                  </button>
                  <button className="btn btn-danger" onClick={resetFilters}>
                    Reset Filters
                  </button>
                </div>
              </div>
              <Row
                style={{ backgroundColor: "white" }}
                className={
                  "d-flex p-3 row_div bg-light row align-items-center gap-2 flex-wrap"
                }
              >
                <div
                  style={{ backgroundColor: "white" }}
                  className="col-lg-4 d-flex flex-column justify-content-center p-2 text-center col-md-3 col-sm-6 bg-light"
                >
                  <h4>Colds</h4>
                  <p>{statisticsData?.colds??0}</p>
                </div>
                <div
                  style={{ backgroundColor: "white" }}
                  className="col-lg-4 d-flex flex-column justify-content-center p-2 text-center col-md-3 col-sm-6 bg-light"
                >
                  <h4>Leads</h4>
                  <p>{statisticsData?.leads??0}</p>
                </div>
                {/* <div
                  style={{ backgroundColor: "white" }}
                  className="col-lg-4 p-2 d-flex flex-column justify-content-center text-center col-md-3 col-sm-6  bg-light"
                >
                  <h4>Calls Number</h4>
                  <p>{statisticsData?.calls_number??0}</p>
                </div> */}
                <div
                  style={{ backgroundColor: "white" }}
                  className="col-lg-4 p-2 d-flex flex-column justify-content-center text-center col-md-3 col-sm-6  bg-light"
                >
                  <h4>Calls Number</h4>
                  <p>{statisticsData?.calls_number??0}</p>
                </div>
                <div
                  style={{ backgroundColor: "white" }}
                  className="col-lg-4 p-2 d-flex flex-column justify-content-center text-center col-md-3 col-sm-6  bg-light"
                >
                  <h4>No Answer</h4>
                  <p>{statisticsData?.no_answer??0}</p>
                </div>
                <div
                  style={{ backgroundColor: "white" }}
                  className="col-lg-4 p-2 d-flex flex-column justify-content-center text-center col-md-3 col-sm-6  bg-light"
                >
                  <h4>Interested</h4>
                  <p>{statisticsData?.interested??0}</p>
                </div>
                <div
                  style={{ backgroundColor: "white" }}
                  className="col-lg-4 p-2 d-flex flex-column justify-content-center text-center col-md-3 col-sm-6  bg-light"
                >
                  <h4>Not Interested</h4>
                  <p>{statisticsData?.not_interested??0}</p>
                </div>
                <div
                  style={{ backgroundColor: "white" }}
                  className="col-lg-4 p-2 d-flex flex-column justify-content-center text-center col-md-3 col-sm-6  bg-light"
                >
                  <h4>Low Budget</h4>
                  <p>{statisticsData?.low_budget??0}</p>
                </div>
              </Row>
              <Row className={"row_div2 my-4 p-3"}>
                <div className="form-control">
                  <button className="btn btn-success mx-2 my-2 ">name</button>
                  <button className="btn btn-primary my-2  ">phone</button>
                  <div className="row">
                    <input
                      value={params?.get("searchValue")}
                      onChange={(e) => {
                        setShowResult(true);
                        setParams((prevParams) => {
                          const newParams = new URLSearchParams(prevParams);
                          newParams.set("searchValue", e.target.value);
                          return newParams;
                        });
                      }}
                      className="w-100"
                      type="text"
                      placeholder="Search For Client"
                    />
                  </div>
                  <button
                    className="btn btn-success my-2"
                    onClick={() => {
                      handlesearch2(params.get("searchValue"), 1);
                    }}
                  >
                    Search
                  </button>
                </div>

                {searchData && searchData?.length && (
                  <div className="my-4">
                    {searchLoading ? (
                      <div
                        style={{
                          minHeight: "40vh",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Lottie
                          style={{ width: "40%" }}
                          animationData={groovyWalkAnimation}
                          loop={true}
                        />
                        {/* <img src={require("../../assets/images/Animation - 1717926811158(1).gif")} alt="" /> */}
                      </div>
                    ) : (
                      <div>
                        {/* <ReactTabulator
                          data={searchData}
                          options={options}
                          // sortOrderReverse={true}
                          columns={coulmns}
                          layout={"fitData"}
                        /> */}
                        <Table style={{ marginTop:'10px' }} dataSource={searchData} columns={renderusers} />

                        <div className="pagination my-4">
                          <button
                            className="btn btn-danger mx-2"
                            onClick={() => handlePageChange(false)}
                          >
                            Prev
                          </button>
                          <button
                            className="btn btn-success"
                            onClick={() => handlePageChange(true)}
                          >
                            Next
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </Row>
            </>
          )}
        </Container>
      </div>

      <Modal
        className="client_history_model"
        style={{ width: "90vw" }}
        title="Client Calls"
        open={showCalls}
        onOk={() => {
          setShowCalls(false);
        }}
        onCancel={() => {
          setShowCalls(false);
        }}
        centered
      >
        {callLoading ? (
          <div
            style={{
              minHeight: "30vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Lottie
              style={{ width: "40%" }}
              animationData={groovyWalkAnimation}
              loop={true}
            />
            {/* <img src={require("../../assets/images/Animation - 1717926811158(1).gif")} alt="" /> */}
          </div>
        ) : (
          <>
            <div
              onClick={() => {
                setShowAddModal(true);
              }}
              style={{ width: "fit-content", cursor: "pointer" }}
              className="my-2 gap-2 d-flex align-items-center "
            >
              <AiOutlinePlusCircle
                className="text-success"
                style={{ cursor: "pointer", fontSize: "20px" }}
              />
              <h4>New Call</h4>
            </div>
            {console.log(calls)}
            <ReactTabulator
              data={calls}
              options={options}
              columns={callColumns}
              layout={"fitData"}
            />
          </>
        )}
      </Modal>

      <Modal
        className=""
        style={{ width: "90vw" }}
        title="Client Calls"
        open={showAddModal}
        onOk={() => {
          // setShowAddModal(false)
          handleAddCall();
        }}
        onCancel={() => {
          setShowAddModal(false);
        }}
        centered
      >
        <form style={{ width: "100%" }} action="">
          <div style={{ width: "100%" }}>
            <label className="w-100" htmlFor="">
              FeedBack
            </label>
            <input
              value={newCall?.feed_back}
              onChange={(e) => {
                setNewCall({ ...newCall, feed_back: e.target.value });
              }}
              className="w-100"
              type="text"
              placeholder="FeedBack"
            />
          </div>
          <div style={{ width: "100%" }}>
            <label className="w-100" htmlFor="">
              Call Duration
            </label>
            <input
              value={newCall?.duration}
              onChange={(e) => {
                setNewCall({ ...newCall, duration: e.target.value });
              }}
              className="w-100"
              type="text"
              placeholder="Call Duration"
            />
          </div>
        </form>
      </Modal>

      <Modal
        className="client_history_model"
        style={{ width: "90vw" }}
        title="Client Comments"
        open={ClientId}
        onOk={() => {
          setClientId(false);
        }}
        onCancel={() => {
          setClientId(false);
        }}
        centered
      >
        {callLoading ? (
          <div
            style={{
              minHeight: "30vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Lottie
              style={{ width: "40%" }}
              animationData={groovyWalkAnimation}
              loop={true}
            />
            {/* <img src={require("../../assets/images/Animation - 1717926811158(1).gif")} alt="" /> */}
          </div>
        ) : (
          <>
            <div
              onClick={() => {
                setOpenNewCommect(true);
              }}
              style={{ width: "fit-content", cursor: "pointer" }}
              className="my-2 gap-2 d-flex align-items-center "
            >
              <AiOutlinePlusCircle
                className="text-success"
                style={{ cursor: "pointer", fontSize: "20px" }}
              />
              <h4>New Comment</h4>
            </div>
            <ReactTabulator
              data={commentsData}
              options={options}
              columns={commentColumns}
              layout={"fitData"}
            />
          </>
        )}
      </Modal>

      <Modal
        className=""
        style={{ width: "90vw", zIndex: 123325412 }}
        title="Client Comments"
        open={openComment}
        onOk={() => {
          // setShowAddModal(false)
          handleAddComment();
        }}
        onCancel={() => {
          setOpenNewCommect(false);
        }}
        centered
      >
        <form style={{ width: "100%" }} action="">
          <div style={{ width: "100%" }}>
            <label className="w-100" htmlFor="">
              Comment
            </label>
            <textarea
              className="w-100"
              type="text"
              placeholder="Comment"
              value={comment}
              onChange={(e) => {
                setComment(e.target.value);
              }}
            ></textarea>
          </div>
        </form>
      </Modal>

      <Modal
        className=""
        style={{ width: "90vw", zIndex: 1233223412 }}
        title="Client Comments"
        open={oldComment}
        onOk={() => {
          // setShowAddModal(false)
          handleUpdateComment();
        }}
        onCancel={() => {
          setOldComment(false);
        }}
        centered
      >
        <form style={{ width: "100%" }} action="">
          <div style={{ width: "100%" }}>
            <label className="w-100" htmlFor="">
              Comment
            </label>
            <textarea
              className="w-100"
              type="text"
              placeholder="Comment"
              value={oldData?.comment}
              onChange={(e) => {
                setOldData({
                  ...oldData,
                  comment: e.target.value,
                });
              }}
            ></textarea>
          </div>
        </form>
      </Modal>

      <Modal
        open={statsFilteredData && statsFilteredData?.length}
        onCancel={() => setStatsFilteredData([])}
        onOk={() => setStatsFilteredData([])}
        style={{ width: "90%" }}
        className="modalStats"
      >
        {statsFilteredData && statsFilteredData?.length ? (
          <ReactTabulator
            data={statsFilteredData}
            options={options}
            // sortOrderReverse={true}
            columns={coulmns}
            layout={"fitData"}
          />
        ) : (
          <h4>No Data</h4>
        )}
      </Modal>
    </React.Fragment>
  );
};

export default SalesDashboard;
