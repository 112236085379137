import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';

import LanguageDropdown from '../../components/Common/TopbarDropdown/LanguageDropdown';
import NotificationDropdown from '../../components/Common/TopbarDropdown/VerticalNotificationDropdown';

//redux
import { Modal, Spin } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import { changeTheme, changeSidebarType } from '../../store/actions';
import { themeModeTypes, leftSidebarTypes } from '../../constants/layout';

// import Img
import logo from "../../assets/images/logo-sm.svg";
import ProfileMenu from '../../components/Common/TopbarDropdown/ProfileMenu';
import axios from 'axios';
import { toast } from 'react-toastify';
import { fetchDataRequest } from '../../store/color/actions';


const Header = (props) => {

  const color = useSelector((state) => state.dataReducer.data);
  // console.log(color)
  let agent=JSON.parse(localStorage.getItem("authUser"));
  const authUser=JSON.parse(localStorage.getItem("authUser"));
    const [showColorsMod,setShowColorsMod]=useState(false)
    const [colorData,setColorData]=useState({
      primary_color:'',
      primary_text_color:'',
      second_color:'',
      second_text_color:''
    })
    const [addLoading,setAddLoading]=useState(false)
    const [pageLoading,setPageLoading]=useState(false);
    const dispatch = useDispatch();

    function tToggle() {
        var body = document.body;
        if (window.screen.width <= 998) {
            body.classList.toggle("sidebar-enable");
        } else {
            body.classList.toggle("sidebar-enable");
            body.classList.toggle("vertical-collpsed");
            dispatch(changeSidebarType(leftSidebarTypes.DEFAULT))
        }
    }

    const handleOk=()=>{
      setAddLoading(true)
      const data_send={
        ...colorData,
        user_id:authUser?.agent_id*1
      }
      // console.log(data_send)
      // return
      axios.post("https://camp-coding.tech/new_quad_wall/quad_crm/admin/add_color.php",data_send)
      .then((res)=>{
        if(res.status=='success'){
          toast.success(res.message)
          getMyColors()
          dispatch(fetchDataRequest(authUser?.agent_id))
        }
        else if(res.status=='error'){
          toast.error(res.message)
        }
        else {
          toast.error('Something Went Error')
        }
      })
      .finally(()=>{
        setAddLoading(false)
      })
      .catch(e=>console.log(e))
    }
    const getMyColors=()=>{
      setPageLoading(true)
      axios.post("https://camp-coding.tech/new_quad_wall/quad_crm/admin/get_user_colors.php",{
        id:authUser?.agent_id,
      })
      .then((res)=>{
        // console.log(res)
        if(res.status=='success'){
          if(res.message!=null){
            setColorData(res.message)
            setShowColorsMod(false)
          }
        }
      })
      .catch(e=>console.log(e))
      .finally(()=>{
        setPageLoading(false)
      })
    }

    useEffect(()=>{
      getMyColors()
    },[])


    document.head.insertAdjacentHTML(
      "beforeend",
      `<style>
         .page-topbar * {
           color: ${color&&color?.primary_text_color} !important;
         }
           .page-topbar .d-flex *{
           color: ${color&&color?.primary_text_color} !important;
           }

       </style>`
    );

    return (
        <React.Fragment>
            <header style={{
              backgroundColor:color!=null&&color?.primary_color
            }} id="page-topbar" className="isvertical-topbar">
                <div className="navbar-header">
                    <div className="d-flex">
                        <div className="navbar-brand-box">

                            <Link to="/" className="logo logo-dark">
                                <span className="logo-sm">
                                    <img src={logo} alt="" height="22" />
                                </span>
                                <span className="logo-lg">
                                    <img src={logo} alt="" height="22" /> <span className="logo-txt">Symox</span>
                                </span>
                            </Link>

                            <Link to={authUser.user_type=='sells'?"/SalesDashboard":"/dashboard"} className="logo logo-light">
                                <span className="logo-sm">
                                    <img src={logo} alt="" height="22" />
                                </span>
                                <span className="logo-lg">
                                    <img src={logo} alt="" height="22" /> <span className="logo-txt">Symox</span>
                                </span>
                            </Link>

                        </div>
                        <div style={{
                          display:'flex',
                          marginLeft:'5px',
                          alignItems:'center',justifyContent:'center'
                        }}>
                      <h4>{agent?.name} ({agent?.user_type=='sells'?'sales':agent?.user_type})</h4>
                    </div>

                        <button type="button" className="btn btn-sm px-3 font-size-16 header-item vertical-menu-btn"
                            onClick={() => {
                                tToggle()
                            }}
                        >
                            <i className="fa fa-fw fa-bars"></i>
                        </button>

                        {/* <form className="app-search d-none d-lg-block">
                            <div className="position-relative">
                                <input type="text" className="form-control" placeholder="Search..." />
                                <span className="bx bx-search"></span>
                            </div>
                        </form> */}

                    </div>

                    <div className="d-flex">
                        <div className="dropdown d-inline-block d-lg-none">
                            <button type="button" className="btn header-item"
                                aria-haspopup={true} aria-expanded={false}>
                                <i style={{color:color&&color?.primary_text_color}} className="icon-sm" data-feather="search"></i>
                                <FeatherIcon style={{color:color&&color?.primary_text_color}} icon="search" />
                            </button>
                            <div className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0">
                                <form className="p-2">
                                    <div className="search-box">
                                        <div className="position-relative">
                                            <input type="text" className="form-control rounded bg-light border-0" placeholder="Search..." />
                                            <i className="mdi mdi-magnify search-icon"></i>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                        {/* <LanguageDropdown /> */}
                        {/* <NotificationDropdown /> */}

                        <div className="dropdown d-none d-sm-inline-block">

                            <button type="button" className="btn header-item light-dark" id="mode-setting-btn">
                                <FeatherIcon style={{color:color&&color?.primary_text_color}} icon="moon" className="icon-sm layout-mode-dark" onClick={() => dispatch(changeTheme(themeModeTypes.DARK))} />
                                <FeatherIcon style={{color:color&&color?.primary_text_color}} icon="sun" className="icon-sm layout-mode-light" onClick={() => dispatch(changeTheme(themeModeTypes.LIGHT))} />
                            </button>
                        </div>
                        <ProfileMenu />
                    </div>
                    <div className='d-flex'>
                      <img onClick={()=>{
                        setShowColorsMod(true);
                      }} style={{width:'50px',cursor:'pointer'}} src={require("../../assets/images/color.png")} alt="" />
                    </div>
                </div>
            </header>

            <Modal title="Change Colors" open={showColorsMod} onOk={handleOk} onCancel={()=>{
              setShowColorsMod(false)
            }}>
              <form onSubmit={e=>e.preventDefault()} action="">
                <div className='form-control'>
                  <label htmlFor="">Main Color</label>
                  <input type="color" onChange={(e)=>{
                    setColorData({...colorData,primary_color:e.target.value})
                  }} value={colorData?.primary_color} />
                </div>
                <div className='form-control'>
                  <label htmlFor="">Main Text Color</label>
                  <input onChange={(e)=>{
                    setColorData({...colorData,primary_text_color:e.target.value})
                  }}  type="color" value={colorData?.primary_text_color} />
                </div>
                <div className='form-control'>
                  <label htmlFor="">Second  Color</label>
                  <input onChange={(e)=>{
                    setColorData({...colorData,second_color:e.target.value})
                  }}  type="color" value={colorData?.second_color} />
                </div>
                <div className='form-control'>
                  <label htmlFor="">Second Text Color</label>
                  <input onChange={(e)=>{
                    setColorData({...colorData,second_text_color:e.target.value})
                  }}  type="color" value={colorData?.second_text_color} />
                </div>
                <div>
                  {
                    addLoading&&<div>
                      <h4>Loading....</h4>
                    </div>
                  }
                </div>
              </form>
            </Modal>

        </React.Fragment>
    )
}

export default Header;




