import React, { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Col, Container, Row, Card, CardBody } from 'reactstrap';

//import logosm from "../../assets/images/logo.png";
import { replace, useFormik } from 'formik';
import * as Yup from "yup";
import axios from 'axios';
import { toast } from 'react-toastify';
import { useState } from 'react';
import { AppContext } from '../../components/context/AppContextProvider';
import moment from 'moment';


const Login = () => {

  let current_day=moment().format('l');
  let next_day=moment().add(1,'days').format('l')
  // console.log(current_day>next_day)

  const naviage=useNavigate();
  const {login}=useContext(AppContext)
  const [logindata,setlogindata]=useState({
    user_email:'',
    password:'',
  })
    document.title = "Login | UAD ";
    const [loding, setLoding] = useState(false)

    let validationSchema=Yup.object({
        user_email:Yup.string().required('Email is required').email('Email invalid'),
        password:Yup.string().required('Password is required')
    })

    //Definition of variable For Form
     let formik= useFormik({
        initialValues:{
          user_email:'',
          password:'',
        },
        validationSchema,
        onSubmit:handelLogin
      });

    async function handelLogin (){
        //Call APi
        const data_send={
          ...logindata
        }
        setLoding(true)
        let res=  await axios.post(`https://camp-coding.tech/new_quad_wall/quad_crm/admin/user_login.php` ,JSON.stringify(data_send))
        console.log(res.message);
        console.log(res.status);
        if(res.status==="success"){
            setLoding(false)
            toast.success("تم تسجيل الدخول بنجاح")
            console.log(res);
            let current_day=moment().format('l');
            let data={
              ...res.message,
              current_day
            }
            console.log("datadata",data)
            // return
            localStorage.setItem("authUser",JSON.stringify({...data}));
            localStorage.setItem("logall",JSON.stringify({...data}));
            login(data);
            if(res.message.user_type=="sells"){
              naviage("/sales_leads",{replace:true});
            }
            else if(res.message.user_type=='team_leader'||res.message.user_type=='salles_admin'){
              naviage('/other_leads');
            }
            else if(res.message.user_type=='operation'){
              naviage('/dashboard',{replace:true});
            }
            else if(res.message.user_type=='teal_leader'){
              naviage('/teal_seals',{replace:true});
            }
            else if(res.message.user_type=='marketing'){
              naviage('/dashboard',{replace:true});
            }
            else{
            naviage("/Employees",{replace:true});
            }
        }else{
            setLoding(false)
            toast.error(res.message)
            // console.log('hello');
        }
    }
/*     const handlelogin2=()=>{
      axios.post("")
    } */
    return (
        <>
            <div className="authentication-bg min-vh-100">
                <div className="bg-overlay"></div>
                <div className='container'>
                    <div className="d-flex flex-column min-vh-100 px-3 pt-4">
                        <Row className="justify-content-center my-auto">
                            <Col md={8} lg={6} xl={5}>
                                <div className="text-center mb-4">
{/*                                     <Link to="/" className=''>
                                        <img src={logosm} alt="" height="22" /> <span className="text-white">UAD</span>
                                    </Link> */}
                                </div>
                                <Card>
                                    <CardBody className="p-4">
                                        <div className="text-center mt-2">
                                            <h5 className="text-primary">Welcome Back !</h5>
                                            <p className="text-muted">Sign in to continue to Quad Walls.</p>
                                        </div>
                                        <div className="p-2 mt-4">



                                            <form action="/"/* onSubmit={formik.handleSubmit} */
                                              onSubmit={(e)=>{
                                                e.preventDefault();
                                                handelLogin();
                                              }}
                                            >

                                                <div className="mb-3">
                                                    <label className="form-label" htmlFor="user_email">User Name</label>
                                                    <input /* onBlur={formik.handleBlur} */ value={logindata.user_email}  onChange={(e)=>{
                                                      setlogindata({...logindata,user_email:e.target.value})
                                                    }}  type="text" className="form-control" id="user_email" name="user_email" placeholder="Enter Your User Name" />
                                                    {/* <input onBlur={formik.handleBlur}  onChange={formik.handleChange}  type="email" className="form-control" id="user_email" name="user_email" placeholder="Enter Your Email" /> */}
                                                    {formik.errors.user_email && formik.touched.user_email?<div className="alert alert-danger">{formik.errors.user_email}</div>:null}

                                                </div>

                                                <div className="mb-3">
                                                    {/* <div className="float-end">
                                                        <Link to="/auth-recoverpw" className="text-muted">Forgot password?</Link>
                                                    </div> */}
                                                    <label htmlFor="password">Password</label>
                                                    <input /* onBlur={formik.handleBlur} */  onChange={(e)=>{
                                                      setlogindata({
                                                        ...logindata,
                                                        password:e.target.value
                                                      })
                                                    }} placeholder="Enter Your Password"  type="password" className='form-control mb-2' name='password' id='password' value={logindata.password} /* value={formik.values.password} *//>
                                                    {/* <input onBlur={formik.handleBlur}  onChange={formik.handleChange} placeholder="Enter Your Password"  type="password" className='form-control mb-2' name='password' id='password' value={formik.values.password}/> */}
                                                    {formik.errors.password && formik.touched.password?<div className="alert alert-danger">{formik.errors.password}</div>:null}
                                                </div>

                                                <div className="form-check">
                                                    <input type="checkbox" className="form-check-input" id="auth-remember-check" />
                                                    <label className="form-check-label" htmlFor="auth-remember-check">Remember me</label>
                                                </div>

                                                <div className="mt-3 text-end">

                                                {loding?
                                                <button className='btn btn-primary w-sm waves-effect waves-light'><i className='fa fa-spinner fa-spin'></i></button>:
                                                <button className="btn btn-primary w-sm waves-effect waves-light" type="submit">Log In</button>
}

                                                </div>

                                                {/* <div className="mt-4 text-center">
                                                    <div className="signin-other-title">
                                                        <h5 className="font-size-14 mb-3 title">Sign in with</h5>
                                                    </div>
                                                    <ul className="list-inline">
                                                        <li className="list-inline-item">
                                                            <Link to="#" className="social-list-item bg-primary text-white border-primary">
                                                                <i className="mdi mdi-facebook"></i>
                                                            </Link>
                                                        </li>
                                                        <li className="list-inline-item">
                                                            <Link to="#" className="social-list-item bg-info text-white border-info">
                                                                <i className="mdi mdi-twitter"></i>
                                                            </Link>
                                                        </li>
                                                        <li className="list-inline-item">
                                                            <Link to="#" className="social-list-item bg-danger text-white border-danger">
                                                                <i className="mdi mdi-google"></i>
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="mt-4 text-center">
                                                    <p className="mb-0">Don't have an account ? <Link to="/auth-register" className="fw-medium text-primary"> Signup now </Link> </p>
                                                </div> */}
                                            </form>



                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            {/* <Col lg={12}>
                                <div className="text-center text-muted p-4">
                                    <p className="text-white-50">© {new Date().getFullYear()} Symox. Crafted with <i className="mdi mdi-heart text-danger"></i> by Themesbrand</p>
                                </div>
                            </Col> */}
                        </Row>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Login;
// 4.14.1
